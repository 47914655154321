.user-card .card-title-text {
  padding: 0.5rem 1.5rem !important;
  align-items: center;
}
.user-card .user-details > p:last-child {
  margin-bottom: 0;
}
.user-card .user-details .mat-icon {
  vertical-align: top;
  margin-right: 0.5rem;
}
