.app-admin-wrap {
  height: 100vh;
  overflow: hidden;
}
.app-admin-container {
  max-width: 100%;
  height: 100vh;

  .rightside-content-hold {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0.333333rem;
    overflow-x: hidden;
    position: relative;
    min-height: 450px;
  }
}

.mat-tab-labels {
  justify-content: space-evenly;
}

.mat-simple-snack-bar-content {
  white-space: pre-wrap;
}

.sidebar-panel {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  z-index: 10001;
  width: $sidebar-width;
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  -webkit-transition: all $sidebarTrnTime $transitionTiming;
  transition: all $sidebarTrnTime $transitionTiming;
  overflow: hidden;
  background: url('../../../images/backgrounds/sidebar-bg.jpg') no-repeat;
  .sidebar-hold {
    width: $sidebar-width;
    margin-top: 2.5rem;
  }
  .navigation-hold {
    position: absolute;
    height: calc(100% - 56px);
    width: 100%;
    margin-top: 56px;
    background: rgba(255, 255, 255, 0.95);
    left: 0;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.main-content-wrap {
  display: flex;
  flex-direction: column;
  position: relative;
  float: right;
  height: 100vh;
  transition: width $sidebarTrnTime $transitionTiming;
  &.ps > .ps__rail-y {
    z-index: 9999;
  }
}

.sidebar-backdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: block;
  z-index: 10000;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all $sidebarTrnTime $transitionTiming;
  &.visible {
    visibility: visible;
    opacity: 1;
  }
}

table,
mat-table {
  background: transparent !important;
  width: 100%;
  border-collapse: collapse;
}

th.mat-header-cell,
mat-header-cell {
  background-color: #96045f !important;
  color: #ffffff;
  text-align: left;
  padding: 8px;
}

td.mat-cell,
mat-cell {
  color: #dddddd;
  padding: 8px;
}

tr.mat-row:nth-child(even),
mat-row:nth-child(even) {
  background-color: #1a2038 !important;
}

tr.mat-row:nth-child(odd),
mat-row:nth-child(odd) {
  background-color: #2a2f4f !important;
}

tr.mat-row:hover,
mat-row:hover {
  background-color: #1f2c4d !important;
}

.align-end-container {
  width: 100%;
  margin: 2rem 0;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  column-gap: 10px;
}

.my-3 {
  margin: 1.5rem 0;
}

.app-admin-container.sidebar-full:not(.compact-toggle-active) {
  .main-content-wrap {
    width: calc(100% - #{$sidebar-width});
  }
  .sidebar-panel {
    width: $sidebar-width;
    overflow: hidden;
  }
}
.app-admin-container.compact-toggle-active {
  .main-content-wrap {
    width: calc(100% - #{$compact-sidebar-width});
  }
}

.app-admin-container.sidebar-compact {
  .sidebar-panel,
  .sidebar-hold {
    width: $compact-sidebar-width;
  }
}

.app-admin-container.sidebar-compact-big {
  .main-content-wrap {
    width: calc(100% - #{$compact-big-sidebar-width});
  }
  .sidebar-panel {
    width: $compact-big-sidebar-width;
    .branding {
      width: $compact-big-sidebar-width;
      .app-logo {
        margin: auto;
      }
    }
    .navigation-hold {
      overflow: visible !important;
    }
  }
}

.app-admin-container.sidebar-closed {
  .sidebar-panel {
    overflow: hidden;
    left: -$sidebar-width;
    .branding {
      left: -$sidebar-width;
    }
  }
  .main-content-wrap {
    width: 100%;
  }
}

.fixed-topbar {
  .rightside-content-hold {
    overflow: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 64px;
  }
}

.layout-intransition {
  .branding {
    display: none !important;
  }

  .app-user-controls,
  .app-user-name {
    opacity: 0 !important;
  }
}

.app-admin-container.sidebar-full.navigation-top {
  .sidebar-panel {
    left: 0;
  }
}
.app-admin-container.navigation-top {
  .container-dynamic {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
  }
  .main-content-wrap {
    float: none;
    width: 100% !important;
  }
  .rightside-content-hold {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 48px;
  }
  .sidebar-panel {
    overflow: hidden;
    left: -$sidebar-width;
    .navigation-hold {
      margin-top: 48px;
      height: calc(100% - 48px);
    }
  }
}

[dir='rtl'] {
  &.app-admin-container.sidebar-closed {
    .sidebar-panel {
      right: -$sidebar-width;
    }
    .branding {
      left: auto !important;
      right: -$sidebar-width;
    }
  }
  .main-content-wrap {
    float: left;
  }
  .sidebar-panel {
    right: 0;
    left: auto !important;
  }
}

@media (max-width: 959px) {
  .app-admin-container.sidebar-full {
    .main-content-wrap {
      width: 100% !important;
    }
  }
}
.mat-mdc-dialog-surface {
  padding: 1rem;
}
mat-card {
  margin: 1rem;
  padding: 1rem;

  mat-card {
    box-shadow: none !important;
    margin: 0;
    padding: 0;
  }
}
.mat-mdc-card-title,
.mat-mdc-card-subtitle {
  margin-bottom: 1rem !important;
}
.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled)
  .mdc-evolution-chip__text-label {
  max-width: 75%;
}
.mat-mdc-card-actions {
  gap: 0.1rem;
  margin: 0;
}
.mdc-list-item__primary-text {
  justify-content: flex-end;
}
.mat-mdc-button .mdc-button__label,
.mat-mdc-unelevated-button .mdc-button__label,
.mat-mdc-raised-button .mdc-button__label,
.mat-mdc-outlined-button .mdc-button__label {
  white-space: nowrap;
  overflow: scroll;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
  caret-color: rgba(255, 255, 255, 0.6) !important;
}
.egret-navy-dark .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: rgba(255, 255, 255, 0.6) !important;
  --mat-full-pseudo-checkbox-selected-checkmark-color: rgba(255, 255, 255, 0.6) !important;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(
    255,
    255,
    255,
    0.7
  ) !important;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: rgba(255, 255, 255, 0.6) !important;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.6) !important;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.6) !important;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: rgba(255, 255, 255, 0.6) !important;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: rgba(255, 255, 255, 0.6) !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label {
  color: rgba(255, 255, 255, 0.6) !important;
}
.product-content-wrap {
  padding-right: 20%;
}