@charset "UTF-8";
@import '../../../node_modules/flag-icons/css/flag-icons.min.css';
@import '../../../node_modules/@swimlane/ngx-datatable/index.css';
@import '../../../node_modules/@swimlane/ngx-datatable/themes/material.css';
@import '../../../node_modules/@swimlane/ngx-datatable/assets/icons.css';
@import '../../../node_modules/quill/dist/quill.core.css';
@import '../../../node_modules/quill/dist/quill.snow.css';
@import '../../../node_modules/dragula/dist/dragula.css';
@import '../../../node_modules/angular-calendar/css/angular-calendar.css';
@import '../../../node_modules/hopscotch/dist/css/hopscotch.min.css';
@import '../../vendor/pace/pace-theme-min.css';
@import '../../../node_modules/highlight.js/styles/github-dark.min.css';
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spin {
  animation: spin 2s infinite linear;
}

html {
  font-size: 16px;
}

html,
body {
  width: 100%;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
  font-family: Archivo, "Helvetica Neue", sans-serif;
}

[tabindex="-1"]:focus {
  outline: none;
}

select,
button,
textarea,
input {
  vertical-align: baseline;
}

div {
  box-sizing: border-box;
}

html[dir=rtl], html[dir=ltr],
body[dir=rtl],
body[dir=ltr] {
  unicode-bidi: embed;
}

bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

.mat-card {
  font-size: 0.875rem;
}

img:not(.mat-card-image) {
  max-width: 100%;
}

a,
a:focus,
a:hover {
  text-decoration: none;
}

a {
  color: inherit;
}

p {
  margin: 0 0 16px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
}

.h1,
h1 {
  font-size: 2rem;
}

.h2,
h2 {
  font-size: 1.75rem;
}

.h3,
h3 {
  font-size: 1.5rem;
}

.h4,
h4 {
  font-size: 1.25rem;
}

.h5,
h5 {
  font-size: 1rem;
}

.h6,
h6 {
  font-size: 0.875rem;
}

code {
  padding: 8px;
  background: rgba(0, 0, 0, 0.08);
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 15px;
}

.bg-none {
  background: transparent !important;
}

.bg-white {
  background: #ffffff !important;
}

.h-full {
  min-height: 100vh;
}

.fix {
  position: relative;
  overflow: hidden;
}

.fix-elm::after {
  display: table;
  width: 100%;
  content: "";
}

.mat-box-shadow {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.light-gray {
  background: rgba(0, 0, 0, 0.024);
}

.light-mat-gray {
  background: rgba(0, 0, 0, 0.08);
}

.mat-blue {
  background: #247ba0;
  color: #fefefe;
}

.mat-red {
  background-color: #f44336 !important;
  color: #fff !important;
}

.mat-indigo {
  background-color: #3f51b5 !important;
  color: #fff !important;
}

.mat-brown {
  background-color: #785548 !important;
  color: #fefefe;
}

.mat-teal {
  background-color: #009688 !important;
  color: #fff !important;
}

.mat-purple {
  background-color: #9c27b0 !important;
  color: hsla(0, 0%, 100%, 0.87) !important;
}

.fz-1 {
  font-size: 1rem !important;
}

.fz-2 {
  font-size: 2rem !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: normal !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.text-muted-white {
  color: rgba(255, 255, 255, 0.7) !important;
}

.text-gray {
  color: rgba(0, 0, 0, 0.7) !important;
}

.text-sm {
  font-size: 0.813rem;
}

.list-item-active {
  border-left: 3px solid;
}

.material-icons.icon-sm {
  font-size: 18px !important;
  line-height: 18px !important;
  height: 18px;
  width: 18px;
}

.material-icons.icon-xs {
  font-size: 13px !important;
  line-height: 13px;
  height: 13px;
  width: 13px;
}

.mat-button,
.mat-raised-button {
  font-weight: 400 !important;
}

[mat-lg-button] {
  padding: 0 32px !important;
  font-size: 18px;
  line-height: 56px !important;
}

.mat-icon-button[mat-sm-button] {
  height: 24px;
  width: 24px;
  line-height: 24px;
}

.mat-icon-button[mat-xs-button] {
  height: 20px;
  width: 20px;
  line-height: 20px;
}

.mat-icon-button[mat-xs-button] .mat-icon {
  font-size: 16px;
  line-height: 20px !important;
  height: 20px;
  width: 20px;
}

.mat-chip[mat-sm-chip] {
  padding: 4px 6px 4px 6px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  line-height: 12px !important;
  max-height: 20px;
  box-sizing: border-box;
}

.mat-icon-button.img-button img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.compact-list .mat-list-item {
  line-height: 1.1;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.compact-list .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.list-tasktype .tasktype-item {
  padding: 12px;
}

.list-tasktype .tasktype-item:hover {
  background: rgba(0, 0, 0, 0.08);
}

.list-tasktype .tasktype-item .tasktype-action {
  visibility: hidden;
}

.list-tasktype .tasktype-item:hover .tasktype-action {
  visibility: visible;
}

.doughnut-grid {
  border-radius: 2px;
  overflow: hidden;
}

.doughnut-grid .doughnut-grid-item {
  padding: 1rem;
}

.doughnut-grid .doughnut-grid-item p {
  margin: 0;
}

.doughnut-grid .doughnut-grid-item .chart {
  margin: 0 0 8px;
}

.logo-group {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.toolbar-avatar {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 24px;
}

.toolbar-avatar > img {
  width: 40px !important;
  border-radius: 50%;
}

.toolbar-avatar.md,
.toolbar-avatar.md > img {
  width: 48px !important;
  height: 48px;
}

.toolbar-avatar > .status-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 25px;
  right: -2px;
  border: 2px solid #ffffff;
  background: #ccc;
}

.full-width {
  width: 100% !important;
}

.dnd-item {
  cursor: move;
  cursor: grab;
  cursor: -webkit-grab;
}

.icon-circle {
  color: rgb(255, 255, 255);
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.6rem;
  border-radius: 50%;
}

.mat-sidenav .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.035) !important;
}

.mat-chip {
  position: relative;
  overflow: hidden;
}

.text-small {
  font-size: 0.813rem;
}

.text-small .mat-icon {
  font-size: 1rem;
  vertical-align: sub;
  margin: 0 2px;
}

.icon-chip {
  font-size: 11px !important;
  padding: 4px 8px !important;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.icon-chip .mat-icon {
  font-size: 16px;
  height: 16px;
  width: 16px;
  margin-right: 4px;
}

.mat-chip:not(.mat-basic-chip) {
  display: inline-block;
  padding: 8px 12px 8px 12px;
  border-radius: 24px;
  font-size: 13px;
  line-height: 16px;
}

.ql-container .ql-editor {
  min-height: 200px;
}

.chart {
  display: block;
  width: 100%;
}

.form-error-msg {
  color: #f44336;
  display: block;
  padding: 5px 0;
}

.accordion-handle {
  cursor: pointer;
}

.app-accordion {
  max-height: 200px;
  transition: max-height 0.3s ease;
}

.app-accordion.open {
  max-height: 1000px;
}

.app-accordion .accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.app-accordion.open .accordion-content {
  max-height: 800px;
}

.app-accordion.open .hidden-on-open {
  display: none !important;
}

.app-accordion:not(.open) .show-on-open {
  display: none !important;
}

mat-list a[mat-list-item] .mat-list-item-content,
mat-list mat-list-item .mat-list-item-content,
mat-nav-list a[mat-list-item] .mat-list-item-content,
mat-nav-list mat-list-item .mat-list-item-content,
[mat-menu-item],
.mat-option,
body .mat-select-trigger {
  font-size: 0.875rem !important;
}

.mat-ripple {
  position: relative;
}

.fileupload-drop-zone {
  text-align: center;
  border: 1px dashed rgba(0, 0, 0, 0.15);
  padding: 48px;
  height: 120px;
}

.default-table {
  text-align: left;
}

.default-table > thead tr th {
  font-weight: 400;
  padding: 0.9rem 1.2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.default-table tbody tr td {
  padding: 0.9rem 1.2rem;
}

.app-error {
  text-align: center;
  width: 320px;
  max-width: 320px;
  margin: 0 auto;
}

.app-error .error-icon {
  height: 120px;
  width: 120px;
  font-size: 120px;
  float: left;
}

.app-error .error-text {
  float: right;
  width: 200px;
  max-width: 200px;
}

.app-error .error-title {
  font-size: 5rem;
  font-weight: 900;
  margin: 0;
}

.app-error .error-subtitle {
  font-size: 1.5rem;
  font-weight: 300;
}

.app-error .error-actions {
  width: 100%;
  overflow: hidden;
  min-height: 54px;
  margin-top: 100px;
}

.app-loader,
.view-loader {
  height: 100vh !important;
  width: 100%;
  position: absolute;
  padding: 1.5rem;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-loader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-loader {
  padding: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.view-loader {
  display: block;
  padding-top: 160px;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9999;
}

.view-loader * {
  margin: auto;
}

@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
.p-0.mat-card-content .ql-container {
  border: 0 !important;
}

.p-0.mat-card-content .ql-toolbar.ql-snow {
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.ps__scrollbar-y-rail {
  z-index: 999;
}

.collapsed-menu .ps__scrollbar-y-rail {
  z-index: auto;
}

.ngx-datatable.material {
  box-shadow: 0 8px 12px rgba(47, 60, 74, 0.04), 0 2px 6px rgba(47, 60, 74, 0.08) !important;
}

.ngx-datatable.material .datatable-header .datatable-header-cell {
  background-color: inherit !important;
}

.datatable-body-cell {
  display: inline-flex !important;
  align-items: center;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.datatable-body-cell-label {
  width: 100%;
  display: flex;
}

.agm-info-window-content {
  color: rgba(0, 0, 0, 0.87);
}

.chart {
  margin-left: -10px;
}

.ps__rail-y {
  right: 0 !important;
  left: auto !important;
}

[dir=rtl] .ps__rail-y {
  right: auto !important;
  left: 0 !important;
}
[dir=rtl] .ps__thumb-y {
  right: auto !important;
  left: 1px !important;
}
[dir=rtl] .ngx-datatable .datatable-footer .datatable-pager .pager {
  float: left;
}
[dir=rtl] .user-card .user-details .mat-icon {
  margin-right: 0;
  margin-left: 0.5rem;
}

[dir=rtl] .search-bar-wide .search-close {
  top: 20px;
  right: auto !important;
  left: 15px;
}

@media (max-width: 767px) {
  .mat-card-title .mat-divider {
    margin-left: 0;
    margin-right: 0;
  }
  .accordion-handle {
    flex-direction: column !important;
  }
  .app-error .error-icon {
    height: 100px;
    width: 100px;
    font-size: 100px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  margin-top: 0;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h1,
.h1 {
  font-size: 24px;
}

h2,
.h2 {
  font-size: 20px;
}

h3,
.h3 {
  font-size: 16px;
}

h4,
.h4 {
  font-size: 15px;
}

h5,
.h5 {
  font-size: 14px;
}

h6,
.h6 {
  font-size: 13px;
}

a,
a:focus,
a:hover {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  outline: 0;
}

button {
  outline: 0;
}

figure {
  margin: 0;
}

strong,
b {
  font-weight: 700;
}

.mat-card-title {
  font-size: 18px;
  font-weight: 500;
}

.strikethrough {
  text-decoration: line-through;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-10 {
  font-size: 10px;
}
.text-10.mat-icon {
  width: 10px !important;
  height: 10px !important;
  line-height: 10px !important;
}

.text-11 {
  font-size: 11px;
}
.text-11.mat-icon {
  width: 11px !important;
  height: 11px !important;
  line-height: 11px !important;
}

.text-12 {
  font-size: 12px;
}
.text-12.mat-icon {
  width: 12px !important;
  height: 12px !important;
  line-height: 12px !important;
}

.text-13 {
  font-size: 13px;
}
.text-13.mat-icon {
  width: 13px !important;
  height: 13px !important;
  line-height: 13px !important;
}

.text-14 {
  font-size: 14px;
}
.text-14.mat-icon {
  width: 14px !important;
  height: 14px !important;
  line-height: 14px !important;
}

.text-15 {
  font-size: 15px;
}
.text-15.mat-icon {
  width: 15px !important;
  height: 15px !important;
  line-height: 15px !important;
}

.text-16 {
  font-size: 16px;
}
.text-16.mat-icon {
  width: 16px !important;
  height: 16px !important;
  line-height: 16px !important;
}

.text-17 {
  font-size: 17px;
}
.text-17.mat-icon {
  width: 17px !important;
  height: 17px !important;
  line-height: 17px !important;
}

.text-18 {
  font-size: 18px;
}
.text-18.mat-icon {
  width: 18px !important;
  height: 18px !important;
  line-height: 18px !important;
}

.text-19 {
  font-size: 19px;
}
.text-19.mat-icon {
  width: 19px !important;
  height: 19px !important;
  line-height: 19px !important;
}

.text-20 {
  font-size: 20px;
}
.text-20.mat-icon {
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
}

.text-21 {
  font-size: 21px;
}
.text-21.mat-icon {
  width: 21px !important;
  height: 21px !important;
  line-height: 21px !important;
}

.text-22 {
  font-size: 22px;
}
.text-22.mat-icon {
  width: 22px !important;
  height: 22px !important;
  line-height: 22px !important;
}

.text-23 {
  font-size: 23px;
}
.text-23.mat-icon {
  width: 23px !important;
  height: 23px !important;
  line-height: 23px !important;
}

.text-24 {
  font-size: 24px;
}
.text-24.mat-icon {
  width: 24px !important;
  height: 24px !important;
  line-height: 24px !important;
}

.text-25 {
  font-size: 25px;
}
.text-25.mat-icon {
  width: 25px !important;
  height: 25px !important;
  line-height: 25px !important;
}

.text-26 {
  font-size: 26px;
}
.text-26.mat-icon {
  width: 26px !important;
  height: 26px !important;
  line-height: 26px !important;
}

.text-27 {
  font-size: 27px;
}
.text-27.mat-icon {
  width: 27px !important;
  height: 27px !important;
  line-height: 27px !important;
}

.text-28 {
  font-size: 28px;
}
.text-28.mat-icon {
  width: 28px !important;
  height: 28px !important;
  line-height: 28px !important;
}

.text-29 {
  font-size: 29px;
}
.text-29.mat-icon {
  width: 29px !important;
  height: 29px !important;
  line-height: 29px !important;
}

.text-30 {
  font-size: 30px;
}
.text-30.mat-icon {
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
}

.text-31 {
  font-size: 31px;
}
.text-31.mat-icon {
  width: 31px !important;
  height: 31px !important;
  line-height: 31px !important;
}

.text-32 {
  font-size: 32px;
}
.text-32.mat-icon {
  width: 32px !important;
  height: 32px !important;
  line-height: 32px !important;
}

.text-33 {
  font-size: 33px;
}
.text-33.mat-icon {
  width: 33px !important;
  height: 33px !important;
  line-height: 33px !important;
}

.text-34 {
  font-size: 34px;
}
.text-34.mat-icon {
  width: 34px !important;
  height: 34px !important;
  line-height: 34px !important;
}

.text-35 {
  font-size: 35px;
}
.text-35.mat-icon {
  width: 35px !important;
  height: 35px !important;
  line-height: 35px !important;
}

.text-36 {
  font-size: 36px;
}
.text-36.mat-icon {
  width: 36px !important;
  height: 36px !important;
  line-height: 36px !important;
}

.text-37 {
  font-size: 37px;
}
.text-37.mat-icon {
  width: 37px !important;
  height: 37px !important;
  line-height: 37px !important;
}

.text-38 {
  font-size: 38px;
}
.text-38.mat-icon {
  width: 38px !important;
  height: 38px !important;
  line-height: 38px !important;
}

.text-39 {
  font-size: 39px;
}
.text-39.mat-icon {
  width: 39px !important;
  height: 39px !important;
  line-height: 39px !important;
}

.text-40 {
  font-size: 40px;
}
.text-40.mat-icon {
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
}

.text-41 {
  font-size: 41px;
}
.text-41.mat-icon {
  width: 41px !important;
  height: 41px !important;
  line-height: 41px !important;
}

.text-42 {
  font-size: 42px;
}
.text-42.mat-icon {
  width: 42px !important;
  height: 42px !important;
  line-height: 42px !important;
}

.text-43 {
  font-size: 43px;
}
.text-43.mat-icon {
  width: 43px !important;
  height: 43px !important;
  line-height: 43px !important;
}

.text-44 {
  font-size: 44px;
}
.text-44.mat-icon {
  width: 44px !important;
  height: 44px !important;
  line-height: 44px !important;
}

.text-45 {
  font-size: 45px;
}
.text-45.mat-icon {
  width: 45px !important;
  height: 45px !important;
  line-height: 45px !important;
}

.text-46 {
  font-size: 46px;
}
.text-46.mat-icon {
  width: 46px !important;
  height: 46px !important;
  line-height: 46px !important;
}

.text-47 {
  font-size: 47px;
}
.text-47.mat-icon {
  width: 47px !important;
  height: 47px !important;
  line-height: 47px !important;
}

.text-48 {
  font-size: 48px;
}
.text-48.mat-icon {
  width: 48px !important;
  height: 48px !important;
  line-height: 48px !important;
}

.text-49 {
  font-size: 49px;
}
.text-49.mat-icon {
  width: 49px !important;
  height: 49px !important;
  line-height: 49px !important;
}

.text-50 {
  font-size: 50px;
}
.text-50.mat-icon {
  width: 50px !important;
  height: 50px !important;
  line-height: 50px !important;
}

.text-51 {
  font-size: 51px;
}
.text-51.mat-icon {
  width: 51px !important;
  height: 51px !important;
  line-height: 51px !important;
}

.text-52 {
  font-size: 52px;
}
.text-52.mat-icon {
  width: 52px !important;
  height: 52px !important;
  line-height: 52px !important;
}

.text-53 {
  font-size: 53px;
}
.text-53.mat-icon {
  width: 53px !important;
  height: 53px !important;
  line-height: 53px !important;
}

.text-54 {
  font-size: 54px;
}
.text-54.mat-icon {
  width: 54px !important;
  height: 54px !important;
  line-height: 54px !important;
}

.text-55 {
  font-size: 55px;
}
.text-55.mat-icon {
  width: 55px !important;
  height: 55px !important;
  line-height: 55px !important;
}

.text-56 {
  font-size: 56px;
}
.text-56.mat-icon {
  width: 56px !important;
  height: 56px !important;
  line-height: 56px !important;
}

.text-57 {
  font-size: 57px;
}
.text-57.mat-icon {
  width: 57px !important;
  height: 57px !important;
  line-height: 57px !important;
}

.text-58 {
  font-size: 58px;
}
.text-58.mat-icon {
  width: 58px !important;
  height: 58px !important;
  line-height: 58px !important;
}

.text-59 {
  font-size: 59px;
}
.text-59.mat-icon {
  width: 59px !important;
  height: 59px !important;
  line-height: 59px !important;
}

.text-60 {
  font-size: 60px;
}
.text-60.mat-icon {
  width: 60px !important;
  height: 60px !important;
  line-height: 60px !important;
}

.text-61 {
  font-size: 61px;
}
.text-61.mat-icon {
  width: 61px !important;
  height: 61px !important;
  line-height: 61px !important;
}

.text-62 {
  font-size: 62px;
}
.text-62.mat-icon {
  width: 62px !important;
  height: 62px !important;
  line-height: 62px !important;
}

.text-63 {
  font-size: 63px;
}
.text-63.mat-icon {
  width: 63px !important;
  height: 63px !important;
  line-height: 63px !important;
}

.text-64 {
  font-size: 64px;
}
.text-64.mat-icon {
  width: 64px !important;
  height: 64px !important;
  line-height: 64px !important;
}

.text-65 {
  font-size: 65px;
}
.text-65.mat-icon {
  width: 65px !important;
  height: 65px !important;
  line-height: 65px !important;
}

.text-66 {
  font-size: 66px;
}
.text-66.mat-icon {
  width: 66px !important;
  height: 66px !important;
  line-height: 66px !important;
}

.text-67 {
  font-size: 67px;
}
.text-67.mat-icon {
  width: 67px !important;
  height: 67px !important;
  line-height: 67px !important;
}

.text-68 {
  font-size: 68px;
}
.text-68.mat-icon {
  width: 68px !important;
  height: 68px !important;
  line-height: 68px !important;
}

.text-69 {
  font-size: 69px;
}
.text-69.mat-icon {
  width: 69px !important;
  height: 69px !important;
  line-height: 69px !important;
}

.text-70 {
  font-size: 70px;
}
.text-70.mat-icon {
  width: 70px !important;
  height: 70px !important;
  line-height: 70px !important;
}

.text-71 {
  font-size: 71px;
}
.text-71.mat-icon {
  width: 71px !important;
  height: 71px !important;
  line-height: 71px !important;
}

.text-72 {
  font-size: 72px;
}
.text-72.mat-icon {
  width: 72px !important;
  height: 72px !important;
  line-height: 72px !important;
}

.text-73 {
  font-size: 73px;
}
.text-73.mat-icon {
  width: 73px !important;
  height: 73px !important;
  line-height: 73px !important;
}

.text-74 {
  font-size: 74px;
}
.text-74.mat-icon {
  width: 74px !important;
  height: 74px !important;
  line-height: 74px !important;
}

.text-75 {
  font-size: 75px;
}
.text-75.mat-icon {
  width: 75px !important;
  height: 75px !important;
  line-height: 75px !important;
}

.text-76 {
  font-size: 76px;
}
.text-76.mat-icon {
  width: 76px !important;
  height: 76px !important;
  line-height: 76px !important;
}

.text-77 {
  font-size: 77px;
}
.text-77.mat-icon {
  width: 77px !important;
  height: 77px !important;
  line-height: 77px !important;
}

.text-78 {
  font-size: 78px;
}
.text-78.mat-icon {
  width: 78px !important;
  height: 78px !important;
  line-height: 78px !important;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-inherit {
  font-weight: inherit;
}

.line-height-1 {
  line-height: 1 !important;
}

.mat-heading-label {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.92px;
  text-transform: uppercase;
  margin: 0;
  padding-bottom: 1rem;
}

.app-admin-wrap {
  height: 100vh;
  overflow: hidden;
}

.app-admin-container {
  max-width: 100%;
  height: 100vh;
}
.app-admin-container .rightside-content-hold {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0.333333rem;
  overflow-x: hidden;
  position: relative;
  min-height: 450px;
}

.mat-tab-labels {
  justify-content: space-evenly;
}

.mat-simple-snack-bar-content {
  white-space: pre-wrap;
}

.sidebar-panel {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  z-index: 10001;
  width: 15rem;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  -webkit-transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  overflow: hidden;
  background: url("../../../images/backgrounds/sidebar-bg.jpg") no-repeat;
}
.sidebar-panel .sidebar-hold {
  width: 15rem;
  margin-top: 2.5rem;
}
.sidebar-panel .navigation-hold {
  position: absolute;
  height: calc(100% - 56px);
  width: 100%;
  margin-top: 56px;
  background: rgba(255, 255, 255, 0.95);
  left: 0;
}
.sidebar-panel ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-content-wrap {
  display: flex;
  flex-direction: column;
  position: relative;
  float: right;
  height: 100vh;
  transition: width 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.main-content-wrap.ps > .ps__rail-y {
  z-index: 9999;
}

.sidebar-backdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: block;
  z-index: 10000;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.sidebar-backdrop.visible {
  visibility: visible;
  opacity: 1;
}

table,
mat-table {
  background: transparent !important;
  width: 100%;
  border-collapse: collapse;
}

th.mat-header-cell,
mat-header-cell {
  background-color: #96045f !important;
  color: #ffffff;
  text-align: left;
  padding: 8px;
}

td.mat-cell,
mat-cell {
  color: #dddddd;
  padding: 8px;
}

tr.mat-row:nth-child(even),
mat-row:nth-child(even) {
  background-color: #1a2038 !important;
}

tr.mat-row:nth-child(odd),
mat-row:nth-child(odd) {
  background-color: #2a2f4f !important;
}

tr.mat-row:hover,
mat-row:hover {
  background-color: #1f2c4d !important;
}

.align-end-container {
  width: 100%;
  margin: 2rem 0;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  column-gap: 10px;
}

.my-3 {
  margin: 1.5rem 0;
}

.app-admin-container.sidebar-full:not(.compact-toggle-active) .main-content-wrap {
  width: calc(100% - 15rem);
}
.app-admin-container.sidebar-full:not(.compact-toggle-active) .sidebar-panel {
  width: 15rem;
  overflow: hidden;
}

.app-admin-container.compact-toggle-active .main-content-wrap {
  width: calc(100% - 64px);
}

.app-admin-container.sidebar-compact .sidebar-panel,
.app-admin-container.sidebar-compact .sidebar-hold {
  width: 64px;
}

.app-admin-container.sidebar-compact-big .main-content-wrap {
  width: calc(100% - 150px);
}
.app-admin-container.sidebar-compact-big .sidebar-panel {
  width: 150px;
}
.app-admin-container.sidebar-compact-big .sidebar-panel .branding {
  width: 150px;
}
.app-admin-container.sidebar-compact-big .sidebar-panel .branding .app-logo {
  margin: auto;
}
.app-admin-container.sidebar-compact-big .sidebar-panel .navigation-hold {
  overflow: visible !important;
}

.app-admin-container.sidebar-closed .sidebar-panel {
  overflow: hidden;
  left: -15rem;
}
.app-admin-container.sidebar-closed .sidebar-panel .branding {
  left: -15rem;
}
.app-admin-container.sidebar-closed .main-content-wrap {
  width: 100%;
}

.fixed-topbar .rightside-content-hold {
  overflow: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 64px;
}

.layout-intransition .branding {
  display: none !important;
}
.layout-intransition .app-user-controls,
.layout-intransition .app-user-name {
  opacity: 0 !important;
}

.app-admin-container.sidebar-full.navigation-top .sidebar-panel {
  left: 0;
}

.app-admin-container.navigation-top .container-dynamic {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.app-admin-container.navigation-top .main-content-wrap {
  float: none;
  width: 100% !important;
}
.app-admin-container.navigation-top .rightside-content-hold {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 48px;
}
.app-admin-container.navigation-top .sidebar-panel {
  overflow: hidden;
  left: -15rem;
}
.app-admin-container.navigation-top .sidebar-panel .navigation-hold {
  margin-top: 48px;
  height: calc(100% - 48px);
}

[dir=rtl].app-admin-container.sidebar-closed .sidebar-panel {
  right: -15rem;
}
[dir=rtl].app-admin-container.sidebar-closed .branding {
  left: auto !important;
  right: -15rem;
}
[dir=rtl] .main-content-wrap {
  float: left;
}
[dir=rtl] .sidebar-panel {
  right: 0;
  left: auto !important;
}

@media (max-width: 959px) {
  .app-admin-container.sidebar-full .main-content-wrap {
    width: 100% !important;
  }
}
.mat-mdc-dialog-surface {
  padding: 1rem;
}

mat-card {
  margin: 1rem;
  padding: 1rem;
}
mat-card mat-card {
  box-shadow: none !important;
  margin: 0;
  padding: 0;
}

.mat-mdc-card-title,
.mat-mdc-card-subtitle {
  margin-bottom: 1rem !important;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
  max-width: 75%;
}

.mat-mdc-card-actions {
  gap: 0.1rem;
  margin: 0;
}

.mdc-list-item__primary-text {
  justify-content: flex-end;
}

.mat-mdc-button .mdc-button__label,
.mat-mdc-unelevated-button .mdc-button__label,
.mat-mdc-raised-button .mdc-button__label,
.mat-mdc-outlined-button .mdc-button__label {
  white-space: nowrap;
  overflow: scroll;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
  caret-color: rgba(255, 255, 255, 0.6) !important;
}

.egret-navy-dark .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: rgba(255, 255, 255, 0.6) !important;
  --mat-full-pseudo-checkbox-selected-checkmark-color: rgba(255, 255, 255, 0.6) !important;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(
    255,
    255,
    255,
    0.7
  ) !important;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: rgba(255, 255, 255, 0.6) !important;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.6) !important;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.6) !important;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: rgba(255, 255, 255, 0.6) !important;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: rgba(255, 255, 255, 0.6) !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label {
  color: rgba(255, 255, 255, 0.6) !important;
}

.product-content-wrap {
  padding-right: 20%;
}

.notification-number {
  position: absolute;
  top: 0;
  left: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
}

#langToggle .mat-select-trigger {
  width: 80px;
  min-width: 80px;
}

.theme-list .mat-menu-item {
  width: 48px;
  height: 48px;
  padding: 5px;
}
.theme-list .mat-menu-item .egret-swatch {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.theme-list .mat-menu-item .active-icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto !important;
  color: #ffffff;
}

.topbar-button-right {
  margin: 0 0.5rem !important;
}

.sidenav-hold .menuitem-badge {
  padding: 3px 10px;
  line-height: 12px;
  color: #ffffff !important;
  font-weight: 400;
  border-radius: 4px;
  font-size: 12px;
  margin-right: 8px;
}
.sidenav-hold .icon-menu {
  padding: 0 24px;
  opacity: 1;
  transition: all 0.3s ease-in;
}
.sidenav-hold .icon-menu .icon-menu-item {
  display: inline-block;
}
.sidenav-hold .icon-menu .icon-menu-item button {
  min-width: auto;
  margin: 4px;
}
.sidenav-hold .icon-menu > .mat-divider {
  margin: 1rem 0;
}
.sidenav-hold .nav-item-sep {
  padding: 0;
  margin: 0 0 1rem;
}
.sidenav-hold .nav-item-sep .icon-menu-title {
  padding-left: 0px;
  margin-left: -8px;
}
.sidenav-hold .nav-item-sep span {
  padding: 16px 0 0 16px;
  display: block;
  font-size: 12px;
}
.sidenav-hold .sidenav li {
  cursor: pointer;
}
.sidenav-hold .sidenav li ul.submenu {
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  opacity: 1;
}
.sidenav-hold .sidenav li ul.submenu.lvl2 a, .sidenav-hold .sidenav li ul.submenu.lvl3 a {
  height: 44px;
}
.sidenav-hold .sidenav li ul.submenu.lvl2 a {
  padding: 0 16px 0 55px;
}
.sidenav-hold .sidenav li ul.submenu.lvl3 a {
  padding: 0 16px 0 64px;
}
.sidenav-hold .sidenav ul.submenu.lvl2 li a {
  border-bottom: rgba(57, 57, 57, 0.2784313725);
  border-width: medium;
  border-bottom-style: ridge;
}
.sidenav-hold .sidenav a {
  position: relative;
  width: 100%;
  padding: 0 16px 0 0;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  text-decoration: none;
}
.sidenav-hold .sidenav a .item-name:is(.lvl1) {
  font-size: 1rem;
  font-weight: 500;
}
.sidenav-hold .sidenav a .item-name:is(.lvl2) {
  font-size: 0.85rem;
  font-weight: 300;
}
.sidenav-hold .sidenav a .mat-icon.sidenav-mat-icon.lvl1-icon {
  text-align: center;
  margin-right: 3px;
  height: 64px;
  width: 64px;
  font-size: 24px;
  line-height: 64px;
  border-left: 3px solid;
  border-color: transparent;
  color: #8990a2;
}
.sidenav-hold .sidenav a .mat-icon.sidenav-mat-icon.lvl2-icon {
  text-align: center;
  margin-right: 3px;
  height: 48px;
  width: 48px;
  font-size: 24px;
  line-height: 48px;
  border-left: none;
  color: #8990a2;
}
.sidenav-hold .sidenav a .svgIcon {
  padding: 15px;
  height: 48px;
  width: 48px;
  border-left: 3px solid;
  border-color: transparent;
  color: #8990a2;
  box-sizing: border-box;
}
.sidenav-hold .sidenav a .menu-caret {
  font-size: 1rem;
  line-height: 1;
  height: 16px;
  width: 16px;
  transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}

.sidebar-full .sidenav-hold li ul {
  position: relative;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s cubic-bezier(0.35, 0, 0.25, 1);
}
.sidebar-full .sidenav-hold li ul::after, .sidebar-full .sidenav-hold li ul::before {
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  height: 2px;
  z-index: 3;
}
.sidebar-full .sidenav-hold li ul::after {
  background: linear-gradient(-180deg, rgba(0, 0, 0, 0.06), transparent);
  bottom: 0;
}
.sidebar-full .sidenav-hold li ul::before {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1), transparent);
  top: 0;
}
.sidebar-full .sidenav-hold li.open, .sidebar-full .sidenav-hold li.open li.open {
  background: rgba(0, 0, 0, 0.02);
}
.sidebar-full .sidenav-hold li.open > div > ul, .sidebar-full .sidenav-hold li.open > ul {
  max-height: 1000px;
  background: rgba(0, 0, 0, 0.005);
}
.sidebar-full .sidenav-hold li.open > div > a .menu-caret, .sidebar-full .sidenav-hold li.open > a .menu-caret {
  transform: rotate(90deg);
}
.sidebar-full .sidenav-hold a {
  height: 48px;
}

.sidebar-compact .sidenav-hold .icon-menu,
.sidebar-compact-big .sidenav-hold .icon-menu {
  padding: 8px 0 0;
}
.sidebar-compact .sidenav-hold .icon-menu .nav-item-sep,
.sidebar-compact-big .sidenav-hold .icon-menu .nav-item-sep {
  display: none;
}
.sidebar-compact .sidenav-hold .nav-item-sep,
.sidebar-compact-big .sidenav-hold .nav-item-sep {
  margin: 1rem 0;
}
.sidebar-compact .sidenav-hold .nav-item-sep span,
.sidebar-compact-big .sidenav-hold .nav-item-sep span {
  display: none;
}
.sidebar-compact .sidenav-hold a,
.sidebar-compact-big .sidenav-hold a {
  padding: 0 !important;
}
.sidebar-compact .sidenav-hold li,
.sidebar-compact-big .sidenav-hold li {
  position: relative;
  z-index: 10001;
}
.sidebar-compact .sidenav-hold li .lvl1 > a > .mat-icon.menu-caret,
.sidebar-compact .sidenav-hold li .menuitem-badge,
.sidebar-compact-big .sidenav-hold li .lvl1 > a > .mat-icon.menu-caret,
.sidebar-compact-big .sidenav-hold li .menuitem-badge {
  display: none;
}
.sidebar-compact .sidenav-hold li:hover > div > ul.submenu, .sidebar-compact .sidenav-hold li:hover > ul.submenu,
.sidebar-compact-big .sidenav-hold li:hover > div > ul.submenu,
.sidebar-compact-big .sidenav-hold li:hover > ul.submenu {
  max-height: 1000px;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: all 0.4s cubic-bezier(0.35, 0, 0.25, 1);
}
.sidebar-compact .sidenav-hold li ul.submenu,
.sidebar-compact-big .sidenav-hold li ul.submenu {
  overflow: visible;
  position: absolute;
  left: 100%;
  top: 0;
  width: 200px;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  transform: translateY(10px);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
.sidebar-compact .sidenav-hold li ul.submenu.lvl2 > li > a, .sidebar-compact .sidenav-hold li ul.submenu.lvl3 > li > a,
.sidebar-compact-big .sidenav-hold li ul.submenu.lvl2 > li > a,
.sidebar-compact-big .sidenav-hold li ul.submenu.lvl3 > li > a {
  height: 36px;
  line-height: 36px;
  padding: 0 16px !important;
}

.sidebar-compact .icon-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 64px;
}
.sidebar-compact .icon-menu .icon-menu-item button {
  margin: 0 0 8px 0;
  padding: 0 12px;
  box-shadow: none;
}
.sidebar-compact .sidenav-hold .sidenav .mat-icon:not(.menu-caret) {
  margin: 0 !important;
  width: 64px !important;
}
.sidebar-compact .sidenav-hold .item-name.lvl1 {
  display: none;
}

.sidebar-compact-big .sidenav > li > div > a {
  text-align: center;
  display: block !important;
  padding: 16px 0;
}
.sidebar-compact-big .sidenav .mat-icon:not(.menu-caret) {
  height: 36px;
  width: 150px;
  font-size: 24px;
  line-height: 36px;
  margin: 0;
}

.layout-intransition .sidenav ul.submenu {
  opacity: 0 !important;
}

[dir=rtl].sidebar-full .sidenav-hold a {
  padding: 0 0 0 16px;
}
[dir=rtl].sidebar-compact .sidenav-hold a, [dir=rtl].sidebar-compact-big .sidenav-hold a {
  padding: 0 !important;
}
[dir=rtl].sidebar-compact .sidenav-hold li ul.submenu, [dir=rtl].sidebar-compact-big .sidenav-hold li ul.submenu {
  left: auto !important;
  right: 100%;
}
[dir=rtl].sidebar-compact .sidenav-hold li ul.submenu.lvl2 > li > a, [dir=rtl].sidebar-compact .sidenav-hold li ul.submenu.lvl3 > li > a, [dir=rtl].sidebar-compact-big .sidenav-hold li ul.submenu.lvl2 > li > a, [dir=rtl].sidebar-compact-big .sidenav-hold li ul.submenu.lvl3 > li > a {
  padding: 0 16px !important;
}
[dir=rtl] .sidenav-hold .sidenav a {
  padding: 0 0 0 16px;
}
[dir=rtl] .sidenav-hold .sidenav a .mat-icon:not(.menu-caret) {
  border-left: none;
  border-right: 3px solid;
  margin-right: 1px;
  border-color: transparent;
}
[dir=rtl] .sidenav-hold .sidenav a .menu-caret {
  transform: rotate(180deg);
}
[dir=rtl] .sidenav-hold .sidenav li ul.submenu.lvl2 > li > a {
  padding: 0 55px 0 16px;
}
[dir=rtl] .sidenav-hold .sidenav li ul.submenu.lvl3 > li > a {
  padding: 0 64px 0 16px;
}
[dir=rtl] .sidenav-hold .nav-item-sep .icon-menu-title {
  padding-right: 0 !important;
  margin: 0 -6px 0 0 !important;
}
[dir=rtl] .sidenav-hold .nav-item-sep span {
  padding: 16px 20px 0 0;
}

.main-footer {
  padding: 0 0.667rem;
}
.main-footer > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 64px;
}
.navigation-top .main-footer {
  margin: 0 -1rem -0.333rem;
}

.breadcrumb {
  display: block;
  margin: 0;
  padding: 0;
}
.breadcrumb li {
  list-style: none;
  float: left;
}
.breadcrumb li:not(:first-child):before {
  content: "/ ";
  padding: 0 8px;
}

.breadcrumb-bar {
  position: relative;
  width: 100%;
}
.breadcrumb-bar .breadcrumb {
  padding: 0 1rem;
  overflow: hidden;
  margin-top: 0.5rem;
}
.breadcrumb-bar .breadcrumb li {
  line-height: 40px;
}
.breadcrumb-bar .breadcrumb li:not(:first-child):before {
  padding: 0 8px;
}
.breadcrumb-bar .breadcrumb li a {
  font-weight: 400;
  font-size: 1rem;
}

.breadcrumb-title {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  line-height: 40px;
}
.breadcrumb-title .bc-title {
  font-size: 14px;
  margin: 0;
  line-height: 40px;
}
.breadcrumb-title .breadcrumb {
  position: relative;
  margin-left: 24px;
}
.breadcrumb-title .breadcrumb::before {
  position: absolute;
  content: "";
  height: 24px;
  width: 2px;
  background: #6a6a6a;
  left: -12px;
  top: 8px;
}

[dir=rtl] .breadcrumb-bar .breadcrumb li,
[dir=rtl] .breadcrumb-title .breadcrumb li {
  float: right;
}
[dir=rtl] .breadcrumb-title .breadcrumb {
  margin-left: 0;
  margin-right: 24px;
}
[dir=rtl] .breadcrumb-title .breadcrumb::before {
  left: auto;
  right: -12px;
}

.navigation-top .nofication-panel {
  padding-top: 48px;
}

.notification-list {
  min-width: 240px;
}

.notification-list .notific-item a {
  outline: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.notification-list .notific-item .message {
  font-size: 0.875rem !important;
}

.notification-list .notific-item .time {
  font-size: 0.75rem !important;
}

.sidebar-compact-switch {
  position: relative;
  width: 24px;
  height: 12px;
  border-radius: 10px;
  border: 1px solid #666;
  cursor: pointer;
  z-index: 999999;
  opacity: 1;
  transition: left 0.3s, opacity 0.1s;
}
.sidebar-compact-switch span {
  position: absolute;
  left: 1px;
  top: 1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #666;
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.sidebar-compact-switch.active {
  background: #444;
}
.sidebar-compact-switch.active span {
  left: 13px;
  background: #ffffff;
}

.branding {
  display: flex;
  align-items: center;
  position: fixed;
  padding: 0 16px;
  width: 15rem;
  height: 120px;
  line-height: 120px;
  top: 0;
  left: 0;
  z-index: 999;
  color: #444;
  background: #ffffff;
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  overflow: hidden;
}
.branding .app-logo {
  cursor: pointer;
  width: auto;
  height: 90px;
  margin-right: 8px;
}
.branding .app-logo-text {
  width: auto;
  font-size: 20px;
  font-weight: 400;
}

.app-user {
  text-align: center;
  width: 100%;
  padding: 1rem 0;
}
.app-user .app-user-photo {
  width: 72px;
  margin: 0 auto 8px;
  height: 72px;
  box-sizing: border-box;
  padding: 4px;
  border-radius: 50%;
}
.app-user .app-user-controls .mat-icon-button {
  margin: 0 2px;
}
.app-user img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.app-user .app-user-name {
  display: block;
  font-size: 0.875rem;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.96);
  margin-left: -2px;
}

.app-admin-container.sidebar-compact .sidebar-compact-switch {
  opacity: 0;
  left: -2rem;
}
.app-admin-container.sidebar-compact .branding {
  width: 64px;
  padding: 0;
}
.app-admin-container.sidebar-compact .branding .app-logo {
  margin: auto;
}
.app-admin-container.sidebar-compact .app-user {
  padding: 0.5rem 0;
}
.app-admin-container.sidebar-compact .app-user .app-user-photo {
  width: 40px;
  height: 40px;
  margin-bottom: 0;
}
.app-admin-container.sidebar-compact .app-user-name,
.app-admin-container.sidebar-compact .app-user-controls,
.app-admin-container.sidebar-compact .app-logo-text {
  display: none !important;
}

[dir=rtl] .app-admin-container.sidebar-compact .sidebar-hold {
  overflow: hidden;
}
[dir=rtl] .branding {
  top: 0;
  left: auto !important;
  right: 0;
}
[dir=rtl] .branding .app-logo {
  margin-right: 0;
  margin-left: 8px;
}

.topbar.mat-toolbar {
  position: relative;
  box-shadow: 0 10px 30px 0 rgba(47, 60, 74, 0.08);
  z-index: 1001;
}
.topbar .mat-select-value {
  font-weight: 400;
  color: #ffffff;
}

.toggle-collapsed {
  transform: rotate(0deg);
  -webkit-transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}

.sidebar-compact .toggle-collapsed {
  transform: rotate(180deg);
}

.search-bar .top-search-form {
  position: relative;
  background: #fff;
  border-radius: 40px;
  margin-right: 1rem;
  display: block;
  max-width: 220px;
  margin-left: 20px;
  box-shadow: inset 0 0 2px 2px rgba(136, 136, 136, 0.2);
}
.search-bar .top-search-form .material-icons {
  position: absolute;
  top: 50%;
  left: 10px;
  margin-top: -12px;
  color: rgba(0, 0, 0, 0.87);
}
.search-bar .top-search-form input {
  font-size: 1rem;
  padding: 0.6rem 0.75rem;
  z-index: 2;
  cursor: text;
  text-indent: 30px;
  border: none;
  background: transparent;
  width: 100%;
  outline: 0;
}

.topnav:after {
  content: "";
  display: table;
  clear: both;
}
.topnav label.menu-toggle {
  height: 48px;
  width: 48px;
  box-sizing: border-box;
  padding: 12px;
  border-radius: 50%;
}
.topnav label.menu-toggle .mat-icon {
  font-size: 24px;
}
.topnav .toggle,
.topnav [id^=drop] {
  display: none;
}
.topnav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}
.topnav ul:not(.menu) {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0), 0 4px 8px rgba(0, 0, 0, 0.28);
}
.topnav ul.menu {
  float: left;
  height: 48px;
  padding-right: 45px;
}
.topnav ul.menu > li {
  float: left;
}
.topnav ul.menu > li > div > a,
.topnav ul.menu > li > div > div {
  border-bottom: 2px solid;
  height: 48px;
  box-sizing: border-box;
  border-color: transparent;
  margin: 0 6px;
}
.topnav ul li {
  margin: 0px;
  display: inline-block;
}
.topnav a,
.topnav label {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 20px;
  height: 44px;
  font-size: 0.875rem;
  text-decoration: none;
  box-sizing: border-box;
}
.topnav a .mat-icon,
.topnav label .mat-icon {
  font-size: 16px;
  height: 20px;
  line-height: 20px;
  width: 20px;
  margin-right: 2px;
}
.topnav ul ul {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  color: rgba(0, 0, 0, 0.87);
  top: 48px;
  transform: translateY(-10px);
  transition: all 0.15s ease-in-out;
  z-index: -1;
}
.topnav ul li:hover > div > div > ul,
.topnav ul li:hover > div > ul {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.topnav ul ul li {
  width: 170px;
  float: none;
  display: list-item;
  position: relative;
}
.topnav ul ul ul {
  top: 0;
  left: 170px;
}
.topnav ul ul ul li {
  position: relative;
  top: 0;
}
.topnav li > a:after {
  content: " +";
}
.topnav li > a:only-child:after {
  content: "";
}

@media all and (max-width: 768px) {
  .topnav {
    margin: 0;
  }
  .topnav .toggle + a {
    display: none;
  }
  .topnav .menu {
    opacity: 0;
    visibility: hidden;
    height: auto !important;
    width: 100%;
  }
  .topnav .menu li a {
    border: none !important;
  }
  .topnav .toggle {
    display: flex;
    text-decoration: none;
    border: none;
  }
  .topnav ul {
    overflow: hidden;
    max-height: 0px;
    transform: translateY(0px) !important;
    transition: max-height 0.3s ease-in-out;
  }
  .topnav [id^=drop]:checked + ul {
    opacity: 1;
    visibility: visible;
    max-height: 2000px;
  }
  .topnav [id^=drop]:checked + ul.menu {
    max-height: 300px;
    overflow-y: scroll;
  }
  .topnav ul li {
    position: relative;
    opacity: 1;
    visibility: visible;
    width: 100%;
    z-index: 1;
  }
  .topnav ul ul .toggle,
  .topnav ul ul a {
    padding: 0 40px;
  }
  .topnav ul ul ul a {
    padding: 0 80px;
  }
  .topnav ul li ul li .toggle,
  .topnav ul ul a,
  .topnav ul ul ul a {
    padding: 14px 20px;
  }
  .topnav ul ul {
    float: none;
    position: relative;
    top: 0;
    left: 0;
    box-shadow: none !important;
    z-index: 1;
  }
  .topnav ul li:hover > div > div > ul,
  .topnav ul li:hover > div > ul {
    opacity: 0;
    visibility: hidden;
  }
  .topnav ul ul li {
    opacity: 1;
    visibility: visible;
    width: 100%;
  }
  .topnav ul ul ul {
    left: 0;
  }
  .topnav ul ul ul li {
    position: static;
  }
}
@media all and (max-width: 330px) {
  .topnav ul li {
    display: block;
    width: 94%;
  }
}
[dir=rtl] .topnav a .mat-icon,
[dir=rtl] .topnav label .mat-icon {
  margin-right: 0;
  margin-left: 2px;
}

.header-topnav {
  position: fixed;
  margin: 0;
  padding: 0;
  width: 100%;
  top: 0;
  z-index: 999;
}
.header-topnav .container {
  padding: 0;
}
.header-topnav .topbar-branding {
  float: left;
  height: 48px;
  padding: 8px;
  margin: 0 8px;
}
.header-topnav .topbar-branding img {
  height: 100%;
  width: auto;
}
.header-topnav .topnav {
  display: flex;
  align-items: center;
}
.header-topnav .header-topnav-right {
  float: right;
  height: 48px;
  display: flex;
  align-items: center;
  padding-right: 0.67rem;
}

@media (max-width: 959px) {
  .header-topnav-right {
    position: absolute;
    right: 6px;
    top: 0;
  }
}
[dir=rtl] .header-topnav .topnav {
  flex-direction: row-reverse;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-drag-preview .mat-ripple-element, .cdk-drag-placeholder .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-app-background, transparent));
  color: var(--mat-app-text-color, var(--mat-app-on-background, inherit));
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-option-label-text-font: Archivo;
  --mat-option-label-text-line-height: 20px;
  --mat-option-label-text-size: 14px;
  --mat-option-label-text-tracking: 0.0179em;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Archivo;
  --mat-optgroup-label-text-line-height: 20px;
  --mat-optgroup-label-text-size: 14px;
  --mat-optgroup-label-text-tracking: 0.0179em;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mat-card-title-text-font: Archivo;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0075em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Archivo;
  --mat-card-subtitle-text-line-height: 24px;
  --mat-card-subtitle-text-size: 15px;
  --mat-card-subtitle-text-tracking: 0.0067em;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Archivo;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333em;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mdc-filled-text-field-label-text-font: Archivo;
  --mdc-filled-text-field-label-text-size: 14px;
  --mdc-filled-text-field-label-text-tracking: 0.0179em;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Archivo;
  --mdc-outlined-text-field-label-text-size: 14px;
  --mdc-outlined-text-field-label-text-tracking: 0.0179em;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Archivo;
  --mat-form-field-container-text-line-height: 20px;
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-container-text-tracking: 0.0179em;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 14px;
  --mat-form-field-subscript-text-font: Archivo;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-select-trigger-text-font: Archivo;
  --mat-select-trigger-text-line-height: 20px;
  --mat-select-trigger-text-size: 14px;
  --mat-select-trigger-text-tracking: 0.0179em;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-subhead-font: Archivo;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0075em;
  --mdc-dialog-supporting-text-font: Archivo;
  --mdc-dialog-supporting-text-line-height: 20px;
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.0179em;
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Archivo;
  --mdc-chip-label-text-line-height: 24px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: 0.0179em;
  --mdc-chip-label-text-weight: 500;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}
html .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-state-layer-size: 40px;
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-font: Archivo;
  --mat-switch-label-text-line-height: 24px;
  --mat-switch-label-text-size: 14px;
  --mat-switch-label-text-tracking: 0.0179em;
  --mat-switch-label-text-weight: 500;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

html {
  --mdc-radio-state-layer-size: 40px;
  --mat-radio-touch-target-display: block;
}

html {
  --mat-radio-label-text-font: Archivo;
  --mat-radio-label-text-line-height: 24px;
  --mat-radio-label-text-size: 14px;
  --mat-radio-label-text-tracking: 0.0179em;
  --mat-radio-label-text-weight: 500;
}

html {
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
}

html {
  --mdc-slider-label-label-text-font: Archivo;
  --mdc-slider-label-label-text-size: 15px;
  --mdc-slider-label-label-text-line-height: 24px;
  --mdc-slider-label-label-text-tracking: 0.0067em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
}

html {
  --mat-menu-item-label-text-font: Archivo;
  --mat-menu-item-label-text-size: 14px;
  --mat-menu-item-label-text-tracking: 0.0179em;
  --mat-menu-item-label-text-line-height: 20px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mdc-list-list-item-label-text-font: Archivo;
  --mdc-list-list-item-label-text-line-height: 20px;
  --mdc-list-list-item-label-text-size: 14px;
  --mdc-list-list-item-label-text-tracking: 0.0179em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Archivo;
  --mdc-list-list-item-supporting-text-line-height: 24px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0179em;
  --mdc-list-list-item-supporting-text-weight: 500;
  --mdc-list-list-item-trailing-supporting-text-font: Archivo;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 16px / 28px Archivo;
  letter-spacing: 0.0094em;
}

html {
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-touch-target-display: block;
}

html {
  --mat-paginator-container-text-font: Archivo;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.0333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

html {
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Archivo;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0893em;
  --mat-tab-header-label-text-line-height: 14px;
  --mat-tab-header-label-text-weight: 500;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-touch-target-display: block;
}

html {
  --mat-checkbox-label-text-font: Archivo;
  --mat-checkbox-label-text-line-height: 24px;
  --mat-checkbox-label-text-size: 14px;
  --mat-checkbox-label-text-tracking: 0.0179em;
  --mat-checkbox-label-text-weight: 500;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mat-text-button-touch-target-display: block;
  --mat-filled-button-touch-target-display: block;
  --mat-protected-button-touch-target-display: block;
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-text-button-label-text-font: Archivo;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.0893em;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Archivo;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.0893em;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Archivo;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.0893em;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Archivo;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.0893em;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
}

html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

html {
  --mat-fab-touch-target-display: block;
  --mat-fab-small-touch-target-display: block;
}

html {
  --mdc-extended-fab-label-text-font: Archivo;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: 0.0893em;
  --mdc-extended-fab-label-text-weight: 500;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-supporting-text-font: Archivo;
  --mdc-snackbar-supporting-text-line-height: 24px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 500;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-table-header-headline-font: Archivo;
  --mat-table-header-headline-line-height: 24px;
  --mat-table-header-headline-size: 15px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0067em;
  --mat-table-row-item-label-text-font: Archivo;
  --mat-table-row-item-label-text-line-height: 24px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 500;
  --mat-table-row-item-label-text-tracking: 0.0179em;
  --mat-table-footer-supporting-text-font: Archivo;
  --mat-table-footer-supporting-text-line-height: 24px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 500;
  --mat-table-footer-supporting-text-tracking: 0.0179em;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-text-font: Archivo;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-font: Archivo;
  --mat-bottom-sheet-container-text-line-height: 24px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: 0.0179em;
  --mat-bottom-sheet-container-text-weight: 500;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-legacy-button-toggle-label-text-font: Archivo;
  --mat-legacy-button-toggle-label-text-line-height: 20px;
  --mat-legacy-button-toggle-label-text-size: 14px;
  --mat-legacy-button-toggle-label-text-tracking: 0.0179em;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: Archivo;
  --mat-standard-button-toggle-label-text-line-height: 20px;
  --mat-standard-button-toggle-label-text-size: 14px;
  --mat-standard-button-toggle-label-text-tracking: 0.0179em;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-datepicker-calendar-text-font: Archivo;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: Archivo;
  --mat-expansion-header-text-size: 15px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Archivo;
  --mat-expansion-container-text-line-height: 24px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0.0179em;
  --mat-expansion-container-text-weight: 500;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: Archivo;
  --mat-stepper-header-label-text-font: Archivo;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 500;
  --mat-stepper-header-error-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: Archivo;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0075em;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-node-min-height: 48px;
}

html {
  --mat-tree-node-text-font: Archivo;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 500;
}

.egret-navy {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: #3f51b5;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-full-pseudo-checkbox-selected-icon-color: #e91e63;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #e91e63;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #5f5f5f;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #3f51b5;
  --mdc-filled-text-field-focus-active-indicator-color: #3f51b5;
  --mdc-filled-text-field-focus-label-text-color: rgba(63, 81, 181, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #f44336;
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #3f51b5;
  --mdc-outlined-text-field-focus-outline-color: #3f51b5;
  --mdc-outlined-text-field-focus-label-text-color: rgba(63, 81, 181, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-error-hover-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-focus-select-arrow-color: rgba(63, 81, 181, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #f44336;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(63, 81, 181, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-selected-focus-state-layer-color: #3949ab;
  --mdc-switch-selected-handle-color: #3949ab;
  --mdc-switch-selected-hover-state-layer-color: #3949ab;
  --mdc-switch-selected-pressed-state-layer-color: #3949ab;
  --mdc-switch-selected-focus-handle-color: #1a237e;
  --mdc-switch-selected-hover-handle-color: #1a237e;
  --mdc-switch-selected-pressed-handle-color: #1a237e;
  --mdc-switch-selected-focus-track-color: #7986cb;
  --mdc-switch-selected-hover-track-color: #7986cb;
  --mdc-switch-selected-pressed-track-color: #7986cb;
  --mdc-switch-selected-track-color: #7986cb;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-handle-color: #3f51b5;
  --mdc-slider-focus-handle-color: #3f51b5;
  --mdc-slider-hover-handle-color: #3f51b5;
  --mdc-slider-active-track-color: #3f51b5;
  --mdc-slider-inactive-track-color: #3f51b5;
  --mdc-slider-with-tick-marks-inactive-container-color: #3f51b5;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: #3f51b5;
  --mat-slider-hover-state-layer-color: rgba(63, 81, 181, 0.05);
  --mat-slider-focus-state-layer-color: rgba(63, 81, 181, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-focus-icon-color: #e91e63;
  --mdc-checkbox-selected-hover-icon-color: #e91e63;
  --mdc-checkbox-selected-icon-color: #e91e63;
  --mdc-checkbox-selected-pressed-icon-color: #e91e63;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #e91e63;
  --mdc-checkbox-selected-hover-state-layer-color: #e91e63;
  --mdc-checkbox-selected-pressed-state-layer-color: #e91e63;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(44, 44, 44, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(44, 44, 44, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #e91e63;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #3f51b5;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #3f51b5;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #c7c7c7;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #e1e1e1;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #aeaeae;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #c7c7c7;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #aeaeae;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-background-color: #3f51b5;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(63, 81, 181, 0.4);
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(63, 81, 181, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(63, 81, 181, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #3f51b5;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(63, 81, 181, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(44, 44, 44, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(44, 44, 44, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-selected-state-icon-background-color: #3f51b5;
  --mat-stepper-header-done-state-icon-background-color: #3f51b5;
  --mat-stepper-header-edit-state-icon-background-color: #3f51b5;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(44, 44, 44, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(44, 44, 44, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #757575;
  --mat-toolbar-container-background-color: #ededed;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-accent {
  --mat-option-selected-state-label-text-color: #e91e63;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.egret-navy .mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.egret-navy .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #3f51b5;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #3f51b5;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.egret-navy .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #e91e63;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #e91e63;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.egret-navy .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.egret-navy .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #3f51b5;
  --mdc-linear-progress-track-color: rgba(63, 81, 181, 0.25);
}
.egret-navy .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #e91e63;
  --mdc-linear-progress-track-color: rgba(233, 30, 99, 0.25);
}
.egret-navy .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}
.egret-navy .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #e91e63;
  --mdc-filled-text-field-focus-active-indicator-color: #e91e63;
  --mdc-filled-text-field-focus-label-text-color: rgba(233, 30, 99, 0.87);
  --mdc-outlined-text-field-caret-color: #e91e63;
  --mdc-outlined-text-field-focus-outline-color: #e91e63;
  --mdc-outlined-text-field-focus-label-text-color: rgba(233, 30, 99, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(233, 30, 99, 0.87);
}
.egret-navy .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(244, 67, 54, 0.87);
}
.egret-navy .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(233, 30, 99, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
.egret-navy .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
.egret-navy .mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.egret-navy .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.egret-navy .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .egret-navy .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-elevated-container-color: #3f51b5;
  --mdc-chip-elevated-selected-container-color: #3f51b5;
  --mdc-chip-elevated-disabled-container-color: #3f51b5;
  --mdc-chip-flat-disabled-selected-container-color: #3f51b5;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
}
.egret-navy .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .egret-navy .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-elevated-container-color: #e91e63;
  --mdc-chip-elevated-selected-container-color: #e91e63;
  --mdc-chip-elevated-disabled-container-color: #e91e63;
  --mdc-chip-flat-disabled-selected-container-color: #e91e63;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
}
.egret-navy .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .egret-navy .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-selected-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-flat-disabled-selected-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
}
.egret-navy .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}
.egret-navy .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #d81b60;
  --mdc-switch-selected-handle-color: #d81b60;
  --mdc-switch-selected-hover-state-layer-color: #d81b60;
  --mdc-switch-selected-pressed-state-layer-color: #d81b60;
  --mdc-switch-selected-focus-handle-color: #880e4f;
  --mdc-switch-selected-hover-handle-color: #880e4f;
  --mdc-switch-selected-pressed-handle-color: #880e4f;
  --mdc-switch-selected-focus-track-color: #f06292;
  --mdc-switch-selected-hover-track-color: #f06292;
  --mdc-switch-selected-pressed-track-color: #f06292;
  --mdc-switch-selected-track-color: #f06292;
}
.egret-navy .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}
.egret-navy .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3f51b5;
  --mdc-radio-selected-hover-icon-color: #3f51b5;
  --mdc-radio-selected-icon-color: #3f51b5;
  --mdc-radio-selected-pressed-icon-color: #3f51b5;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #3f51b5;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e91e63;
  --mdc-radio-selected-hover-icon-color: #e91e63;
  --mdc-radio-selected-icon-color: #e91e63;
  --mdc-radio-selected-pressed-icon-color: #e91e63;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #e91e63;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-accent {
  --mdc-slider-handle-color: #e91e63;
  --mdc-slider-focus-handle-color: #e91e63;
  --mdc-slider-hover-handle-color: #e91e63;
  --mdc-slider-active-track-color: #e91e63;
  --mdc-slider-inactive-track-color: #e91e63;
  --mdc-slider-with-tick-marks-inactive-container-color: #e91e63;
  --mat-slider-ripple-color: #e91e63;
  --mat-slider-hover-state-layer-color: rgba(233, 30, 99, 0.05);
  --mat-slider-focus-state-layer-color: rgba(233, 30, 99, 0.2);
}
.egret-navy .mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-slider-ripple-color: #f44336;
  --mat-slider-hover-state-layer-color: rgba(244, 67, 54, 0.05);
  --mat-slider-focus-state-layer-color: rgba(244, 67, 54, 0.2);
}
.egret-navy .mdc-list-item__start,
.egret-navy .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3f51b5;
  --mdc-radio-selected-hover-icon-color: #3f51b5;
  --mdc-radio-selected-icon-color: #3f51b5;
  --mdc-radio-selected-pressed-icon-color: #3f51b5;
}
.egret-navy .mat-accent .mdc-list-item__start,
.egret-navy .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e91e63;
  --mdc-radio-selected-hover-icon-color: #e91e63;
  --mdc-radio-selected-icon-color: #e91e63;
  --mdc-radio-selected-pressed-icon-color: #e91e63;
}
.egret-navy .mat-warn .mdc-list-item__start,
.egret-navy .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}
.egret-navy .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-focus-icon-color: #3f51b5;
  --mdc-checkbox-selected-hover-icon-color: #3f51b5;
  --mdc-checkbox-selected-icon-color: #3f51b5;
  --mdc-checkbox-selected-pressed-icon-color: #3f51b5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-hover-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-pressed-state-layer-color: #3f51b5;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.egret-navy .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-focus-icon-color: #e91e63;
  --mdc-checkbox-selected-hover-icon-color: #e91e63;
  --mdc-checkbox-selected-icon-color: #e91e63;
  --mdc-checkbox-selected-pressed-icon-color: #e91e63;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #e91e63;
  --mdc-checkbox-selected-hover-state-layer-color: #e91e63;
  --mdc-checkbox-selected-pressed-state-layer-color: #e91e63;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.egret-navy .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.egret-navy .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.egret-navy .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.egret-navy .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.egret-navy .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #3f51b5;
}
.egret-navy .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.egret-navy .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.egret-navy .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.egret-navy .mat-mdc-tab-group, .egret-navy .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #3f51b5;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #3f51b5;
  --mat-tab-header-active-ripple-color: #3f51b5;
  --mat-tab-header-inactive-ripple-color: #3f51b5;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #3f51b5;
  --mat-tab-header-active-hover-label-text-color: #3f51b5;
  --mat-tab-header-active-focus-indicator-color: #3f51b5;
  --mat-tab-header-active-hover-indicator-color: #3f51b5;
}
.egret-navy .mat-mdc-tab-group.mat-accent, .egret-navy .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #e91e63;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #e91e63;
  --mat-tab-header-active-ripple-color: #e91e63;
  --mat-tab-header-inactive-ripple-color: #e91e63;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #e91e63;
  --mat-tab-header-active-hover-label-text-color: #e91e63;
  --mat-tab-header-active-focus-indicator-color: #e91e63;
  --mat-tab-header-active-hover-indicator-color: #e91e63;
}
.egret-navy .mat-mdc-tab-group.mat-warn, .egret-navy .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.egret-navy .mat-mdc-tab-group.mat-background-primary, .egret-navy .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #3f51b5;
}
.egret-navy .mat-mdc-tab-group.mat-background-accent, .egret-navy .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #e91e63;
}
.egret-navy .mat-mdc-tab-group.mat-background-warn, .egret-navy .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
}
.egret-navy .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-focus-icon-color: #3f51b5;
  --mdc-checkbox-selected-hover-icon-color: #3f51b5;
  --mdc-checkbox-selected-icon-color: #3f51b5;
  --mdc-checkbox-selected-pressed-icon-color: #3f51b5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-hover-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-pressed-state-layer-color: #3f51b5;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.egret-navy .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.egret-navy .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #3f51b5;
  --mat-text-button-state-layer-color: #3f51b5;
  --mat-text-button-ripple-color: rgba(63, 81, 181, 0.1);
}
.egret-navy .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #e91e63;
  --mat-text-button-state-layer-color: #e91e63;
  --mat-text-button-ripple-color: rgba(233, 30, 99, 0.1);
}
.egret-navy .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.egret-navy .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #3f51b5;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.egret-navy .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #e91e63;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.egret-navy .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.egret-navy .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #3f51b5;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.egret-navy .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #e91e63;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.egret-navy .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.egret-navy .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #3f51b5;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #3f51b5;
  --mat-outlined-button-ripple-color: rgba(63, 81, 181, 0.1);
}
.egret-navy .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #e91e63;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #e91e63;
  --mat-outlined-button-ripple-color: rgba(233, 30, 99, 0.1);
}
.egret-navy .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.egret-navy .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #3f51b5;
  --mat-icon-button-state-layer-color: #3f51b5;
  --mat-icon-button-ripple-color: rgba(63, 81, 181, 0.1);
}
.egret-navy .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #e91e63;
  --mat-icon-button-state-layer-color: #e91e63;
  --mat-icon-button-ripple-color: rgba(233, 30, 99, 0.1);
}
.egret-navy .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-icon-button-state-layer-color: #f44336;
  --mat-icon-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.egret-navy .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #3f51b5;
  --mat-fab-foreground-color: #fff;
  --mat-fab-state-layer-color: #fff;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.egret-navy .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #e91e63;
  --mat-fab-foreground-color: #fff;
  --mat-fab-state-layer-color: #fff;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.egret-navy .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mat-fab-foreground-color: #fff;
  --mat-fab-state-layer-color: #fff;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.egret-navy .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #3f51b5;
  --mat-fab-small-foreground-color: #fff;
  --mat-fab-small-state-layer-color: #fff;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.egret-navy .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #e91e63;
  --mat-fab-small-foreground-color: #fff;
  --mat-fab-small-state-layer-color: #fff;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.egret-navy .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #f44336;
  --mat-fab-small-foreground-color: #fff;
  --mat-fab-small-state-layer-color: #fff;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.egret-navy .mat-accent {
  --mdc-circular-progress-active-indicator-color: #e91e63;
}
.egret-navy .mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}
.egret-navy .mat-badge-accent {
  --mat-badge-background-color: #e91e63;
}
.egret-navy .mat-badge-warn {
  --mat-badge-background-color: #f44336;
}
.egret-navy .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-background-color: #e91e63;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(233, 30, 99, 0.4);
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(233, 30, 99, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(233, 30, 99, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(233, 30, 99, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.egret-navy .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.egret-navy .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #e91e63;
}
.egret-navy .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}
.egret-navy .mat-icon.mat-primary {
  --mat-icon-color: #3f51b5;
}
.egret-navy .mat-icon.mat-accent {
  --mat-icon-color: #e91e63;
}
.egret-navy .mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}
.egret-navy .mat-step-header.mat-accent {
  --mat-stepper-header-selected-state-icon-background-color: #e91e63;
  --mat-stepper-header-done-state-icon-background-color: #e91e63;
  --mat-stepper-header-edit-state-icon-background-color: #e91e63;
}
.egret-navy .mat-step-header.mat-warn {
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
}
.egret-navy .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #3f51b5;
}
.egret-navy .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #e91e63;
}
.egret-navy .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
}
.egret-navy .mat-datetimepicker-calendar-header {
  background-color: #3f51b5;
}
.egret-navy .mat-datetimepicker-calendar-body-cell-content.mat-datetimepicker-calendar-body-selected {
  background-color: #3f51b5;
  color: white;
}
.egret-navy .mat-datetimepicker-calendar-body-cell-content.mat-datetimepicker-calendar-body-disabled > .mat-datetimepicker-calendar-body-selected {
  background-color: rgba(63, 81, 181, 0.4);
}
.egret-navy .mat-datetimepicker-calendar-body-cell-content.mat-datetimepicker-calendar-body-today:not(.mat-datetimepicker-calendar-body-selected) {
  border-color: #3f51b5;
}
.egret-navy .mat-datetimepicker-calendar-body-cell-content.mat-datetimepicker-calendar-body-today.mat-datetimepicker-calendar-body-selected {
  box-shadow: inset 0 0 0 1px;
}
.egret-navy .mat-datetimepicker-clock-center {
  background-color: #3f51b5;
}
.egret-navy .mat-datetimepicker-clock-hand {
  background-color: #3f51b5;
}
.egret-navy .mat-datetimepicker-clock-hand::before {
  background-color: #3f51b5;
}
.egret-navy .mat-datetimepicker-clock-cell.mat-datetimepicker-clock-cell-selected {
  background-color: #4d5ec1;
}
.egret-navy .mat-datetimepicker-calendar-previous-button, .egret-navy .mat-datetimepicker-calendar-next-button {
  color: black;
}
.egret-navy .text-muted,
.egret-navy .mat-heading-label {
  color: rgba(0, 0, 0, 0.54) !important;
}
.egret-navy .mat-bg-chip {
  background-color: #e1e1e1;
}
.egret-navy .mat-bg-card {
  background-color: white;
}
.egret-navy .mat-bg-default {
  background-color: #fafafa;
}
.egret-navy .branding {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
@media only screen and (max-width: 959px) {
  .egret-navy .mat-bg-default--mobile {
    background-color: #fafafa;
  }
  .egret-navy .mat-bg-card--mobile {
    background-color: white;
  }
}
.egret-navy .ngx-datatable.material {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.egret-navy .ngx-datatable .datatable-header-cell,
.egret-navy .ngx-datatable .datatable-body-cell,
.egret-navy .page-count,
.egret-navy .ngx-datatable .datatable-footer .datatable-pager a {
  color: rgba(0, 0, 0, 0.87) !important;
}
.egret-navy .ngx-datatable .datatable-footer .datatable-pager .pager li.disabled a {
  color: rgba(0, 0, 0, 0.38) !important;
}
.egret-navy .ngx-datatable.material .datatable-header {
  background: #fafafa;
}
.egret-navy .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.egret-navy .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: rgba(44, 44, 44, 0.04);
}
.egret-navy .cal-month-view {
  background-color: white !important;
}
.egret-navy .cal-month-view .cal-day-cell:not(:last-child),
.egret-navy .cal-month-view .cal-days .cal-cell-row,
.egret-navy .cal-month-view .cal-days {
  border-color: rgba(0, 0, 0, 0.12) !important;
}
.egret-navy .cal-month-view .cal-cell-row .cal-cell:hover,
.egret-navy .cal-month-view .cal-cell.cal-has-events.cal-open,
.egret-navy .cal-month-view .cal-cell-row:hover,
.egret-navy .cal-month-view .cal-day-cell.cal-today {
  background-color: #fafafa;
}
.egret-navy .cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  color: white;
  opacity: 0.5;
}
.egret-navy .cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
  opacity: 0.1 !important;
}
.egret-navy .cal-month-view .cal-day-cell.cal-today {
  color: #FFAC1C !important;
}
.egret-navy div.hopscotch-bubble {
  background-color: white !important;
  border-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.egret-navy div.hopscotch-bubble .hopscotch-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
.egret-navy div.hopscotch-bubble .hopscotch-bubble-number {
  background-image: none !important;
}
.egret-navy .hopscotch-next {
  background-color: !important;
}
.egret-navy div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow-border {
  border-bottom: 17px solid rgba(0, 0, 0, 0.12);
}
.egret-navy div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow {
  border-bottom: 17px solid white;
  top: -16px;
}
.egret-navy div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow-border {
  border-left: 17px solid rgba(0, 0, 0, 0.12);
}
.egret-navy div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow {
  border-left: 17px solid white;
  left: -1px;
}
.egret-navy div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow-border {
  border-top: 17px solid rgba(0, 0, 0, 0.12);
}
.egret-navy div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow {
  border-top: 17px solid white;
  top: -18px;
}
.egret-navy div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow-border {
  border-right: 17px solid rgba(0, 0, 0, 0.12);
}
.egret-navy div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow {
  left: 1px;
  border-right: 17px solid white;
}
.egret-navy .header-topnav,
.egret-navy .topnav ul ul {
  background-color: white;
}
.egret-navy .topnav ul li ul li:hover,
.egret-navy .topnav ul li ul li.open {
  background: #fafafa;
}
.egret-navy .header-topnav a {
  color: rgba(0, 0, 0, 0.87);
}

.egret-navy-dark {
  --mat-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-option-selected-state-label-text-color: #3f51b5;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-optgroup-label-text-color: white;
  --mat-full-pseudo-checkbox-selected-icon-color: #e91e63;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #1a2038;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #1a2038;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #e91e63;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  --mat-app-background-color: #1a2038;
  --mat-app-text-color: white;
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: #222a49;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #222a49;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(255, 255, 255, 0.7);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #3a487e;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #3f51b5;
  --mdc-filled-text-field-focus-active-indicator-color: #3f51b5;
  --mdc-filled-text-field-focus-label-text-color: rgba(63, 81, 181, 0.87);
  --mdc-filled-text-field-container-color: #2b3350;
  --mdc-filled-text-field-disabled-container-color: #262e4d;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #f44336;
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(255, 255, 255, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(255, 255, 255, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #3f51b5;
  --mdc-outlined-text-field-focus-outline-color: #3f51b5;
  --mdc-outlined-text-field-focus-label-text-color: rgba(63, 81, 181, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-error-hover-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(255, 255, 255, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-focus-select-arrow-color: rgba(63, 81, 181, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(255, 255, 255, 0.38);
  --mat-form-field-state-layer-color: rgba(255, 255, 255, 0.87);
  --mat-form-field-error-text-color: #f44336;
  --mat-form-field-select-option-text-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-focus-state-layer-opacity: 0.24;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: #222a49;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(63, 81, 181, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: #222a49;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: #222a49;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-selected-focus-state-layer-color: #7986cb;
  --mdc-switch-selected-handle-color: #7986cb;
  --mdc-switch-selected-hover-state-layer-color: #7986cb;
  --mdc-switch-selected-pressed-state-layer-color: #7986cb;
  --mdc-switch-selected-focus-handle-color: #9fa8da;
  --mdc-switch-selected-hover-handle-color: #9fa8da;
  --mdc-switch-selected-pressed-handle-color: #9fa8da;
  --mdc-switch-selected-focus-track-color: #3949ab;
  --mdc-switch-selected-hover-track-color: #3949ab;
  --mdc-switch-selected-pressed-track-color: #3949ab;
  --mdc-switch-selected-track-color: #3949ab;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-handle-color: #3f51b5;
  --mdc-slider-focus-handle-color: #3f51b5;
  --mdc-slider-hover-handle-color: #3f51b5;
  --mdc-slider-active-track-color: #3f51b5;
  --mdc-slider-inactive-track-color: #3f51b5;
  --mdc-slider-with-tick-marks-inactive-container-color: #3f51b5;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-label-container-color: #fff;
  --mdc-slider-label-label-text-color: #000;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mat-slider-ripple-color: #3f51b5;
  --mat-slider-hover-state-layer-color: rgba(63, 81, 181, 0.05);
  --mat-slider-focus-state-layer-color: rgba(63, 81, 181, 0.2);
  --mat-slider-value-indicator-opacity: 0.9;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #222a49;
  --mat-menu-divider-color: rgba(255, 255, 255, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: rgba(255, 255, 255, 0.7);
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #222a49;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-focus-icon-color: #e91e63;
  --mdc-checkbox-selected-hover-icon-color: #e91e63;
  --mdc-checkbox-selected-icon-color: #e91e63;
  --mdc-checkbox-selected-pressed-icon-color: #e91e63;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #e91e63;
  --mdc-checkbox-selected-hover-state-layer-color: #e91e63;
  --mdc-checkbox-selected-pressed-state-layer-color: #e91e63;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
  --mat-checkbox-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mat-checkbox-label-text-color: white;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: white;
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mat-text-button-state-layer-color: white;
  --mat-text-button-disabled-state-layer-color: white;
  --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.24;
  --mat-text-button-pressed-state-layer-opacity: 0.24;
  --mdc-filled-button-container-color: #222a49;
  --mdc-filled-button-label-text-color: white;
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-disabled-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.24;
  --mat-filled-button-pressed-state-layer-opacity: 0.24;
  --mdc-protected-button-container-color: #222a49;
  --mdc-protected-button-label-text-color: white;
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-disabled-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.24;
  --mat-protected-button-pressed-state-layer-opacity: 0.24;
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-label-text-color: white;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: white;
  --mat-outlined-button-disabled-state-layer-color: white;
  --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.24;
  --mat-outlined-button-pressed-state-layer-opacity: 0.24;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
  --mat-icon-button-state-layer-color: white;
  --mat-icon-button-disabled-state-layer-color: white;
  --mat-icon-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.24;
  --mat-icon-button-pressed-state-layer-opacity: 0.24;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: #222a49;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-disabled-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.24;
  --mat-fab-pressed-state-layer-opacity: 0.24;
  --mat-fab-disabled-state-container-color: rgba(250, 250, 250, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(255, 255, 255, 0.5);
  --mdc-fab-small-container-color: #222a49;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-disabled-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.24;
  --mat-fab-small-pressed-state-layer-opacity: 0.24;
  --mat-fab-small-disabled-state-container-color: rgba(250, 250, 250, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(255, 255, 255, 0.5);
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #d3d4db;
  --mdc-snackbar-supporting-text-color: rgba(34, 42, 73, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: #222a49;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #3f51b5;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #3f51b5;
  --mat-badge-disabled-state-background-color: #5f6374;
  --mat-badge-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #222a49;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: rgba(255, 255, 255, 0.5);
  --mat-legacy-button-toggle-state-layer-color: rgba(255, 255, 255, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(255, 255, 255, 0.7);
  --mat-legacy-button-toggle-selected-state-background-color: #3a487e;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-legacy-button-toggle-disabled-state-background-color: #2a345b;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #4b5ca0;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #222a49;
  --mat-standard-button-toggle-state-layer-color: white;
  --mat-standard-button-toggle-selected-state-background-color: #3a487e;
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-standard-button-toggle-disabled-state-background-color: #222a49;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #4b5ca0;
  --mat-standard-button-toggle-divider-color: #3d445f;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-background-color: #3f51b5;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(63, 81, 181, 0.4);
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(63, 81, 181, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(63, 81, 181, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #3f51b5;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(63, 81, 181, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: white;
  --mat-datepicker-calendar-body-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-period-button-text-color: white;
  --mat-datepicker-calendar-period-button-icon-color: white;
  --mat-datepicker-calendar-navigation-button-icon-color: white;
  --mat-datepicker-calendar-header-divider-color: rgba(255, 255, 255, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-date-today-outline-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(255, 255, 255, 0.3);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(255, 255, 255, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-container-background-color: #222a49;
  --mat-datepicker-calendar-container-text-color: white;
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(255, 255, 255, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: #222a49;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(255, 255, 255, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(250, 250, 250, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(250, 250, 250, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: rgba(255, 255, 255, 0.7);
  --mat-expansion-header-indicator-color: rgba(255, 255, 255, 0.7);
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(255, 255, 255, 0.12);
  --mat-sidenav-container-background-color: #222a49;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #1a2038;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(221, 213, 182, 0.6);
  --mat-stepper-header-selected-state-icon-background-color: #3f51b5;
  --mat-stepper-header-done-state-icon-background-color: #3f51b5;
  --mat-stepper-header-edit-state-icon-background-color: #3f51b5;
  --mat-stepper-container-color: #222a49;
  --mat-stepper-line-color: rgba(255, 255, 255, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(250, 250, 250, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(250, 250, 250, 0.04);
  --mat-stepper-header-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-optional-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #bdbfc8;
  --mat-toolbar-container-background-color: #222a49;
  --mat-toolbar-container-text-color: white;
  --mat-tree-container-background-color: #222a49;
  --mat-tree-node-text-color: white;
  color: #ffffff;
}
.egret-navy-dark .mat-accent {
  --mat-option-selected-state-label-text-color: #e91e63;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.egret-navy-dark .mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.egret-navy-dark .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #3f51b5;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #1a2038;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #1a2038;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #3f51b5;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.egret-navy-dark .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #e91e63;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #1a2038;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #1a2038;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #e91e63;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.egret-navy-dark .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #1a2038;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #1a2038;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.egret-navy-dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #3f51b5;
  --mdc-linear-progress-track-color: rgba(63, 81, 181, 0.25);
}
.egret-navy-dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #e91e63;
  --mdc-linear-progress-track-color: rgba(233, 30, 99, 0.25);
}
.egret-navy-dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}
.egret-navy-dark .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #e91e63;
  --mdc-filled-text-field-focus-active-indicator-color: #e91e63;
  --mdc-filled-text-field-focus-label-text-color: rgba(233, 30, 99, 0.87);
  --mdc-outlined-text-field-caret-color: #e91e63;
  --mdc-outlined-text-field-focus-outline-color: #e91e63;
  --mdc-outlined-text-field-focus-label-text-color: rgba(233, 30, 99, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(233, 30, 99, 0.87);
}
.egret-navy-dark .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(244, 67, 54, 0.87);
}
.egret-navy-dark .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #222a49;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(233, 30, 99, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
.egret-navy-dark .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #222a49;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
.egret-navy-dark .mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.egret-navy-dark .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #3d445f;
  --mdc-chip-elevated-selected-container-color: #3d445f;
  --mdc-chip-elevated-disabled-container-color: #3d445f;
  --mdc-chip-flat-disabled-selected-container-color: #3d445f;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-selected-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mat-chip-selected-disabled-trailing-icon-color: #fafafa;
  --mat-chip-selected-trailing-icon-color: #fafafa;
}
.egret-navy-dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .egret-navy-dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-elevated-container-color: #3f51b5;
  --mdc-chip-elevated-selected-container-color: #3f51b5;
  --mdc-chip-elevated-disabled-container-color: #3f51b5;
  --mdc-chip-flat-disabled-selected-container-color: #3f51b5;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
}
.egret-navy-dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .egret-navy-dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-elevated-container-color: #e91e63;
  --mdc-chip-elevated-selected-container-color: #e91e63;
  --mdc-chip-elevated-disabled-container-color: #e91e63;
  --mdc-chip-flat-disabled-selected-container-color: #e91e63;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
}
.egret-navy-dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .egret-navy-dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-selected-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-flat-disabled-selected-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
}
.egret-navy-dark .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}
.egret-navy-dark .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: white;
}
.egret-navy-dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #f06292;
  --mdc-switch-selected-handle-color: #f06292;
  --mdc-switch-selected-hover-state-layer-color: #f06292;
  --mdc-switch-selected-pressed-state-layer-color: #f06292;
  --mdc-switch-selected-focus-handle-color: #f48fb1;
  --mdc-switch-selected-hover-handle-color: #f48fb1;
  --mdc-switch-selected-pressed-handle-color: #f48fb1;
  --mdc-switch-selected-focus-track-color: #d81b60;
  --mdc-switch-selected-hover-track-color: #d81b60;
  --mdc-switch-selected-pressed-track-color: #d81b60;
  --mdc-switch-selected-track-color: #d81b60;
}
.egret-navy-dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e57373;
  --mdc-switch-selected-handle-color: #e57373;
  --mdc-switch-selected-hover-state-layer-color: #e57373;
  --mdc-switch-selected-pressed-state-layer-color: #e57373;
  --mdc-switch-selected-focus-handle-color: #ef9a9a;
  --mdc-switch-selected-hover-handle-color: #ef9a9a;
  --mdc-switch-selected-pressed-handle-color: #ef9a9a;
  --mdc-switch-selected-focus-track-color: #e53935;
  --mdc-switch-selected-hover-track-color: #e53935;
  --mdc-switch-selected-pressed-track-color: #e53935;
  --mdc-switch-selected-track-color: #e53935;
}
.egret-navy-dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #3f51b5;
  --mdc-radio-selected-hover-icon-color: #3f51b5;
  --mdc-radio-selected-icon-color: #3f51b5;
  --mdc-radio-selected-pressed-icon-color: #3f51b5;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #3f51b5;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mat-radio-label-text-color: white;
}
.egret-navy-dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #e91e63;
  --mdc-radio-selected-hover-icon-color: #e91e63;
  --mdc-radio-selected-icon-color: #e91e63;
  --mdc-radio-selected-pressed-icon-color: #e91e63;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #e91e63;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mat-radio-label-text-color: white;
}
.egret-navy-dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mat-radio-label-text-color: white;
}
.egret-navy-dark .mat-accent {
  --mdc-slider-handle-color: #e91e63;
  --mdc-slider-focus-handle-color: #e91e63;
  --mdc-slider-hover-handle-color: #e91e63;
  --mdc-slider-active-track-color: #e91e63;
  --mdc-slider-inactive-track-color: #e91e63;
  --mdc-slider-with-tick-marks-inactive-container-color: #e91e63;
  --mat-slider-ripple-color: #e91e63;
  --mat-slider-hover-state-layer-color: rgba(233, 30, 99, 0.05);
  --mat-slider-focus-state-layer-color: rgba(233, 30, 99, 0.2);
}
.egret-navy-dark .mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-slider-ripple-color: #f44336;
  --mat-slider-hover-state-layer-color: rgba(244, 67, 54, 0.05);
  --mat-slider-focus-state-layer-color: rgba(244, 67, 54, 0.2);
}
.egret-navy-dark .mdc-list-item__start,
.egret-navy-dark .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #3f51b5;
  --mdc-radio-selected-hover-icon-color: #3f51b5;
  --mdc-radio-selected-icon-color: #3f51b5;
  --mdc-radio-selected-pressed-icon-color: #3f51b5;
}
.egret-navy-dark .mat-accent .mdc-list-item__start,
.egret-navy-dark .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #e91e63;
  --mdc-radio-selected-hover-icon-color: #e91e63;
  --mdc-radio-selected-icon-color: #e91e63;
  --mdc-radio-selected-pressed-icon-color: #e91e63;
}
.egret-navy-dark .mat-warn .mdc-list-item__start,
.egret-navy-dark .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}
.egret-navy-dark .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-focus-icon-color: #3f51b5;
  --mdc-checkbox-selected-hover-icon-color: #3f51b5;
  --mdc-checkbox-selected-icon-color: #3f51b5;
  --mdc-checkbox-selected-pressed-icon-color: #3f51b5;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-hover-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-pressed-state-layer-color: #3f51b5;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.egret-navy-dark .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-focus-icon-color: #e91e63;
  --mdc-checkbox-selected-hover-icon-color: #e91e63;
  --mdc-checkbox-selected-icon-color: #e91e63;
  --mdc-checkbox-selected-pressed-icon-color: #e91e63;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #e91e63;
  --mdc-checkbox-selected-hover-state-layer-color: #e91e63;
  --mdc-checkbox-selected-pressed-state-layer-color: #e91e63;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.egret-navy-dark .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.egret-navy-dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.egret-navy-dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.egret-navy-dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.egret-navy-dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #3f51b5;
}
.egret-navy-dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.egret-navy-dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.egret-navy-dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.egret-navy-dark .mat-mdc-tab-group, .egret-navy-dark .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #3f51b5;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #3f51b5;
  --mat-tab-header-active-ripple-color: #3f51b5;
  --mat-tab-header-inactive-ripple-color: #3f51b5;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #3f51b5;
  --mat-tab-header-active-hover-label-text-color: #3f51b5;
  --mat-tab-header-active-focus-indicator-color: #3f51b5;
  --mat-tab-header-active-hover-indicator-color: #3f51b5;
}
.egret-navy-dark .mat-mdc-tab-group.mat-accent, .egret-navy-dark .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #e91e63;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #e91e63;
  --mat-tab-header-active-ripple-color: #e91e63;
  --mat-tab-header-inactive-ripple-color: #e91e63;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #e91e63;
  --mat-tab-header-active-hover-label-text-color: #e91e63;
  --mat-tab-header-active-focus-indicator-color: #e91e63;
  --mat-tab-header-active-hover-indicator-color: #e91e63;
}
.egret-navy-dark .mat-mdc-tab-group.mat-warn, .egret-navy-dark .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.egret-navy-dark .mat-mdc-tab-group.mat-background-primary, .egret-navy-dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #3f51b5;
}
.egret-navy-dark .mat-mdc-tab-group.mat-background-accent, .egret-navy-dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #e91e63;
}
.egret-navy-dark .mat-mdc-tab-group.mat-background-warn, .egret-navy-dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
}
.egret-navy-dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-focus-icon-color: #3f51b5;
  --mdc-checkbox-selected-hover-icon-color: #3f51b5;
  --mdc-checkbox-selected-icon-color: #3f51b5;
  --mdc-checkbox-selected-pressed-icon-color: #3f51b5;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-hover-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-pressed-state-layer-color: #3f51b5;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.egret-navy-dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.egret-navy-dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #3f51b5;
  --mat-text-button-state-layer-color: #3f51b5;
  --mat-text-button-ripple-color: rgba(63, 81, 181, 0.1);
}
.egret-navy-dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #e91e63;
  --mat-text-button-state-layer-color: #e91e63;
  --mat-text-button-ripple-color: rgba(233, 30, 99, 0.1);
}
.egret-navy-dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.egret-navy-dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #3f51b5;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.egret-navy-dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #e91e63;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.egret-navy-dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.egret-navy-dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #3f51b5;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.egret-navy-dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #e91e63;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.egret-navy-dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.egret-navy-dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #3f51b5;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: #3f51b5;
  --mat-outlined-button-ripple-color: rgba(63, 81, 181, 0.1);
}
.egret-navy-dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #e91e63;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: #e91e63;
  --mat-outlined-button-ripple-color: rgba(233, 30, 99, 0.1);
}
.egret-navy-dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.egret-navy-dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #3f51b5;
  --mat-icon-button-state-layer-color: #3f51b5;
  --mat-icon-button-ripple-color: rgba(63, 81, 181, 0.1);
}
.egret-navy-dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #e91e63;
  --mat-icon-button-state-layer-color: #e91e63;
  --mat-icon-button-ripple-color: rgba(233, 30, 99, 0.1);
}
.egret-navy-dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-icon-button-state-layer-color: #f44336;
  --mat-icon-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.egret-navy-dark .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #3f51b5;
  --mat-fab-foreground-color: #fff;
  --mat-fab-state-layer-color: #fff;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.egret-navy-dark .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #e91e63;
  --mat-fab-foreground-color: #fff;
  --mat-fab-state-layer-color: #fff;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.egret-navy-dark .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mat-fab-foreground-color: #fff;
  --mat-fab-state-layer-color: #fff;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.egret-navy-dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #3f51b5;
  --mat-fab-small-foreground-color: #fff;
  --mat-fab-small-state-layer-color: #fff;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.egret-navy-dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #e91e63;
  --mat-fab-small-foreground-color: #fff;
  --mat-fab-small-state-layer-color: #fff;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.egret-navy-dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #f44336;
  --mat-fab-small-foreground-color: #fff;
  --mat-fab-small-state-layer-color: #fff;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.egret-navy-dark .mat-accent {
  --mdc-circular-progress-active-indicator-color: #e91e63;
}
.egret-navy-dark .mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}
.egret-navy-dark .mat-badge-accent {
  --mat-badge-background-color: #e91e63;
}
.egret-navy-dark .mat-badge-warn {
  --mat-badge-background-color: #f44336;
}
.egret-navy-dark .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-background-color: #e91e63;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(233, 30, 99, 0.4);
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(233, 30, 99, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(233, 30, 99, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(233, 30, 99, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.egret-navy-dark .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.egret-navy-dark .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #e91e63;
}
.egret-navy-dark .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}
.egret-navy-dark .mat-icon.mat-primary {
  --mat-icon-color: #3f51b5;
}
.egret-navy-dark .mat-icon.mat-accent {
  --mat-icon-color: #e91e63;
}
.egret-navy-dark .mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}
.egret-navy-dark .mat-step-header.mat-accent {
  --mat-stepper-header-selected-state-icon-background-color: #e91e63;
  --mat-stepper-header-done-state-icon-background-color: #e91e63;
  --mat-stepper-header-edit-state-icon-background-color: #e91e63;
}
.egret-navy-dark .mat-step-header.mat-warn {
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
}
.egret-navy-dark .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #3f51b5;
}
.egret-navy-dark .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #e91e63;
}
.egret-navy-dark .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
}
.egret-navy-dark .mat-datetimepicker-calendar-header {
  background-color: #3f51b5;
}
.egret-navy-dark .mat-datetimepicker-calendar-body-cell-content.mat-datetimepicker-calendar-body-selected {
  background-color: #3f51b5;
  color: white;
}
.egret-navy-dark .mat-datetimepicker-calendar-body-cell-content.mat-datetimepicker-calendar-body-disabled > .mat-datetimepicker-calendar-body-selected {
  background-color: rgba(63, 81, 181, 0.4);
}
.egret-navy-dark .mat-datetimepicker-calendar-body-cell-content.mat-datetimepicker-calendar-body-today:not(.mat-datetimepicker-calendar-body-selected) {
  border-color: #3f51b5;
}
.egret-navy-dark .mat-datetimepicker-calendar-body-cell-content.mat-datetimepicker-calendar-body-today.mat-datetimepicker-calendar-body-selected {
  box-shadow: inset 0 0 0 1px;
}
.egret-navy-dark .mat-datetimepicker-clock-center {
  background-color: #3f51b5;
}
.egret-navy-dark .mat-datetimepicker-clock-hand {
  background-color: #3f51b5;
}
.egret-navy-dark .mat-datetimepicker-clock-hand::before {
  background-color: #3f51b5;
}
.egret-navy-dark .mat-datetimepicker-clock-cell.mat-datetimepicker-clock-cell-selected {
  background-color: #4d5ec1;
}
.egret-navy-dark .mat-datetimepicker-calendar-previous-button, .egret-navy-dark .mat-datetimepicker-calendar-next-button {
  color: black;
}
.egret-navy-dark .text-muted,
.egret-navy-dark .mat-heading-label {
  color: rgba(255, 255, 255, 0.7) !important;
}
.egret-navy-dark .mat-bg-chip {
  background-color: #3a487e;
}
.egret-navy-dark .mat-bg-card {
  background-color: #222a49;
}
.egret-navy-dark .mat-bg-default {
  background-color: #1a2038;
}
.egret-navy-dark .branding {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
@media only screen and (max-width: 959px) {
  .egret-navy-dark .mat-bg-default--mobile {
    background-color: #1a2038;
  }
  .egret-navy-dark .mat-bg-card--mobile {
    background-color: #222a49;
  }
}
.egret-navy-dark .ngx-datatable.material {
  background-color: #222a49 !important;
  color: #ffffff !important;
}
.egret-navy-dark .ngx-datatable .datatable-header-cell,
.egret-navy-dark .ngx-datatable .datatable-body-cell,
.egret-navy-dark .page-count,
.egret-navy-dark .ngx-datatable .datatable-footer .datatable-pager a {
  color: #ffffff !important;
}
.egret-navy-dark .ngx-datatable .datatable-footer .datatable-pager .pager li.disabled a {
  color: rgba(255, 255, 255, 0.5) !important;
}
.egret-navy-dark .ngx-datatable.material .datatable-header {
  background: #1a2038;
}
.egret-navy-dark .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.egret-navy-dark .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: rgba(250, 250, 250, 0.04);
}
.egret-navy-dark .cal-month-view {
  background-color: #222a49 !important;
}
.egret-navy-dark .cal-month-view .cal-day-cell:not(:last-child),
.egret-navy-dark .cal-month-view .cal-days .cal-cell-row,
.egret-navy-dark .cal-month-view .cal-days {
  border-color: rgba(255, 255, 255, 0.12) !important;
}
.egret-navy-dark .cal-month-view .cal-cell-row .cal-cell:hover,
.egret-navy-dark .cal-month-view .cal-cell.cal-has-events.cal-open,
.egret-navy-dark .cal-month-view .cal-cell-row:hover,
.egret-navy-dark .cal-month-view .cal-day-cell.cal-today {
  background-color: #1a2038;
}
.egret-navy-dark .cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  color: white;
  opacity: 0.5;
}
.egret-navy-dark .cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
  opacity: 0.1 !important;
}
.egret-navy-dark .cal-month-view .cal-day-cell.cal-today {
  color: #FFAC1C !important;
}
.egret-navy-dark div.hopscotch-bubble {
  background-color: #222a49 !important;
  border-color: rgba(255, 255, 255, 0.12) !important;
  color: #ffffff !important;
}
.egret-navy-dark div.hopscotch-bubble .hopscotch-title {
  color: #ffffff !important;
}
.egret-navy-dark div.hopscotch-bubble .hopscotch-bubble-number {
  background-image: none !important;
}
.egret-navy-dark .hopscotch-next {
  background-color: !important;
}
.egret-navy-dark div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow-border {
  border-bottom: 17px solid rgba(255, 255, 255, 0.12);
}
.egret-navy-dark div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow {
  border-bottom: 17px solid #222a49;
  top: -16px;
}
.egret-navy-dark div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow-border {
  border-left: 17px solid rgba(255, 255, 255, 0.12);
}
.egret-navy-dark div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow {
  border-left: 17px solid #222a49;
  left: -1px;
}
.egret-navy-dark div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow-border {
  border-top: 17px solid rgba(255, 255, 255, 0.12);
}
.egret-navy-dark div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow {
  border-top: 17px solid #222a49;
  top: -18px;
}
.egret-navy-dark div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow-border {
  border-right: 17px solid rgba(255, 255, 255, 0.12);
}
.egret-navy-dark div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow {
  left: 1px;
  border-right: 17px solid #222a49;
}
.egret-navy-dark .header-topnav,
.egret-navy-dark .topnav ul ul {
  background-color: #222a49;
}
.egret-navy-dark .topnav ul li ul li:hover,
.egret-navy-dark .topnav ul li ul li.open {
  background: #1a2038;
}
.egret-navy-dark .header-topnav a {
  color: #ffffff;
}

.red-snackbar {
  background-color: rgb(153, 50, 50) !important;
  color: beige !important;
}

.avatar-xs {
  width: 24px;
  height: 24px;
}

.avatar-sm {
  width: 36px;
  height: 36px;
}

.avatar-md {
  width: 54px;
  height: 54px;
}

.avatar-lg {
  width: 80px;
  height: 80px;
}

.h-0 {
  height: 0px;
}

.w-0 {
  width: 0px;
}

.h-20 {
  height: 20px;
}

.w-20 {
  width: 20px;
}

.h-32 {
  height: 32px;
}

.w-32 {
  width: 32px;
}

.h-40 {
  height: 40px;
}

.w-40 {
  width: 40px;
}

.h-80 {
  height: 80px;
}

.w-80 {
  width: 80px;
}

.h-120 {
  height: 120px;
}

.w-120 {
  width: 120px;
}

.h-160 {
  height: 160px;
}

.w-160 {
  width: 160px;
}

.h-200 {
  height: 200px;
}

.w-200 {
  width: 200px;
}

.h-240 {
  height: 240px;
}

.w-240 {
  width: 240px;
}

.h-260 {
  height: 260px;
}

.w-260 {
  width: 260px;
}

.h-300 {
  height: 300px;
}

.w-300 {
  width: 300px;
}

.w-full {
  width: 100%;
}

.w-full-screen {
  width: 100vh;
}

.h-full {
  height: 100%;
}

.h-full-screen {
  height: 100vh;
  overflow-y: auto;
}

.h-100vh-70px {
  height: calc(100vh - 70px);
}

.h-100vh-280px {
  height: calc(100vh - 280px);
}

.m-033 {
  margin: 0.333333rem !important;
}

.m-333 {
  margin: 0.333333rem !important;
}

.margin-333 {
  margin: 0.333333rem !important;
}

[dir=rtl] .pr-16 {
  padding-left: 16px;
  padding-right: 0 !important;
}
[dir=rtl] .mr-16 {
  margin-right: 0 !important;
  margin-left: 16px;
}

.rounded {
  border-radius: 8px;
}
.rounded-circle {
  border-radius: 50%;
}
.rounded.mat-progress-bar,
.rounded .mat-progress-bar-fill {
  border-radius: 10px;
  overflow: hidden;
}

.overflow-hidden {
  overflow: hidden !important;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-none {
  float: none;
}

.background-size-cover {
  background-size: cover;
}

.background-size-contain {
  background-size: contain;
}

.background-size-100 {
  background-size: 100%;
}

[dir=rtl] .float-left {
  float: right;
}
[dir=rtl] .float-right {
  float: left;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.m-0 {
  margin: 0px !important;
}

.m-4 {
  margin: 4px !important;
}

.m-8 {
  margin: 8px !important;
}

.m-12 {
  margin: 12px !important;
}

.m-16 {
  margin: 16px !important;
}

.m-24 {
  margin: 24px !important;
}

.m-28 {
  margin: 28px !important;
}

.m-32 {
  margin: 32px !important;
}

.m-36 {
  margin: 36px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-44 {
  margin: 44px !important;
}

.m-48 {
  margin: 48px !important;
}

.m-52 {
  margin: 52px !important;
}

.m-56 {
  margin: 56px !important;
}

.m-60 {
  margin: 60px !important;
}

.m-64 {
  margin: 64px !important;
}

.m-128 {
  margin: 128px !important;
}

.m-256 {
  margin: 256px !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.mx-4 {
  margin-right: 4px !important;
  margin-left: 4px !important;
}

.mx-8 {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.mx-12 {
  margin-right: 12px !important;
  margin-left: 12px !important;
}

.mx-16 {
  margin-right: 16px !important;
  margin-left: 16px !important;
}

.mx-24 {
  margin-right: 24px !important;
  margin-left: 24px !important;
}

.mx-28 {
  margin-right: 28px !important;
  margin-left: 28px !important;
}

.mx-32 {
  margin-right: 32px !important;
  margin-left: 32px !important;
}

.mx-36 {
  margin-right: 36px !important;
  margin-left: 36px !important;
}

.mx-40 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}

.mx-44 {
  margin-right: 44px !important;
  margin-left: 44px !important;
}

.mx-48 {
  margin-right: 48px !important;
  margin-left: 48px !important;
}

.mx-52 {
  margin-right: 52px !important;
  margin-left: 52px !important;
}

.mx-56 {
  margin-right: 56px !important;
  margin-left: 56px !important;
}

.mx-60 {
  margin-right: 60px !important;
  margin-left: 60px !important;
}

.mx-64 {
  margin-right: 64px !important;
  margin-left: 64px !important;
}

.mx-128 {
  margin-right: 128px !important;
  margin-left: 128px !important;
}

.mx-256 {
  margin-right: 256px !important;
  margin-left: 256px !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.my-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.my-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.my-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.my-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.my-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.my-28 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.my-32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.my-36 {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}

.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.my-44 {
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}

.my-48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.my-52 {
  margin-top: 52px !important;
  margin-bottom: 52px !important;
}

.my-56 {
  margin-top: 56px !important;
  margin-bottom: 56px !important;
}

.my-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.my-64 {
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}

.my-128 {
  margin-top: 128px !important;
  margin-bottom: 128px !important;
}

.my-256 {
  margin-top: 256px !important;
  margin-bottom: 256px !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-28 {
  margin-top: 28px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-36 {
  margin-top: 36px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-44 {
  margin-top: 44px !important;
}

.mt-48 {
  margin-top: 48px !important;
}

.mt-52 {
  margin-top: 52px !important;
}

.mt-56 {
  margin-top: 56px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-64 {
  margin-top: 64px !important;
}

.mt-128 {
  margin-top: 128px !important;
}

.mt-256 {
  margin-top: 256px !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.mr-28 {
  margin-right: 28px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.mr-36 {
  margin-right: 36px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-44 {
  margin-right: 44px !important;
}

.mr-48 {
  margin-right: 48px !important;
}

.mr-52 {
  margin-right: 52px !important;
}

.mr-56 {
  margin-right: 56px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.mr-64 {
  margin-right: 64px !important;
}

.mr-128 {
  margin-right: 128px !important;
}

.mr-256 {
  margin-right: 256px !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-28 {
  margin-bottom: 28px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-36 {
  margin-bottom: 36px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-44 {
  margin-bottom: 44px !important;
}

.mb-48 {
  margin-bottom: 48px !important;
}

.mb-52 {
  margin-bottom: 52px !important;
}

.mb-56 {
  margin-bottom: 56px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-64 {
  margin-bottom: 64px !important;
}

.mb-128 {
  margin-bottom: 128px !important;
}

.mb-256 {
  margin-bottom: 256px !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.ml-28 {
  margin-left: 28px !important;
}

.ml-32 {
  margin-left: 32px !important;
}

.ml-36 {
  margin-left: 36px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-44 {
  margin-left: 44px !important;
}

.ml-48 {
  margin-left: 48px !important;
}

.ml-52 {
  margin-left: 52px !important;
}

.ml-56 {
  margin-left: 56px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.ml-64 {
  margin-left: 64px !important;
}

.ml-128 {
  margin-left: 128px !important;
}

.ml-256 {
  margin-left: 256px !important;
}

.ml-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0px !important;
}

.p-4 {
  padding: 4px !important;
}

.p-8 {
  padding: 8px !important;
}

.p-12 {
  padding: 12px !important;
}

.p-16 {
  padding: 16px !important;
}

.p-24 {
  padding: 24px !important;
}

.p-28 {
  padding: 28px !important;
}

.p-32 {
  padding: 32px !important;
}

.p-36 {
  padding: 36px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-44 {
  padding: 44px !important;
}

.p-48 {
  padding: 48px !important;
}

.p-52 {
  padding: 52px !important;
}

.p-56 {
  padding: 56px !important;
}

.p-60 {
  padding: 60px !important;
}

.p-64 {
  padding: 64px !important;
}

.p-128 {
  padding: 128px !important;
}

.p-256 {
  padding: 256px !important;
}

.px-0 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.px-4 {
  padding-right: 4px !important;
  padding-left: 4px !important;
}

.px-8 {
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.px-12 {
  padding-right: 12px !important;
  padding-left: 12px !important;
}

.px-16 {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.px-24 {
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.px-28 {
  padding-right: 28px !important;
  padding-left: 28px !important;
}

.px-32 {
  padding-right: 32px !important;
  padding-left: 32px !important;
}

.px-36 {
  padding-right: 36px !important;
  padding-left: 36px !important;
}

.px-40 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.px-44 {
  padding-right: 44px !important;
  padding-left: 44px !important;
}

.px-48 {
  padding-right: 48px !important;
  padding-left: 48px !important;
}

.px-52 {
  padding-right: 52px !important;
  padding-left: 52px !important;
}

.px-56 {
  padding-right: 56px !important;
  padding-left: 56px !important;
}

.px-60 {
  padding-right: 60px !important;
  padding-left: 60px !important;
}

.px-64 {
  padding-right: 64px !important;
  padding-left: 64px !important;
}

.px-128 {
  padding-right: 128px !important;
  padding-left: 128px !important;
}

.px-256 {
  padding-right: 256px !important;
  padding-left: 256px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.py-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.py-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.py-28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.py-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.py-36 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.py-44 {
  padding-top: 44px !important;
  padding-bottom: 44px !important;
}

.py-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.py-52 {
  padding-top: 52px !important;
  padding-bottom: 52px !important;
}

.py-56 {
  padding-top: 56px !important;
  padding-bottom: 56px !important;
}

.py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.py-64 {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}

.py-128 {
  padding-top: 128px !important;
  padding-bottom: 128px !important;
}

.py-256 {
  padding-top: 256px !important;
  padding-bottom: 256px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-4 {
  padding-top: 4px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.pt-28 {
  padding-top: 28px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.pt-36 {
  padding-top: 36px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-44 {
  padding-top: 44px !important;
}

.pt-48 {
  padding-top: 48px !important;
}

.pt-52 {
  padding-top: 52px !important;
}

.pt-56 {
  padding-top: 56px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-64 {
  padding-top: 64px !important;
}

.pt-128 {
  padding-top: 128px !important;
}

.pt-256 {
  padding-top: 256px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-4 {
  padding-right: 4px !important;
}

.pr-8 {
  padding-right: 8px !important;
}

.pr-12 {
  padding-right: 12px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

.pr-28 {
  padding-right: 28px !important;
}

.pr-32 {
  padding-right: 32px !important;
}

.pr-36 {
  padding-right: 36px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-44 {
  padding-right: 44px !important;
}

.pr-48 {
  padding-right: 48px !important;
}

.pr-52 {
  padding-right: 52px !important;
}

.pr-56 {
  padding-right: 56px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.pr-64 {
  padding-right: 64px !important;
}

.pr-128 {
  padding-right: 128px !important;
}

.pr-256 {
  padding-right: 256px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-4 {
  padding-bottom: 4px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.pb-28 {
  padding-bottom: 28px !important;
}

.pb-32 {
  padding-bottom: 32px !important;
}

.pb-36 {
  padding-bottom: 36px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-44 {
  padding-bottom: 44px !important;
}

.pb-48 {
  padding-bottom: 48px !important;
}

.pb-52 {
  padding-bottom: 52px !important;
}

.pb-56 {
  padding-bottom: 56px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-64 {
  padding-bottom: 64px !important;
}

.pb-128 {
  padding-bottom: 128px !important;
}

.pb-256 {
  padding-bottom: 256px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-4 {
  padding-left: 4px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.pl-28 {
  padding-left: 28px !important;
}

.pl-32 {
  padding-left: 32px !important;
}

.pl-36 {
  padding-left: 36px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-44 {
  padding-left: 44px !important;
}

.pl-48 {
  padding-left: 48px !important;
}

.pl-52 {
  padding-left: 52px !important;
}

.pl-56 {
  padding-left: 56px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.pl-64 {
  padding-left: 64px !important;
}

.pl-128 {
  padding-left: 128px !important;
}

.pl-256 {
  padding-left: 256px !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-semi {
  font-weight: 500 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.font-italic {
  font-style: italic !important;
}

.font-normal {
  font-style: normal !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.border-light {
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.border-top-light {
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.border-right-light {
  border-right: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.border-bottom-light {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.border-left-light {
  border-left: 1px solid rgba(255, 255, 255, 0.1) !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.button-loading .mat-button-wrapper {
  display: flex;
  align-items: center;
}

.btn-spinner {
  width: 1em;
  height: 1em;
  background: transparent;
  border-radius: 50%;
  margin: 0 16px 0 0;
  border: 2px solid transparent;
  animation: btn-glow 1s ease infinite;
}

@keyframes btn-glow {
  0% {
    box-shadow: 0 0 0 0.4em #5d5d5d, 0 0 0 0.1em #5d5d5d;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #777676;
  }
  100% {
    box-shadow: 0 0 0 0.4em #5d5d5d, 0 0 0 3.6em transparent;
  }
}
.mat-card {
  margin: 0.75rem;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0 8px 12px rgba(47, 60, 74, 0.04), 0 2px 6px rgba(47, 60, 74, 0.08) !important;
}
.mat-card.rounded-circle {
  border-radius: 200px !important;
}

.mat-card.default {
  padding: 0;
}

.mat-card.default .mat-card-title {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1;
  font-weight: 400;
}

.mat-card.default > :first-child {
  border-radius: 2px 2px 0 0;
}

.mat-card.default .mat-card-subtitle:first-child {
  padding-top: 1rem;
}

.mat-card.default .mat-card-subtitle {
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1;
}

.mat-card.default .mat-card-content {
  padding: 1rem;
  margin-bottom: 0;
  position: relative;
}

.mat-card.default .mat-card-actions,
.mat-card.default .mat-card-actions:last-child {
  padding: 0.5rem;
  margin: 0;
}

.mat-card.default > :last-child {
  border-radius: 0 0 2px 2px;
}

.mat-card .mat-card-title .mat-divider,
.mat-divider.full-width {
  margin-left: -24px;
  margin-right: -24px;
}

.mat-card.p-0 .mat-card-title .card-title-text {
  padding: 1rem 1.5rem;
}

.mat-card.p-0 .mat-card-title .card-title-text .card-control {
  height: 24px;
  width: 24px;
  line-height: 24px;
}

.mat-card.p-0 .mat-card-title .card-title-text .mat-card-subtitle {
  margin: 0;
}

.mat-card.p-0 .mat-card-title .mat-divider {
  margin-left: 0;
  margin-right: 0;
  border-top-color: rgba(0, 0, 0, 0.04);
}

.mat-card.p-0 .mat-card-image {
  margin: 0 0 1rem !important;
}

.mat-card.p-0 .mat-card-content {
  padding: 1.5rem 1.5rem;
}

div.hopscotch-bubble,
div.hopscotch-bubble .hopscotch-title,
div.hopscotch-bubble .hopscotch-content,
div.hopscotch-bubble .hopscotch-nav-button {
  font-family: Archivo, "Helvetica Neue", sans-serif !important;
}

div.hopscotch-bubble {
  border: 1px solid;
  border-radius: 2px;
}

div.hopscotch-bubble .hopscotch-bubble-number {
  border-radius: 50%;
  box-sizing: border-box;
  padding: 0;
  font-weight: 500;
  height: 32px;
  width: 32px;
  line-height: 32px;
}

div.hopscotch-bubble .hopscotch-title {
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.1px;
}

div.hopscotch-bubble .hopscotch-content {
  margin: -5px 0 16px;
}

div.hopscotch-bubble .hopscotch-bubble-close:active {
  outline: 0;
}

div.hopscotch-bubble .hopscotch-bubble-arrow-container.up {
  top: -17px;
}

div.hopscotch-bubble .hopscotch-bubble-arrow-container.right {
  right: -34px;
}

div.hopscotch-bubble .hopscotch-bubble-arrow-container.down {
  bottom: -34px;
}

div.hopscotch-bubble .hopscotch-bubble-arrow-container.left {
  left: -17px;
}

div.hopscotch-bubble .hopscotch-nav-button {
  border: 0;
  border-radius: 2px;
  font-weight: normal;
  text-shadow: none !important;
  padding: 0 18px;
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  background-image: none !important;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transform: translate3d(0, 0, 0);
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

div.hopscotch-bubble .hopscotch-nav-button:hover,
div.hopscotch-bubble .hopscotch-nav-button:active,
div.hopscotch-bubble .hopscotch-nav-button:focus {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  outline: none;
}

div.hopscotch-bubble .hopscotch-nav-button.prev,
div.hopscotch-bubble .hopscotch-nav-button.prev:hover {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
}

egret-sidebar {
  position: absolute;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  width: 280px;
  z-index: 1000;
  float: left;
  left: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  transition-property: transform, width, min-width, max-width, -webkit-transform;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
}
egret-sidebar.sidebar-locked-open {
  position: relative !important;
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
}
egret-sidebar.open {
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
}
egret-sidebar.position-right {
  left: auto !important;
  right: 0 !important;
  float: right;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.egret-sidebar-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
}

.egret-example-viewer-accordion .code-wrap {
  max-height: 400px;
}
.egret-example-viewer-accordion pre {
  margin: 0 !important;
}
.egret-example-viewer-accordion > .mat-expansion-panel {
  box-shadow: none !important;
}
.egret-example-viewer-accordion .example-viewer-tab-wrap {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.egret-example-viewer-accordion .mat-expansion-panel-header {
  margin: 0 !important;
}
.egret-example-viewer-accordion .egret-example-viewer-header.mat-expansion-panel-header > .mat-expansion-indicator {
  display: none !important;
}
.egret-example-viewer-accordion .egret-example-viewer-header.mat-expansion-panel-header .mat-expansion-panel-header-title {
  align-items: center;
}

.red {
  background-color: #f44336 !important;
}
.red .mat-table {
  background: transparent !important;
}
.red .ngx-datatable.material *,
.red .mat-table,
.red .mat-cell,
.red .mat-header-cell {
  color: white !important;
}
.red .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.red .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #f44336, 5;
}

.text-red {
  color: #f44336;
}

.sidebar-red .branding {
  background: #f44336 !important;
  color: white !important;
}
.sidebar-red .navigation-hold {
  background: #f44336 !important;
}
.sidebar-red .navigation-hold a,
.sidebar-red .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-red .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-red .navigation-hold .sidenav li.open > a > span,
.sidebar-red .navigation-hold .icon-menu .mat-raised-button,
.sidebar-red .navigation-hold .app-user .app-user-name,
.sidebar-red .navigation-hold .app-logo-text {
  color: white !important;
}
.sidebar-red .navigation-hold a:hover {
  background: rgba(186, 22, 10, 0.2) !important;
}
.sidebar-red .navigation-hold .app-user .app-user-photo,
.sidebar-red .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #96045f !important;
}
.sidebar-red .navigation-hold .icon-menu .mat-raised-button {
  background: rgba(244, 67, 54, 0.96) !important;
}
.sidebar-red .navigation-hold li.open > a {
  background: rgba(186, 22, 10, 0.2) !important;
}
.sidebar-red .navigation-hold .text-muted {
  color: white !important;
}

.footer-red .main-footer {
  background: #f44336 !important;
  color: white !important;
}

.red-50 {
  background-color: #ffebee !important;
}

.red-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-red-50 {
  color: #ffebee !important;
}

.red-100 {
  background-color: #ffcdd2 !important;
}

.red-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-red-100 {
  color: #ffcdd2 !important;
}

.red-200 {
  background-color: #ef9a9a !important;
}

.red-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-red-200 {
  color: #ef9a9a !important;
}

.red-300 {
  background-color: #e57373 !important;
}

.red-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-red-300 {
  color: #e57373 !important;
}

.red-400 {
  background-color: #ef5350 !important;
}

.red-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-red-400 {
  color: #ef5350 !important;
}

.red-500 {
  background-color: #f44336 !important;
}

.red-500-fg {
  color: white !important;
}

.text-red-500 {
  color: #f44336 !important;
}

.red-600 {
  background-color: #e53935 !important;
}

.red-600-fg {
  color: white !important;
}

.text-red-600 {
  color: #e53935 !important;
}

.red-700 {
  background-color: #d32f2f !important;
}

.red-700-fg {
  color: white !important;
}

.text-red-700 {
  color: #d32f2f !important;
}

.red-800 {
  background-color: #c62828 !important;
}

.red-800-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-red-800 {
  color: #c62828 !important;
}

.red-900 {
  background-color: #b71c1c !important;
}

.red-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-red-900 {
  color: #b71c1c !important;
}

.red-A100 {
  background-color: #ff8a80 !important;
}

.red-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-red-A100 {
  color: #ff8a80 !important;
}

.red-A200 {
  background-color: #ff5252 !important;
}

.red-A200-fg {
  color: white !important;
}

.text-red-A200 {
  color: #ff5252 !important;
}

.red-A400 {
  background-color: #ff1744 !important;
}

.red-A400-fg {
  color: white !important;
}

.text-red-A400 {
  color: #ff1744 !important;
}

.red-A700 {
  background-color: #d50000 !important;
}

.red-A700-fg {
  color: white !important;
}

.text-red-A700 {
  color: #d50000 !important;
}

.pink {
  background-color: #e91e63 !important;
}
.pink .mat-table {
  background: transparent !important;
}
.pink .ngx-datatable.material *,
.pink .mat-table,
.pink .mat-cell,
.pink .mat-header-cell {
  color: white !important;
}
.pink .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.pink .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #e91e63, 5;
}

.text-pink {
  color: #e91e63;
}

.sidebar-pink .branding {
  background: #e91e63 !important;
  color: white !important;
}
.sidebar-pink .navigation-hold {
  background: #e91e63 !important;
}
.sidebar-pink .navigation-hold a,
.sidebar-pink .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-pink .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-pink .navigation-hold .sidenav li.open > a > span,
.sidebar-pink .navigation-hold .icon-menu .mat-raised-button,
.sidebar-pink .navigation-hold .app-user .app-user-name,
.sidebar-pink .navigation-hold .app-logo-text {
  color: white !important;
}
.sidebar-pink .navigation-hold a:hover {
  background: rgba(147, 14, 59, 0.2) !important;
}
.sidebar-pink .navigation-hold .app-user .app-user-photo,
.sidebar-pink .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #96045f !important;
}
.sidebar-pink .navigation-hold .icon-menu .mat-raised-button {
  background: rgba(233, 30, 99, 0.96) !important;
}
.sidebar-pink .navigation-hold li.open > a {
  background: rgba(147, 14, 59, 0.2) !important;
}
.sidebar-pink .navigation-hold .text-muted {
  color: white !important;
}

.footer-pink .main-footer {
  background: #e91e63 !important;
  color: white !important;
}

.pink-50 {
  background-color: #fce4ec !important;
}

.pink-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-pink-50 {
  color: #fce4ec !important;
}

.pink-100 {
  background-color: #f8bbd0 !important;
}

.pink-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-pink-100 {
  color: #f8bbd0 !important;
}

.pink-200 {
  background-color: #f48fb1 !important;
}

.pink-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-pink-200 {
  color: #f48fb1 !important;
}

.pink-300 {
  background-color: #f06292 !important;
}

.pink-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-pink-300 {
  color: #f06292 !important;
}

.pink-400 {
  background-color: #ec407a !important;
}

.pink-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-pink-400 {
  color: #ec407a !important;
}

.pink-500 {
  background-color: #e91e63 !important;
}

.pink-500-fg {
  color: white !important;
}

.text-pink-500 {
  color: #e91e63 !important;
}

.pink-600 {
  background-color: #d81b60 !important;
}

.pink-600-fg {
  color: white !important;
}

.text-pink-600 {
  color: #d81b60 !important;
}

.pink-700 {
  background-color: #c2185b !important;
}

.pink-700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-pink-700 {
  color: #c2185b !important;
}

.pink-800 {
  background-color: #ad1457 !important;
}

.pink-800-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-pink-800 {
  color: #ad1457 !important;
}

.pink-900 {
  background-color: #880e4f !important;
}

.pink-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-pink-900 {
  color: #880e4f !important;
}

.pink-A100 {
  background-color: #ff80ab !important;
}

.pink-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-pink-A100 {
  color: #ff80ab !important;
}

.pink-A200 {
  background-color: #ff4081 !important;
}

.pink-A200-fg {
  color: white !important;
}

.text-pink-A200 {
  color: #ff4081 !important;
}

.pink-A400 {
  background-color: #f50057 !important;
}

.pink-A400-fg {
  color: white !important;
}

.text-pink-A400 {
  color: #f50057 !important;
}

.pink-A700 {
  background-color: #c51162 !important;
}

.pink-A700-fg {
  color: white !important;
}

.text-pink-A700 {
  color: #c51162 !important;
}

.light-purple {
  background-color: #7367f0 !important;
}
.light-purple .mat-table {
  background: transparent !important;
}
.light-purple .ngx-datatable.material *,
.light-purple .mat-table,
.light-purple .mat-cell,
.light-purple .mat-header-cell {
  color: #ffffff !important;
}
.light-purple .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.light-purple .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #7367f0, 5;
}

.text-light-purple {
  color: #7367f0;
}

.sidebar-light-purple .branding {
  background: #7367f0 !important;
  color: #ffffff !important;
}
.sidebar-light-purple .navigation-hold {
  background: #7367f0 !important;
}
.sidebar-light-purple .navigation-hold a,
.sidebar-light-purple .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-light-purple .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-light-purple .navigation-hold .sidenav li.open > a > span,
.sidebar-light-purple .navigation-hold .icon-menu .mat-raised-button,
.sidebar-light-purple .navigation-hold .app-user .app-user-name,
.sidebar-light-purple .navigation-hold .app-logo-text {
  color: #ffffff !important;
}
.sidebar-light-purple .navigation-hold a:hover {
  background: rgba(39, 22, 219, 0.2) !important;
}
.sidebar-light-purple .navigation-hold .app-user .app-user-photo,
.sidebar-light-purple .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #96045f !important;
}
.sidebar-light-purple .navigation-hold .icon-menu .mat-raised-button {
  background: rgba(115, 103, 240, 0.96) !important;
}
.sidebar-light-purple .navigation-hold li.open > a {
  background: rgba(39, 22, 219, 0.2) !important;
}
.sidebar-light-purple .navigation-hold .text-muted {
  color: white !important;
}

.footer-light-purple .main-footer {
  background: #7367f0 !important;
  color: #ffffff !important;
}

.light-purple-50 {
  background-color: #eeedfd !important;
}

.light-purple-50-fg {
  color: #000000 !important;
}

.text-light-purple-50 {
  color: #eeedfd !important;
}

.light-purple-100 {
  background-color: #d5d1fb !important;
}

.light-purple-100-fg {
  color: #000000 !important;
}

.text-light-purple-100 {
  color: #d5d1fb !important;
}

.light-purple-200 {
  background-color: #b9b3f8 !important;
}

.light-purple-200-fg {
  color: #000000 !important;
}

.text-light-purple-200 {
  color: #b9b3f8 !important;
}

.light-purple-300 {
  background-color: #9d95f5 !important;
}

.light-purple-300-fg {
  color: #000000 !important;
}

.text-light-purple-300 {
  color: #9d95f5 !important;
}

.light-purple-400 {
  background-color: #887ef2 !important;
}

.light-purple-400-fg {
  color: #000000 !important;
}

.text-light-purple-400 {
  color: #887ef2 !important;
}

.light-purple-500 {
  background-color: #7367f0 !important;
}

.light-purple-500-fg {
  color: #ffffff !important;
}

.text-light-purple-500 {
  color: #7367f0 !important;
}

.light-purple-600 {
  background-color: #6b5fee !important;
}

.light-purple-600-fg {
  color: #ffffff !important;
}

.text-light-purple-600 {
  color: #6b5fee !important;
}

.light-purple-700 {
  background-color: #6054ec !important;
}

.light-purple-700-fg {
  color: #ffffff !important;
}

.text-light-purple-700 {
  color: #6054ec !important;
}

.light-purple-800 {
  background-color: #564ae9 !important;
}

.light-purple-800-fg {
  color: #ffffff !important;
}

.text-light-purple-800 {
  color: #564ae9 !important;
}

.light-purple-900 {
  background-color: #4339e5 !important;
}

.light-purple-900-fg {
  color: #ffffff !important;
}

.text-light-purple-900 {
  color: #4339e5 !important;
}

.light-purple-A100 {
  background-color: #ffffff !important;
}

.light-purple-A100-fg {
  color: #000000 !important;
}

.text-light-purple-A100 {
  color: #ffffff !important;
}

.light-purple-A200 {
  background-color: #fcfcff !important;
}

.light-purple-A200-fg {
  color: #000000 !important;
}

.text-light-purple-A200 {
  color: #fcfcff !important;
}

.light-purple-A400 {
  background-color: #ccc9ff !important;
}

.light-purple-A400-fg {
  color: #000000 !important;
}

.text-light-purple-A400 {
  color: #ccc9ff !important;
}

.light-purple-A700 {
  background-color: #b3afff !important;
}

.light-purple-A700-fg {
  color: #000000 !important;
}

.text-light-purple-A700 {
  color: #b3afff !important;
}

.purple {
  background-color: #9c27b0 !important;
}
.purple .mat-table {
  background: transparent !important;
}
.purple .ngx-datatable.material *,
.purple .mat-table,
.purple .mat-cell,
.purple .mat-header-cell {
  color: rgba(255, 255, 255, 0.87) !important;
}
.purple .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.purple .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #9c27b0, 5;
}

.text-purple {
  color: #9c27b0;
}

.sidebar-purple .branding {
  background: #9c27b0 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}
.sidebar-purple .navigation-hold {
  background: #9c27b0 !important;
}
.sidebar-purple .navigation-hold a,
.sidebar-purple .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-purple .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-purple .navigation-hold .sidenav li.open > a > span,
.sidebar-purple .navigation-hold .icon-menu .mat-raised-button,
.sidebar-purple .navigation-hold .app-user .app-user-name,
.sidebar-purple .navigation-hold .app-logo-text {
  color: rgba(255, 255, 255, 0.87) !important;
}
.sidebar-purple .navigation-hold a:hover {
  background: rgba(82, 20, 93, 0.2) !important;
}
.sidebar-purple .navigation-hold .app-user .app-user-photo,
.sidebar-purple .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #96045f !important;
}
.sidebar-purple .navigation-hold .icon-menu .mat-raised-button {
  background: rgba(156, 39, 176, 0.96) !important;
}
.sidebar-purple .navigation-hold li.open > a {
  background: rgba(82, 20, 93, 0.2) !important;
}
.sidebar-purple .navigation-hold .text-muted {
  color: rgba(255, 255, 255, 0.87) !important;
}

.footer-purple .main-footer {
  background: #9c27b0 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.purple-50 {
  background-color: #f3e5f5 !important;
}

.purple-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-purple-50 {
  color: #f3e5f5 !important;
}

.purple-100 {
  background-color: #e1bee7 !important;
}

.purple-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-purple-100 {
  color: #e1bee7 !important;
}

.purple-200 {
  background-color: #ce93d8 !important;
}

.purple-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-purple-200 {
  color: #ce93d8 !important;
}

.purple-300 {
  background-color: #ba68c8 !important;
}

.purple-300-fg {
  color: white !important;
}

.text-purple-300 {
  color: #ba68c8 !important;
}

.purple-400 {
  background-color: #ab47bc !important;
}

.purple-400-fg {
  color: white !important;
}

.text-purple-400 {
  color: #ab47bc !important;
}

.purple-500 {
  background-color: #9c27b0 !important;
}

.purple-500-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-purple-500 {
  color: #9c27b0 !important;
}

.purple-600 {
  background-color: #8e24aa !important;
}

.purple-600-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-purple-600 {
  color: #8e24aa !important;
}

.purple-700 {
  background-color: #7b1fa2 !important;
}

.purple-700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-purple-700 {
  color: #7b1fa2 !important;
}

.purple-800 {
  background-color: #6a1b9a !important;
}

.purple-800-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-purple-800 {
  color: #6a1b9a !important;
}

.purple-900 {
  background-color: #4a148c !important;
}

.purple-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-purple-900 {
  color: #4a148c !important;
}

.purple-A100 {
  background-color: #ea80fc !important;
}

.purple-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-purple-A100 {
  color: #ea80fc !important;
}

.purple-A200 {
  background-color: #e040fb !important;
}

.purple-A200-fg {
  color: white !important;
}

.text-purple-A200 {
  color: #e040fb !important;
}

.purple-A400 {
  background-color: #d500f9 !important;
}

.purple-A400-fg {
  color: white !important;
}

.text-purple-A400 {
  color: #d500f9 !important;
}

.purple-A700 {
  background-color: #aa00ff !important;
}

.purple-A700-fg {
  color: white !important;
}

.text-purple-A700 {
  color: #aa00ff !important;
}

.deep-purple {
  background-color: #673ab7 !important;
}
.deep-purple .mat-table {
  background: transparent !important;
}
.deep-purple .ngx-datatable.material *,
.deep-purple .mat-table,
.deep-purple .mat-cell,
.deep-purple .mat-header-cell {
  color: rgba(255, 255, 255, 0.87) !important;
}
.deep-purple .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.deep-purple .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #673ab7, 5;
}

.text-deep-purple {
  color: #673ab7;
}

.sidebar-deep-purple .branding {
  background: #673ab7 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}
.sidebar-deep-purple .navigation-hold {
  background: #673ab7 !important;
}
.sidebar-deep-purple .navigation-hold a,
.sidebar-deep-purple .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-deep-purple .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-deep-purple .navigation-hold .sidenav li.open > a > span,
.sidebar-deep-purple .navigation-hold .icon-menu .mat-raised-button,
.sidebar-deep-purple .navigation-hold .app-user .app-user-name,
.sidebar-deep-purple .navigation-hold .app-logo-text {
  color: rgba(255, 255, 255, 0.87) !important;
}
.sidebar-deep-purple .navigation-hold a:hover {
  background: rgba(59, 33, 106, 0.2) !important;
}
.sidebar-deep-purple .navigation-hold .app-user .app-user-photo,
.sidebar-deep-purple .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #96045f !important;
}
.sidebar-deep-purple .navigation-hold .icon-menu .mat-raised-button {
  background: rgba(103, 58, 183, 0.96) !important;
}
.sidebar-deep-purple .navigation-hold li.open > a {
  background: rgba(59, 33, 106, 0.2) !important;
}
.sidebar-deep-purple .navigation-hold .text-muted {
  color: rgba(255, 255, 255, 0.87) !important;
}

.footer-deep-purple .main-footer {
  background: #673ab7 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.deep-purple-50 {
  background-color: #ede7f6 !important;
}

.deep-purple-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-deep-purple-50 {
  color: #ede7f6 !important;
}

.deep-purple-100 {
  background-color: #d1c4e9 !important;
}

.deep-purple-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-deep-purple-100 {
  color: #d1c4e9 !important;
}

.deep-purple-200 {
  background-color: #b39ddb !important;
}

.deep-purple-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-deep-purple-200 {
  color: #b39ddb !important;
}

.deep-purple-300 {
  background-color: #9575cd !important;
}

.deep-purple-300-fg {
  color: white !important;
}

.text-deep-purple-300 {
  color: #9575cd !important;
}

.deep-purple-400 {
  background-color: #7e57c2 !important;
}

.deep-purple-400-fg {
  color: white !important;
}

.text-deep-purple-400 {
  color: #7e57c2 !important;
}

.deep-purple-500 {
  background-color: #673ab7 !important;
}

.deep-purple-500-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-deep-purple-500 {
  color: #673ab7 !important;
}

.deep-purple-600 {
  background-color: #5e35b1 !important;
}

.deep-purple-600-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-deep-purple-600 {
  color: #5e35b1 !important;
}

.deep-purple-700 {
  background-color: #512da8 !important;
}

.deep-purple-700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-deep-purple-700 {
  color: #512da8 !important;
}

.deep-purple-800 {
  background-color: #4527a0 !important;
}

.deep-purple-800-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-deep-purple-800 {
  color: #4527a0 !important;
}

.deep-purple-900 {
  background-color: #311b92 !important;
}

.deep-purple-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-deep-purple-900 {
  color: #311b92 !important;
}

.deep-purple-A100 {
  background-color: #b388ff !important;
}

.deep-purple-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-deep-purple-A100 {
  color: #b388ff !important;
}

.deep-purple-A200 {
  background-color: #7c4dff !important;
}

.deep-purple-A200-fg {
  color: white !important;
}

.text-deep-purple-A200 {
  color: #7c4dff !important;
}

.deep-purple-A400 {
  background-color: #651fff !important;
}

.deep-purple-A400-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-deep-purple-A400 {
  color: #651fff !important;
}

.deep-purple-A700 {
  background-color: #6200ea !important;
}

.deep-purple-A700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-deep-purple-A700 {
  color: #6200ea !important;
}

.indigo {
  background-color: #3f51b5 !important;
}
.indigo .mat-table {
  background: transparent !important;
}
.indigo .ngx-datatable.material *,
.indigo .mat-table,
.indigo .mat-cell,
.indigo .mat-header-cell {
  color: rgba(255, 255, 255, 0.87) !important;
}
.indigo .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.indigo .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #3f51b5, 5;
}

.text-indigo {
  color: #3f51b5;
}

.sidebar-indigo .branding {
  background: #3f51b5 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}
.sidebar-indigo .navigation-hold {
  background: #3f51b5 !important;
}
.sidebar-indigo .navigation-hold a,
.sidebar-indigo .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-indigo .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-indigo .navigation-hold .sidenav li.open > a > span,
.sidebar-indigo .navigation-hold .icon-menu .mat-raised-button,
.sidebar-indigo .navigation-hold .app-user .app-user-name,
.sidebar-indigo .navigation-hold .app-logo-text {
  color: rgba(255, 255, 255, 0.87) !important;
}
.sidebar-indigo .navigation-hold a:hover {
  background: rgba(37, 47, 105, 0.2) !important;
}
.sidebar-indigo .navigation-hold .app-user .app-user-photo,
.sidebar-indigo .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #96045f !important;
}
.sidebar-indigo .navigation-hold .icon-menu .mat-raised-button {
  background: rgba(63, 81, 181, 0.96) !important;
}
.sidebar-indigo .navigation-hold li.open > a {
  background: rgba(37, 47, 105, 0.2) !important;
}
.sidebar-indigo .navigation-hold .text-muted {
  color: rgba(255, 255, 255, 0.87) !important;
}

.footer-indigo .main-footer {
  background: #3f51b5 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.indigo-50 {
  background-color: #e8eaf6 !important;
}

.indigo-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-indigo-50 {
  color: #e8eaf6 !important;
}

.indigo-100 {
  background-color: #c5cae9 !important;
}

.indigo-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-indigo-100 {
  color: #c5cae9 !important;
}

.indigo-200 {
  background-color: #9fa8da !important;
}

.indigo-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-indigo-200 {
  color: #9fa8da !important;
}

.indigo-300 {
  background-color: #7986cb !important;
}

.indigo-300-fg {
  color: white !important;
}

.text-indigo-300 {
  color: #7986cb !important;
}

.indigo-400 {
  background-color: #5c6bc0 !important;
}

.indigo-400-fg {
  color: white !important;
}

.text-indigo-400 {
  color: #5c6bc0 !important;
}

.indigo-500 {
  background-color: #3f51b5 !important;
}

.indigo-500-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-indigo-500 {
  color: #3f51b5 !important;
}

.indigo-600 {
  background-color: #3949ab !important;
}

.indigo-600-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-indigo-600 {
  color: #3949ab !important;
}

.indigo-700 {
  background-color: #303f9f !important;
}

.indigo-700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-indigo-700 {
  color: #303f9f !important;
}

.indigo-800 {
  background-color: #283593 !important;
}

.indigo-800-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-indigo-800 {
  color: #283593 !important;
}

.indigo-900 {
  background-color: #1a237e !important;
}

.indigo-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-indigo-900 {
  color: #1a237e !important;
}

.indigo-A100 {
  background-color: #8c9eff !important;
}

.indigo-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-indigo-A100 {
  color: #8c9eff !important;
}

.indigo-A200 {
  background-color: #536dfe !important;
}

.indigo-A200-fg {
  color: white !important;
}

.text-indigo-A200 {
  color: #536dfe !important;
}

.indigo-A400 {
  background-color: #3d5afe !important;
}

.indigo-A400-fg {
  color: white !important;
}

.text-indigo-A400 {
  color: #3d5afe !important;
}

.indigo-A700 {
  background-color: #304ffe !important;
}

.indigo-A700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-indigo-A700 {
  color: #304ffe !important;
}

.blue {
  background-color: #2196f3 !important;
}
.blue .mat-table {
  background: transparent !important;
}
.blue .ngx-datatable.material *,
.blue .mat-table,
.blue .mat-cell,
.blue .mat-header-cell {
  color: white !important;
}
.blue .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.blue .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #2196f3, 5;
}

.text-blue {
  color: #2196f3;
}

.sidebar-blue .branding {
  background: #2196f3 !important;
  color: white !important;
}
.sidebar-blue .navigation-hold {
  background: #2196f3 !important;
}
.sidebar-blue .navigation-hold a,
.sidebar-blue .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-blue .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-blue .navigation-hold .sidenav li.open > a > span,
.sidebar-blue .navigation-hold .icon-menu .mat-raised-button,
.sidebar-blue .navigation-hold .app-user .app-user-name,
.sidebar-blue .navigation-hold .app-logo-text {
  color: white !important;
}
.sidebar-blue .navigation-hold a:hover {
  background: rgba(9, 96, 165, 0.2) !important;
}
.sidebar-blue .navigation-hold .app-user .app-user-photo,
.sidebar-blue .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #96045f !important;
}
.sidebar-blue .navigation-hold .icon-menu .mat-raised-button {
  background: rgba(33, 150, 243, 0.96) !important;
}
.sidebar-blue .navigation-hold li.open > a {
  background: rgba(9, 96, 165, 0.2) !important;
}
.sidebar-blue .navigation-hold .text-muted {
  color: white !important;
}

.footer-blue .main-footer {
  background: #2196f3 !important;
  color: white !important;
}

.blue-50 {
  background-color: #e3f2fd !important;
}

.blue-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-blue-50 {
  color: #e3f2fd !important;
}

.blue-100 {
  background-color: #bbdefb !important;
}

.blue-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-blue-100 {
  color: #bbdefb !important;
}

.blue-200 {
  background-color: #90caf9 !important;
}

.blue-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-blue-200 {
  color: #90caf9 !important;
}

.blue-300 {
  background-color: #64b5f6 !important;
}

.blue-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-blue-300 {
  color: #64b5f6 !important;
}

.blue-400 {
  background-color: #42a5f5 !important;
}

.blue-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-blue-400 {
  color: #42a5f5 !important;
}

.blue-500 {
  background-color: #2196f3 !important;
}

.blue-500-fg {
  color: white !important;
}

.text-blue-500 {
  color: #2196f3 !important;
}

.blue-600 {
  background-color: #1e88e5 !important;
}

.blue-600-fg {
  color: white !important;
}

.text-blue-600 {
  color: #1e88e5 !important;
}

.blue-700 {
  background-color: #1976d2 !important;
}

.blue-700-fg {
  color: white !important;
}

.text-blue-700 {
  color: #1976d2 !important;
}

.blue-800 {
  background-color: #1565c0 !important;
}

.blue-800-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-blue-800 {
  color: #1565c0 !important;
}

.blue-900 {
  background-color: #0d47a1 !important;
}

.blue-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-blue-900 {
  color: #0d47a1 !important;
}

.blue-A100 {
  background-color: #82b1ff !important;
}

.blue-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-blue-A100 {
  color: #82b1ff !important;
}

.blue-A200 {
  background-color: #448aff !important;
}

.blue-A200-fg {
  color: white !important;
}

.text-blue-A200 {
  color: #448aff !important;
}

.blue-A400 {
  background-color: #2979ff !important;
}

.blue-A400-fg {
  color: white !important;
}

.text-blue-A400 {
  color: #2979ff !important;
}

.blue-A700 {
  background-color: #2962ff !important;
}

.blue-A700-fg {
  color: white !important;
}

.text-blue-A700 {
  color: #2962ff !important;
}

.light-blue {
  background-color: #03a9f4 !important;
}
.light-blue .mat-table {
  background: transparent !important;
}
.light-blue .ngx-datatable.material *,
.light-blue .mat-table,
.light-blue .mat-cell,
.light-blue .mat-header-cell {
  color: white !important;
}
.light-blue .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.light-blue .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #03a9f4, 5;
}

.text-light-blue {
  color: #03a9f4;
}

.sidebar-light-blue .branding {
  background: #03a9f4 !important;
  color: white !important;
}
.sidebar-light-blue .navigation-hold {
  background: #03a9f4 !important;
}
.sidebar-light-blue .navigation-hold a,
.sidebar-light-blue .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-light-blue .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-light-blue .navigation-hold .sidenav li.open > a > span,
.sidebar-light-blue .navigation-hold .icon-menu .mat-raised-button,
.sidebar-light-blue .navigation-hold .app-user .app-user-name,
.sidebar-light-blue .navigation-hold .app-logo-text {
  color: white !important;
}
.sidebar-light-blue .navigation-hold a:hover {
  background: rgba(2, 99, 143, 0.2) !important;
}
.sidebar-light-blue .navigation-hold .app-user .app-user-photo,
.sidebar-light-blue .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #96045f !important;
}
.sidebar-light-blue .navigation-hold .icon-menu .mat-raised-button {
  background: rgba(3, 169, 244, 0.96) !important;
}
.sidebar-light-blue .navigation-hold li.open > a {
  background: rgba(2, 99, 143, 0.2) !important;
}
.sidebar-light-blue .navigation-hold .text-muted {
  color: white !important;
}

.footer-light-blue .main-footer {
  background: #03a9f4 !important;
  color: white !important;
}

.light-blue-50 {
  background-color: #e1f5fe !important;
}

.light-blue-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-blue-50 {
  color: #e1f5fe !important;
}

.light-blue-100 {
  background-color: #b3e5fc !important;
}

.light-blue-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-blue-100 {
  color: #b3e5fc !important;
}

.light-blue-200 {
  background-color: #81d4fa !important;
}

.light-blue-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-blue-200 {
  color: #81d4fa !important;
}

.light-blue-300 {
  background-color: #4fc3f7 !important;
}

.light-blue-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-blue-300 {
  color: #4fc3f7 !important;
}

.light-blue-400 {
  background-color: #29b6f6 !important;
}

.light-blue-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-blue-400 {
  color: #29b6f6 !important;
}

.light-blue-500 {
  background-color: #03a9f4 !important;
}

.light-blue-500-fg {
  color: white !important;
}

.text-light-blue-500 {
  color: #03a9f4 !important;
}

.light-blue-600 {
  background-color: #039be5 !important;
}

.light-blue-600-fg {
  color: white !important;
}

.text-light-blue-600 {
  color: #039be5 !important;
}

.light-blue-700 {
  background-color: #0288d1 !important;
}

.light-blue-700-fg {
  color: white !important;
}

.text-light-blue-700 {
  color: #0288d1 !important;
}

.light-blue-800 {
  background-color: #0277bd !important;
}

.light-blue-800-fg {
  color: white !important;
}

.text-light-blue-800 {
  color: #0277bd !important;
}

.light-blue-900 {
  background-color: #01579b !important;
}

.light-blue-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-light-blue-900 {
  color: #01579b !important;
}

.light-blue-A100 {
  background-color: #80d8ff !important;
}

.light-blue-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-blue-A100 {
  color: #80d8ff !important;
}

.light-blue-A200 {
  background-color: #40c4ff !important;
}

.light-blue-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-blue-A200 {
  color: #40c4ff !important;
}

.light-blue-A400 {
  background-color: #00b0ff !important;
}

.light-blue-A400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-blue-A400 {
  color: #00b0ff !important;
}

.light-blue-A700 {
  background-color: #0091ea !important;
}

.light-blue-A700-fg {
  color: white !important;
}

.text-light-blue-A700 {
  color: #0091ea !important;
}

.dark-blue {
  background-color: #10174c !important;
}
.dark-blue .mat-table {
  background: transparent !important;
}
.dark-blue .ngx-datatable.material *,
.dark-blue .mat-table,
.dark-blue .mat-cell,
.dark-blue .mat-header-cell {
  color: rgba(255, 255, 255, 0.87) !important;
}
.dark-blue .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.dark-blue .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #10174c, 5;
}

.text-dark-blue {
  color: #10174c;
}

.sidebar-dark-blue .branding {
  background: #10174c !important;
  color: rgba(255, 255, 255, 0.87) !important;
}
.sidebar-dark-blue .navigation-hold {
  background: #10174c !important;
}
.sidebar-dark-blue .navigation-hold a,
.sidebar-dark-blue .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-dark-blue .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-dark-blue .navigation-hold .sidenav li.open > a > span,
.sidebar-dark-blue .navigation-hold .icon-menu .mat-raised-button,
.sidebar-dark-blue .navigation-hold .app-user .app-user-name,
.sidebar-dark-blue .navigation-hold .app-logo-text {
  color: rgba(255, 255, 255, 0.87) !important;
}
.sidebar-dark-blue .navigation-hold a:hover {
  background: rgba(0, 0, 0, 0.2) !important;
}
.sidebar-dark-blue .navigation-hold .app-user .app-user-photo,
.sidebar-dark-blue .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #96045f !important;
}
.sidebar-dark-blue .navigation-hold .icon-menu .mat-raised-button {
  background: rgba(16, 23, 76, 0.96) !important;
}
.sidebar-dark-blue .navigation-hold li.open > a {
  background: rgba(0, 0, 0, 0.2) !important;
}
.sidebar-dark-blue .navigation-hold .text-muted {
  color: rgba(255, 255, 255, 0.87) !important;
}

.footer-dark-blue .main-footer {
  background: #10174c !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.dark-blue-50 {
  background-color: #e2e3ea !important;
}

.dark-blue-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-dark-blue-50 {
  color: #e2e3ea !important;
}

.dark-blue-100 {
  background-color: #b7b9c9 !important;
}

.dark-blue-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-dark-blue-100 {
  color: #b7b9c9 !important;
}

.dark-blue-200 {
  background-color: #888ba6 !important;
}

.dark-blue-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-dark-blue-200 {
  color: #888ba6 !important;
}

.dark-blue-300 {
  background-color: #585d82 !important;
}

.dark-blue-300-fg {
  color: white !important;
}

.text-dark-blue-300 {
  color: #585d82 !important;
}

.dark-blue-400 {
  background-color: #343a67 !important;
}

.dark-blue-400-fg {
  color: white !important;
}

.text-dark-blue-400 {
  color: #343a67 !important;
}

.dark-blue-500 {
  background-color: #10174c !important;
}

.dark-blue-500-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-dark-blue-500 {
  color: #10174c !important;
}

.dark-blue-600 {
  background-color: #0e1445 !important;
}

.dark-blue-600-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-dark-blue-600 {
  color: #0e1445 !important;
}

.dark-blue-700 {
  background-color: #0c113c !important;
}

.dark-blue-700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-dark-blue-700 {
  color: #0c113c !important;
}

.dark-blue-800 {
  background-color: #090d33 !important;
}

.dark-blue-800-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-dark-blue-800 {
  color: #090d33 !important;
}

.dark-blue-900 {
  background-color: #050724 !important;
}

.dark-blue-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-dark-blue-900 {
  color: #050724 !important;
}

.dark-blue-A100 {
  background-color: #6068ff !important;
}

.dark-blue-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-dark-blue-A100 {
  color: #6068ff !important;
}

.dark-blue-A200 {
  background-color: #2d38ff !important;
}

.dark-blue-A200-fg {
  color: white !important;
}

.text-dark-blue-A200 {
  color: #2d38ff !important;
}

.dark-blue-A400 {
  background-color: #000df9 !important;
}

.dark-blue-A400-fg {
  color: white !important;
}

.text-dark-blue-A400 {
  color: #000df9 !important;
}

.dark-blue-A700 {
  background-color: #000be0 !important;
}

.dark-blue-A700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-dark-blue-A700 {
  color: #000be0 !important;
}

.cyan {
  background-color: #00bcd4 !important;
}
.cyan .mat-table {
  background: transparent !important;
}
.cyan .ngx-datatable.material *,
.cyan .mat-table,
.cyan .mat-cell,
.cyan .mat-header-cell {
  color: white !important;
}
.cyan .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.cyan .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #00bcd4, 5;
}

.text-cyan {
  color: #00bcd4;
}

.sidebar-cyan .branding {
  background: #00bcd4 !important;
  color: white !important;
}
.sidebar-cyan .navigation-hold {
  background: #00bcd4 !important;
}
.sidebar-cyan .navigation-hold a,
.sidebar-cyan .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-cyan .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-cyan .navigation-hold .sidenav li.open > a > span,
.sidebar-cyan .navigation-hold .icon-menu .mat-raised-button,
.sidebar-cyan .navigation-hold .app-user .app-user-name,
.sidebar-cyan .navigation-hold .app-logo-text {
  color: white !important;
}
.sidebar-cyan .navigation-hold a:hover {
  background: rgba(0, 98, 110, 0.2) !important;
}
.sidebar-cyan .navigation-hold .app-user .app-user-photo,
.sidebar-cyan .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #96045f !important;
}
.sidebar-cyan .navigation-hold .icon-menu .mat-raised-button {
  background: rgba(0, 188, 212, 0.96) !important;
}
.sidebar-cyan .navigation-hold li.open > a {
  background: rgba(0, 98, 110, 0.2) !important;
}
.sidebar-cyan .navigation-hold .text-muted {
  color: white !important;
}

.footer-cyan .main-footer {
  background: #00bcd4 !important;
  color: white !important;
}

.cyan-50 {
  background-color: #e0f7fa !important;
}

.cyan-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-cyan-50 {
  color: #e0f7fa !important;
}

.cyan-100 {
  background-color: #b2ebf2 !important;
}

.cyan-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-cyan-100 {
  color: #b2ebf2 !important;
}

.cyan-200 {
  background-color: #80deea !important;
}

.cyan-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-cyan-200 {
  color: #80deea !important;
}

.cyan-300 {
  background-color: #4dd0e1 !important;
}

.cyan-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-cyan-300 {
  color: #4dd0e1 !important;
}

.cyan-400 {
  background-color: #26c6da !important;
}

.cyan-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-cyan-400 {
  color: #26c6da !important;
}

.cyan-500 {
  background-color: #00bcd4 !important;
}

.cyan-500-fg {
  color: white !important;
}

.text-cyan-500 {
  color: #00bcd4 !important;
}

.cyan-600 {
  background-color: #00acc1 !important;
}

.cyan-600-fg {
  color: white !important;
}

.text-cyan-600 {
  color: #00acc1 !important;
}

.cyan-700 {
  background-color: #0097a7 !important;
}

.cyan-700-fg {
  color: white !important;
}

.text-cyan-700 {
  color: #0097a7 !important;
}

.cyan-800 {
  background-color: #00838f !important;
}

.cyan-800-fg {
  color: white !important;
}

.text-cyan-800 {
  color: #00838f !important;
}

.cyan-900 {
  background-color: #006064 !important;
}

.cyan-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-cyan-900 {
  color: #006064 !important;
}

.cyan-A100 {
  background-color: #84ffff !important;
}

.cyan-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-cyan-A100 {
  color: #84ffff !important;
}

.cyan-A200 {
  background-color: #18ffff !important;
}

.cyan-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-cyan-A200 {
  color: #18ffff !important;
}

.cyan-A400 {
  background-color: #00e5ff !important;
}

.cyan-A400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-cyan-A400 {
  color: #00e5ff !important;
}

.cyan-A700 {
  background-color: #00b8d4 !important;
}

.cyan-A700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-cyan-A700 {
  color: #00b8d4 !important;
}

.teal {
  background-color: #009688 !important;
}
.teal .mat-table {
  background: transparent !important;
}
.teal .ngx-datatable.material *,
.teal .mat-table,
.teal .mat-cell,
.teal .mat-header-cell {
  color: white !important;
}
.teal .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.teal .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #009688, 5;
}

.text-teal {
  color: #009688;
}

.sidebar-teal .branding {
  background: #009688 !important;
  color: white !important;
}
.sidebar-teal .navigation-hold {
  background: #009688 !important;
}
.sidebar-teal .navigation-hold a,
.sidebar-teal .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-teal .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-teal .navigation-hold .sidenav li.open > a > span,
.sidebar-teal .navigation-hold .icon-menu .mat-raised-button,
.sidebar-teal .navigation-hold .app-user .app-user-name,
.sidebar-teal .navigation-hold .app-logo-text {
  color: white !important;
}
.sidebar-teal .navigation-hold a:hover {
  background: rgba(0, 48, 44, 0.2) !important;
}
.sidebar-teal .navigation-hold .app-user .app-user-photo,
.sidebar-teal .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #96045f !important;
}
.sidebar-teal .navigation-hold .icon-menu .mat-raised-button {
  background: rgba(0, 150, 136, 0.96) !important;
}
.sidebar-teal .navigation-hold li.open > a {
  background: rgba(0, 48, 44, 0.2) !important;
}
.sidebar-teal .navigation-hold .text-muted {
  color: white !important;
}

.footer-teal .main-footer {
  background: #009688 !important;
  color: white !important;
}

.teal-50 {
  background-color: #e0f2f1 !important;
}

.teal-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-teal-50 {
  color: #e0f2f1 !important;
}

.teal-100 {
  background-color: #b2dfdb !important;
}

.teal-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-teal-100 {
  color: #b2dfdb !important;
}

.teal-200 {
  background-color: #80cbc4 !important;
}

.teal-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-teal-200 {
  color: #80cbc4 !important;
}

.teal-300 {
  background-color: #4db6ac !important;
}

.teal-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-teal-300 {
  color: #4db6ac !important;
}

.teal-400 {
  background-color: #26a69a !important;
}

.teal-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-teal-400 {
  color: #26a69a !important;
}

.teal-500 {
  background-color: #009688 !important;
}

.teal-500-fg {
  color: white !important;
}

.text-teal-500 {
  color: #009688 !important;
}

.teal-600 {
  background-color: #00897b !important;
}

.teal-600-fg {
  color: white !important;
}

.text-teal-600 {
  color: #00897b !important;
}

.teal-700 {
  background-color: #00796b !important;
}

.teal-700-fg {
  color: white !important;
}

.text-teal-700 {
  color: #00796b !important;
}

.teal-800 {
  background-color: #00695c !important;
}

.teal-800-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-teal-800 {
  color: #00695c !important;
}

.teal-900 {
  background-color: #004d40 !important;
}

.teal-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-teal-900 {
  color: #004d40 !important;
}

.teal-A100 {
  background-color: #a7ffeb !important;
}

.teal-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-teal-A100 {
  color: #a7ffeb !important;
}

.teal-A200 {
  background-color: #64ffda !important;
}

.teal-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-teal-A200 {
  color: #64ffda !important;
}

.teal-A400 {
  background-color: #1de9b6 !important;
}

.teal-A400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-teal-A400 {
  color: #1de9b6 !important;
}

.teal-A700 {
  background-color: #00bfa5 !important;
}

.teal-A700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-teal-A700 {
  color: #00bfa5 !important;
}

.green {
  background-color: #4caf50 !important;
}
.green .mat-table {
  background: transparent !important;
}
.green .ngx-datatable.material *,
.green .mat-table,
.green .mat-cell,
.green .mat-header-cell {
  color: white !important;
}
.green .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.green .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #4caf50, 5;
}

.text-green {
  color: #4caf50;
}

.sidebar-green .branding {
  background: #4caf50 !important;
  color: white !important;
}
.sidebar-green .navigation-hold {
  background: #4caf50 !important;
}
.sidebar-green .navigation-hold a,
.sidebar-green .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-green .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-green .navigation-hold .sidenav li.open > a > span,
.sidebar-green .navigation-hold .icon-menu .mat-raised-button,
.sidebar-green .navigation-hold .app-user .app-user-name,
.sidebar-green .navigation-hold .app-logo-text {
  color: white !important;
}
.sidebar-green .navigation-hold a:hover {
  background: rgba(45, 104, 47, 0.2) !important;
}
.sidebar-green .navigation-hold .app-user .app-user-photo,
.sidebar-green .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #96045f !important;
}
.sidebar-green .navigation-hold .icon-menu .mat-raised-button {
  background: rgba(76, 175, 80, 0.96) !important;
}
.sidebar-green .navigation-hold li.open > a {
  background: rgba(45, 104, 47, 0.2) !important;
}
.sidebar-green .navigation-hold .text-muted {
  color: white !important;
}

.footer-green .main-footer {
  background: #4caf50 !important;
  color: white !important;
}

.green-50 {
  background-color: #e8f5e9 !important;
}

.green-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-green-50 {
  color: #e8f5e9 !important;
}

.green-100 {
  background-color: #c8e6c9 !important;
}

.green-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-green-100 {
  color: #c8e6c9 !important;
}

.green-200 {
  background-color: #a5d6a7 !important;
}

.green-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-green-200 {
  color: #a5d6a7 !important;
}

.green-300 {
  background-color: #81c784 !important;
}

.green-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-green-300 {
  color: #81c784 !important;
}

.green-400 {
  background-color: #66bb6a !important;
}

.green-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-green-400 {
  color: #66bb6a !important;
}

.green-500 {
  background-color: #4caf50 !important;
}

.green-500-fg {
  color: white !important;
}

.text-green-500 {
  color: #4caf50 !important;
}

.green-600 {
  background-color: #43a047 !important;
}

.green-600-fg {
  color: white !important;
}

.text-green-600 {
  color: #43a047 !important;
}

.green-700 {
  background-color: #388e3c !important;
}

.green-700-fg {
  color: white !important;
}

.text-green-700 {
  color: #388e3c !important;
}

.green-800 {
  background-color: #2e7d32 !important;
}

.green-800-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-green-800 {
  color: #2e7d32 !important;
}

.green-900 {
  background-color: #1b5e20 !important;
}

.green-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-green-900 {
  color: #1b5e20 !important;
}

.green-A100 {
  background-color: #b9f6ca !important;
}

.green-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-green-A100 {
  color: #b9f6ca !important;
}

.green-A200 {
  background-color: #69f0ae !important;
}

.green-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-green-A200 {
  color: #69f0ae !important;
}

.green-A400 {
  background-color: #00e676 !important;
}

.green-A400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-green-A400 {
  color: #00e676 !important;
}

.green-A700 {
  background-color: #00c853 !important;
}

.green-A700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-green-A700 {
  color: #00c853 !important;
}

.light-green {
  background-color: #8bc34a !important;
}
.light-green .mat-table {
  background: transparent !important;
}
.light-green .ngx-datatable.material *,
.light-green .mat-table,
.light-green .mat-cell,
.light-green .mat-header-cell {
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-green .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.light-green .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #8bc34a, 5;
}

.text-light-green {
  color: #8bc34a;
}

.sidebar-light-green .branding {
  background: #8bc34a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-light-green .navigation-hold {
  background: #8bc34a !important;
}
.sidebar-light-green .navigation-hold a,
.sidebar-light-green .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-light-green .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-light-green .navigation-hold .sidenav li.open > a > span,
.sidebar-light-green .navigation-hold .icon-menu .mat-raised-button,
.sidebar-light-green .navigation-hold .app-user .app-user-name,
.sidebar-light-green .navigation-hold .app-logo-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-light-green .navigation-hold a:hover {
  background: rgba(87, 125, 42, 0.2) !important;
}
.sidebar-light-green .navigation-hold .app-user .app-user-photo,
.sidebar-light-green .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #96045f !important;
}
.sidebar-light-green .navigation-hold .icon-menu .mat-raised-button {
  background: rgba(139, 195, 74, 0.96) !important;
}
.sidebar-light-green .navigation-hold li.open > a {
  background: rgba(87, 125, 42, 0.2) !important;
}
.sidebar-light-green .navigation-hold .text-muted {
  color: rgba(0, 0, 0, 0.87) !important;
}

.footer-light-green .main-footer {
  background: #8bc34a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.light-green-50 {
  background-color: #f1f8e9 !important;
}

.light-green-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-green-50 {
  color: #f1f8e9 !important;
}

.light-green-100 {
  background-color: #dcedc8 !important;
}

.light-green-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-green-100 {
  color: #dcedc8 !important;
}

.light-green-200 {
  background-color: #c5e1a5 !important;
}

.light-green-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-green-200 {
  color: #c5e1a5 !important;
}

.light-green-300 {
  background-color: #aed581 !important;
}

.light-green-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-green-300 {
  color: #aed581 !important;
}

.light-green-400 {
  background-color: #9ccc65 !important;
}

.light-green-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-green-400 {
  color: #9ccc65 !important;
}

.light-green-500 {
  background-color: #8bc34a !important;
}

.light-green-500-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-green-500 {
  color: #8bc34a !important;
}

.light-green-600 {
  background-color: #7cb342 !important;
}

.light-green-600-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-green-600 {
  color: #7cb342 !important;
}

.light-green-700 {
  background-color: #689f38 !important;
}

.light-green-700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-green-700 {
  color: #689f38 !important;
}

.light-green-800 {
  background-color: #558b2f !important;
}

.light-green-800-fg {
  color: white !important;
}

.text-light-green-800 {
  color: #558b2f !important;
}

.light-green-900 {
  background-color: #33691e !important;
}

.light-green-900-fg {
  color: white !important;
}

.text-light-green-900 {
  color: #33691e !important;
}

.light-green-A100 {
  background-color: #ccff90 !important;
}

.light-green-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-green-A100 {
  color: #ccff90 !important;
}

.light-green-A200 {
  background-color: #b2ff59 !important;
}

.light-green-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-green-A200 {
  color: #b2ff59 !important;
}

.light-green-A400 {
  background-color: #76ff03 !important;
}

.light-green-A400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-green-A400 {
  color: #76ff03 !important;
}

.light-green-A700 {
  background-color: #64dd17 !important;
}

.light-green-A700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-green-A700 {
  color: #64dd17 !important;
}

.lime {
  background-color: #cddc39 !important;
}
.lime .mat-table {
  background: transparent !important;
}
.lime .ngx-datatable.material *,
.lime .mat-table,
.lime .mat-cell,
.lime .mat-header-cell {
  color: rgba(0, 0, 0, 0.87) !important;
}
.lime .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.lime .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #cddc39, 5;
}

.text-lime {
  color: #cddc39;
}

.sidebar-lime .branding {
  background: #cddc39 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-lime .navigation-hold {
  background: #cddc39 !important;
}
.sidebar-lime .navigation-hold a,
.sidebar-lime .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-lime .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-lime .navigation-hold .sidenav li.open > a > span,
.sidebar-lime .navigation-hold .icon-menu .mat-raised-button,
.sidebar-lime .navigation-hold .app-user .app-user-name,
.sidebar-lime .navigation-hold .app-logo-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-lime .navigation-hold a:hover {
  background: rgba(137, 149, 26, 0.2) !important;
}
.sidebar-lime .navigation-hold .app-user .app-user-photo,
.sidebar-lime .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #96045f !important;
}
.sidebar-lime .navigation-hold .icon-menu .mat-raised-button {
  background: rgba(205, 220, 57, 0.96) !important;
}
.sidebar-lime .navigation-hold li.open > a {
  background: rgba(137, 149, 26, 0.2) !important;
}
.sidebar-lime .navigation-hold .text-muted {
  color: rgba(0, 0, 0, 0.87) !important;
}

.footer-lime .main-footer {
  background: #cddc39 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.lime-50 {
  background-color: #f9fbe7 !important;
}

.lime-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-50 {
  color: #f9fbe7 !important;
}

.lime-100 {
  background-color: #f0f4c3 !important;
}

.lime-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-100 {
  color: #f0f4c3 !important;
}

.lime-200 {
  background-color: #e6ee9c !important;
}

.lime-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-200 {
  color: #e6ee9c !important;
}

.lime-300 {
  background-color: #dce775 !important;
}

.lime-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-300 {
  color: #dce775 !important;
}

.lime-400 {
  background-color: #d4e157 !important;
}

.lime-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-400 {
  color: #d4e157 !important;
}

.lime-500 {
  background-color: #cddc39 !important;
}

.lime-500-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-500 {
  color: #cddc39 !important;
}

.lime-600 {
  background-color: #c0ca33 !important;
}

.lime-600-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-600 {
  color: #c0ca33 !important;
}

.lime-700 {
  background-color: #afb42b !important;
}

.lime-700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-700 {
  color: #afb42b !important;
}

.lime-800 {
  background-color: #9e9d24 !important;
}

.lime-800-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-800 {
  color: #9e9d24 !important;
}

.lime-900 {
  background-color: #827717 !important;
}

.lime-900-fg {
  color: white !important;
}

.text-lime-900 {
  color: #827717 !important;
}

.lime-A100 {
  background-color: #f4ff81 !important;
}

.lime-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-A100 {
  color: #f4ff81 !important;
}

.lime-A200 {
  background-color: #eeff41 !important;
}

.lime-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-A200 {
  color: #eeff41 !important;
}

.lime-A400 {
  background-color: #c6ff00 !important;
}

.lime-A400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-A400 {
  color: #c6ff00 !important;
}

.lime-A700 {
  background-color: #aeea00 !important;
}

.lime-A700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-A700 {
  color: #aeea00 !important;
}

.yellow {
  background-color: #ffeb3b !important;
}
.yellow .mat-table {
  background: transparent !important;
}
.yellow .ngx-datatable.material *,
.yellow .mat-table,
.yellow .mat-cell,
.yellow .mat-header-cell {
  color: rgba(0, 0, 0, 0.87) !important;
}
.yellow .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.yellow .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #ffeb3b, 5;
}

.text-yellow {
  color: #ffeb3b;
}

.sidebar-yellow .branding {
  background: #ffeb3b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-yellow .navigation-hold {
  background: #ffeb3b !important;
}
.sidebar-yellow .navigation-hold a,
.sidebar-yellow .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-yellow .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-yellow .navigation-hold .sidenav li.open > a > span,
.sidebar-yellow .navigation-hold .icon-menu .mat-raised-button,
.sidebar-yellow .navigation-hold .app-user .app-user-name,
.sidebar-yellow .navigation-hold .app-logo-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-yellow .navigation-hold a:hover {
  background: rgba(212, 190, 0, 0.2) !important;
}
.sidebar-yellow .navigation-hold .app-user .app-user-photo,
.sidebar-yellow .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #96045f !important;
}
.sidebar-yellow .navigation-hold .icon-menu .mat-raised-button {
  background: rgba(255, 235, 59, 0.96) !important;
}
.sidebar-yellow .navigation-hold li.open > a {
  background: rgba(212, 190, 0, 0.2) !important;
}
.sidebar-yellow .navigation-hold .text-muted {
  color: rgba(0, 0, 0, 0.87) !important;
}

.footer-yellow .main-footer {
  background: #ffeb3b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.yellow-50 {
  background-color: #fffde7 !important;
}

.yellow-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-50 {
  color: #fffde7 !important;
}

.yellow-100 {
  background-color: #fff9c4 !important;
}

.yellow-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-100 {
  color: #fff9c4 !important;
}

.yellow-200 {
  background-color: #fff59d !important;
}

.yellow-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-200 {
  color: #fff59d !important;
}

.yellow-300 {
  background-color: #fff176 !important;
}

.yellow-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-300 {
  color: #fff176 !important;
}

.yellow-400 {
  background-color: #ffee58 !important;
}

.yellow-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-400 {
  color: #ffee58 !important;
}

.yellow-500 {
  background-color: #ffeb3b !important;
}

.yellow-500-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-500 {
  color: #ffeb3b !important;
}

.yellow-600 {
  background-color: #fdd835 !important;
}

.yellow-600-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-600 {
  color: #fdd835 !important;
}

.yellow-700 {
  background-color: #fbc02d !important;
}

.yellow-700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-700 {
  color: #fbc02d !important;
}

.yellow-800 {
  background-color: #f9a825 !important;
}

.yellow-800-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-800 {
  color: #f9a825 !important;
}

.yellow-900 {
  background-color: #f57f17 !important;
}

.yellow-900-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-900 {
  color: #f57f17 !important;
}

.yellow-A100 {
  background-color: #ffff8d !important;
}

.yellow-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-A100 {
  color: #ffff8d !important;
}

.yellow-A200 {
  background-color: #ffff00 !important;
}

.yellow-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-A200 {
  color: #ffff00 !important;
}

.yellow-A400 {
  background-color: #ffea00 !important;
}

.yellow-A400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-A400 {
  color: #ffea00 !important;
}

.yellow-A700 {
  background-color: #ffd600 !important;
}

.yellow-A700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-A700 {
  color: #ffd600 !important;
}

.amber {
  background-color: #ffc107 !important;
}
.amber .mat-table {
  background: transparent !important;
}
.amber .ngx-datatable.material *,
.amber .mat-table,
.amber .mat-cell,
.amber .mat-header-cell {
  color: rgba(0, 0, 0, 0.87) !important;
}
.amber .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.amber .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #ffc107, 5;
}

.text-amber {
  color: #ffc107;
}

.sidebar-amber .branding {
  background: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-amber .navigation-hold {
  background: #ffc107 !important;
}
.sidebar-amber .navigation-hold a,
.sidebar-amber .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-amber .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-amber .navigation-hold .sidenav li.open > a > span,
.sidebar-amber .navigation-hold .icon-menu .mat-raised-button,
.sidebar-amber .navigation-hold .app-user .app-user-name,
.sidebar-amber .navigation-hold .app-logo-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-amber .navigation-hold a:hover {
  background: rgba(160, 120, 0, 0.2) !important;
}
.sidebar-amber .navigation-hold .app-user .app-user-photo,
.sidebar-amber .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #96045f !important;
}
.sidebar-amber .navigation-hold .icon-menu .mat-raised-button {
  background: rgba(255, 193, 7, 0.96) !important;
}
.sidebar-amber .navigation-hold li.open > a {
  background: rgba(160, 120, 0, 0.2) !important;
}
.sidebar-amber .navigation-hold .text-muted {
  color: rgba(0, 0, 0, 0.87) !important;
}

.footer-amber .main-footer {
  background: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.amber-50 {
  background-color: #fff8e1 !important;
}

.amber-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-50 {
  color: #fff8e1 !important;
}

.amber-100 {
  background-color: #ffecb3 !important;
}

.amber-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-100 {
  color: #ffecb3 !important;
}

.amber-200 {
  background-color: #ffe082 !important;
}

.amber-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-200 {
  color: #ffe082 !important;
}

.amber-300 {
  background-color: #ffd54f !important;
}

.amber-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-300 {
  color: #ffd54f !important;
}

.amber-400 {
  background-color: #ffca28 !important;
}

.amber-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-400 {
  color: #ffca28 !important;
}

.amber-500 {
  background-color: #ffc107 !important;
}

.amber-500-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-500 {
  color: #ffc107 !important;
}

.amber-600 {
  background-color: #ffb300 !important;
}

.amber-600-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-600 {
  color: #ffb300 !important;
}

.amber-700 {
  background-color: #ffa000 !important;
}

.amber-700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-700 {
  color: #ffa000 !important;
}

.amber-800 {
  background-color: #ff8f00 !important;
}

.amber-800-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-800 {
  color: #ff8f00 !important;
}

.amber-900 {
  background-color: #ff6f00 !important;
}

.amber-900-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-900 {
  color: #ff6f00 !important;
}

.amber-A100 {
  background-color: #ffe57f !important;
}

.amber-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-A100 {
  color: #ffe57f !important;
}

.amber-A200 {
  background-color: #ffd740 !important;
}

.amber-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-A200 {
  color: #ffd740 !important;
}

.amber-A400 {
  background-color: #ffc400 !important;
}

.amber-A400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-A400 {
  color: #ffc400 !important;
}

.amber-A700 {
  background-color: #ffab00 !important;
}

.amber-A700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-A700 {
  color: #ffab00 !important;
}

.orange {
  background-color: #ff9800 !important;
}
.orange .mat-table {
  background: transparent !important;
}
.orange .ngx-datatable.material *,
.orange .mat-table,
.orange .mat-cell,
.orange .mat-header-cell {
  color: rgba(0, 0, 0, 0.87) !important;
}
.orange .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.orange .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #ff9800, 5;
}

.text-orange {
  color: #ff9800;
}

.sidebar-orange .branding {
  background: #ff9800 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-orange .navigation-hold {
  background: #ff9800 !important;
}
.sidebar-orange .navigation-hold a,
.sidebar-orange .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-orange .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-orange .navigation-hold .sidenav li.open > a > span,
.sidebar-orange .navigation-hold .icon-menu .mat-raised-button,
.sidebar-orange .navigation-hold .app-user .app-user-name,
.sidebar-orange .navigation-hold .app-logo-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-orange .navigation-hold a:hover {
  background: rgba(153, 91, 0, 0.2) !important;
}
.sidebar-orange .navigation-hold .app-user .app-user-photo,
.sidebar-orange .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #96045f !important;
}
.sidebar-orange .navigation-hold .icon-menu .mat-raised-button {
  background: rgba(255, 152, 0, 0.96) !important;
}
.sidebar-orange .navigation-hold li.open > a {
  background: rgba(153, 91, 0, 0.2) !important;
}
.sidebar-orange .navigation-hold .text-muted {
  color: rgba(0, 0, 0, 0.87) !important;
}

.footer-orange .main-footer {
  background: #ff9800 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.orange-50 {
  background-color: #fff3e0 !important;
}

.orange-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-orange-50 {
  color: #fff3e0 !important;
}

.orange-100 {
  background-color: #ffe0b2 !important;
}

.orange-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-orange-100 {
  color: #ffe0b2 !important;
}

.orange-200 {
  background-color: #ffcc80 !important;
}

.orange-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-orange-200 {
  color: #ffcc80 !important;
}

.orange-300 {
  background-color: #ffb74d !important;
}

.orange-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-orange-300 {
  color: #ffb74d !important;
}

.orange-400 {
  background-color: #ffa726 !important;
}

.orange-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-orange-400 {
  color: #ffa726 !important;
}

.orange-500 {
  background-color: #ff9800 !important;
}

.orange-500-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-orange-500 {
  color: #ff9800 !important;
}

.orange-600 {
  background-color: #fb8c00 !important;
}

.orange-600-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-orange-600 {
  color: #fb8c00 !important;
}

.orange-700 {
  background-color: #f57c00 !important;
}

.orange-700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-orange-700 {
  color: #f57c00 !important;
}

.orange-800 {
  background-color: #ef6c00 !important;
}

.orange-800-fg {
  color: white !important;
}

.text-orange-800 {
  color: #ef6c00 !important;
}

.orange-900 {
  background-color: #e65100 !important;
}

.orange-900-fg {
  color: white !important;
}

.text-orange-900 {
  color: #e65100 !important;
}

.orange-A100 {
  background-color: #ffd180 !important;
}

.orange-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-orange-A100 {
  color: #ffd180 !important;
}

.orange-A200 {
  background-color: #ffab40 !important;
}

.orange-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-orange-A200 {
  color: #ffab40 !important;
}

.orange-A400 {
  background-color: #ff9100 !important;
}

.orange-A400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-orange-A400 {
  color: #ff9100 !important;
}

.orange-A700 {
  background-color: #ff6d00 !important;
}

.orange-A700-fg {
  color: black !important;
}

.text-orange-A700 {
  color: #ff6d00 !important;
}

.deep-orange {
  background-color: #ff5722 !important;
}
.deep-orange .mat-table {
  background: transparent !important;
}
.deep-orange .ngx-datatable.material *,
.deep-orange .mat-table,
.deep-orange .mat-cell,
.deep-orange .mat-header-cell {
  color: white !important;
}
.deep-orange .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.deep-orange .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #ff5722, 5;
}

.text-deep-orange {
  color: #ff5722;
}

.sidebar-deep-orange .branding {
  background: #ff5722 !important;
  color: white !important;
}
.sidebar-deep-orange .navigation-hold {
  background: #ff5722 !important;
}
.sidebar-deep-orange .navigation-hold a,
.sidebar-deep-orange .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-deep-orange .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-deep-orange .navigation-hold .sidenav li.open > a > span,
.sidebar-deep-orange .navigation-hold .icon-menu .mat-raised-button,
.sidebar-deep-orange .navigation-hold .app-user .app-user-name,
.sidebar-deep-orange .navigation-hold .app-logo-text {
  color: white !important;
}
.sidebar-deep-orange .navigation-hold a:hover {
  background: rgba(187, 45, 0, 0.2) !important;
}
.sidebar-deep-orange .navigation-hold .app-user .app-user-photo,
.sidebar-deep-orange .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #96045f !important;
}
.sidebar-deep-orange .navigation-hold .icon-menu .mat-raised-button {
  background: rgba(255, 87, 34, 0.96) !important;
}
.sidebar-deep-orange .navigation-hold li.open > a {
  background: rgba(187, 45, 0, 0.2) !important;
}
.sidebar-deep-orange .navigation-hold .text-muted {
  color: white !important;
}

.footer-deep-orange .main-footer {
  background: #ff5722 !important;
  color: white !important;
}

.deep-orange-50 {
  background-color: #fbe9e7 !important;
}

.deep-orange-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-deep-orange-50 {
  color: #fbe9e7 !important;
}

.deep-orange-100 {
  background-color: #ffccbc !important;
}

.deep-orange-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-deep-orange-100 {
  color: #ffccbc !important;
}

.deep-orange-200 {
  background-color: #ffab91 !important;
}

.deep-orange-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-deep-orange-200 {
  color: #ffab91 !important;
}

.deep-orange-300 {
  background-color: #ff8a65 !important;
}

.deep-orange-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-deep-orange-300 {
  color: #ff8a65 !important;
}

.deep-orange-400 {
  background-color: #ff7043 !important;
}

.deep-orange-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-deep-orange-400 {
  color: #ff7043 !important;
}

.deep-orange-500 {
  background-color: #ff5722 !important;
}

.deep-orange-500-fg {
  color: white !important;
}

.text-deep-orange-500 {
  color: #ff5722 !important;
}

.deep-orange-600 {
  background-color: #f4511e !important;
}

.deep-orange-600-fg {
  color: white !important;
}

.text-deep-orange-600 {
  color: #f4511e !important;
}

.deep-orange-700 {
  background-color: #e64a19 !important;
}

.deep-orange-700-fg {
  color: white !important;
}

.text-deep-orange-700 {
  color: #e64a19 !important;
}

.deep-orange-800 {
  background-color: #d84315 !important;
}

.deep-orange-800-fg {
  color: white !important;
}

.text-deep-orange-800 {
  color: #d84315 !important;
}

.deep-orange-900 {
  background-color: #bf360c !important;
}

.deep-orange-900-fg {
  color: white !important;
}

.text-deep-orange-900 {
  color: #bf360c !important;
}

.deep-orange-A100 {
  background-color: #ff9e80 !important;
}

.deep-orange-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-deep-orange-A100 {
  color: #ff9e80 !important;
}

.deep-orange-A200 {
  background-color: #ff6e40 !important;
}

.deep-orange-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-deep-orange-A200 {
  color: #ff6e40 !important;
}

.deep-orange-A400 {
  background-color: #ff3d00 !important;
}

.deep-orange-A400-fg {
  color: white !important;
}

.text-deep-orange-A400 {
  color: #ff3d00 !important;
}

.deep-orange-A700 {
  background-color: #dd2c00 !important;
}

.deep-orange-A700-fg {
  color: white !important;
}

.text-deep-orange-A700 {
  color: #dd2c00 !important;
}

.brown {
  background-color: #795548 !important;
}
.brown .mat-table {
  background: transparent !important;
}
.brown .ngx-datatable.material *,
.brown .mat-table,
.brown .mat-cell,
.brown .mat-header-cell {
  color: rgba(255, 255, 255, 0.87) !important;
}
.brown .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.brown .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #795548, 5;
}

.text-brown {
  color: #795548;
}

.sidebar-brown .branding {
  background: #795548 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}
.sidebar-brown .navigation-hold {
  background: #795548 !important;
}
.sidebar-brown .navigation-hold a,
.sidebar-brown .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-brown .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-brown .navigation-hold .sidenav li.open > a > span,
.sidebar-brown .navigation-hold .icon-menu .mat-raised-button,
.sidebar-brown .navigation-hold .app-user .app-user-name,
.sidebar-brown .navigation-hold .app-logo-text {
  color: rgba(255, 255, 255, 0.87) !important;
}
.sidebar-brown .navigation-hold a:hover {
  background: rgba(57, 40, 34, 0.2) !important;
}
.sidebar-brown .navigation-hold .app-user .app-user-photo,
.sidebar-brown .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #96045f !important;
}
.sidebar-brown .navigation-hold .icon-menu .mat-raised-button {
  background: rgba(121, 85, 72, 0.96) !important;
}
.sidebar-brown .navigation-hold li.open > a {
  background: rgba(57, 40, 34, 0.2) !important;
}
.sidebar-brown .navigation-hold .text-muted {
  color: rgba(255, 255, 255, 0.87) !important;
}

.footer-brown .main-footer {
  background: #795548 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.brown-50 {
  background-color: #efebe9 !important;
}

.brown-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-brown-50 {
  color: #efebe9 !important;
}

.brown-100 {
  background-color: #d7ccc8 !important;
}

.brown-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-brown-100 {
  color: #d7ccc8 !important;
}

.brown-200 {
  background-color: #bcaaa4 !important;
}

.brown-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-brown-200 {
  color: #bcaaa4 !important;
}

.brown-300 {
  background-color: #a1887f !important;
}

.brown-300-fg {
  color: white !important;
}

.text-brown-300 {
  color: #a1887f !important;
}

.brown-400 {
  background-color: #8d6e63 !important;
}

.brown-400-fg {
  color: white !important;
}

.text-brown-400 {
  color: #8d6e63 !important;
}

.brown-500 {
  background-color: #795548 !important;
}

.brown-500-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-brown-500 {
  color: #795548 !important;
}

.brown-600 {
  background-color: #6d4c41 !important;
}

.brown-600-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-brown-600 {
  color: #6d4c41 !important;
}

.brown-700 {
  background-color: #5d4037 !important;
}

.brown-700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-brown-700 {
  color: #5d4037 !important;
}

.brown-800 {
  background-color: #4e342e !important;
}

.brown-800-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-brown-800 {
  color: #4e342e !important;
}

.brown-900 {
  background-color: #3e2723 !important;
}

.brown-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-brown-900 {
  color: #3e2723 !important;
}

.brown-A100 {
  background-color: #d7ccc8 !important;
}

.brown-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-brown-A100 {
  color: #d7ccc8 !important;
}

.brown-A200 {
  background-color: #bcaaa4 !important;
}

.brown-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-brown-A200 {
  color: #bcaaa4 !important;
}

.brown-A400 {
  background-color: #8d6e63 !important;
}

.brown-A400-fg {
  color: white !important;
}

.text-brown-A400 {
  color: #8d6e63 !important;
}

.brown-A700 {
  background-color: #5d4037 !important;
}

.brown-A700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-brown-A700 {
  color: #5d4037 !important;
}

.grey {
  background-color: #9e9e9e !important;
}
.grey .mat-table {
  background: transparent !important;
}
.grey .ngx-datatable.material *,
.grey .mat-table,
.grey .mat-cell,
.grey .mat-header-cell {
  color: rgba(0, 0, 0, 0.87) !important;
}
.grey .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.grey .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #9e9e9e, 5;
}

.text-grey {
  color: #9e9e9e;
}

.sidebar-grey .branding {
  background: #9e9e9e !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-grey .navigation-hold {
  background: #9e9e9e !important;
}
.sidebar-grey .navigation-hold a,
.sidebar-grey .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-grey .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-grey .navigation-hold .sidenav li.open > a > span,
.sidebar-grey .navigation-hold .icon-menu .mat-raised-button,
.sidebar-grey .navigation-hold .app-user .app-user-name,
.sidebar-grey .navigation-hold .app-logo-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-grey .navigation-hold a:hover {
  background: rgba(107, 107, 107, 0.2) !important;
}
.sidebar-grey .navigation-hold .app-user .app-user-photo,
.sidebar-grey .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #96045f !important;
}
.sidebar-grey .navigation-hold .icon-menu .mat-raised-button {
  background: rgba(158, 158, 158, 0.96) !important;
}
.sidebar-grey .navigation-hold li.open > a {
  background: rgba(107, 107, 107, 0.2) !important;
}
.sidebar-grey .navigation-hold .text-muted {
  color: rgba(0, 0, 0, 0.87) !important;
}

.footer-grey .main-footer {
  background: #9e9e9e !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.grey-0 {
  background-color: #ffffff !important;
}

.grey-0-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-grey-0 {
  color: #ffffff !important;
}

.grey-50 {
  background-color: #fafafa !important;
}

.grey-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-grey-50 {
  color: #fafafa !important;
}

.grey-100 {
  background-color: #f5f5f5 !important;
}

.grey-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-grey-100 {
  color: #f5f5f5 !important;
}

.grey-200 {
  background-color: #eeeeee !important;
}

.grey-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-grey-200 {
  color: #eeeeee !important;
}

.grey-300 {
  background-color: #e0e0e0 !important;
}

.grey-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-grey-300 {
  color: #e0e0e0 !important;
}

.grey-400 {
  background-color: #bdbdbd !important;
}

.grey-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-grey-400 {
  color: #bdbdbd !important;
}

.grey-500 {
  background-color: #9e9e9e !important;
}

.grey-500-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-grey-500 {
  color: #9e9e9e !important;
}

.grey-600 {
  background-color: #757575 !important;
}

.grey-600-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-grey-600 {
  color: #757575 !important;
}

.grey-700 {
  background-color: #616161 !important;
}

.grey-700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-grey-700 {
  color: #616161 !important;
}

.grey-800 {
  background-color: #424242 !important;
}

.grey-800-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-grey-800 {
  color: #424242 !important;
}

.grey-900 {
  background-color: #212121 !important;
}

.grey-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-grey-900 {
  color: #212121 !important;
}

.grey-1000 {
  background-color: #000000 !important;
}

.grey-1000-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-grey-1000 {
  color: #000000 !important;
}

.grey-A100 {
  background-color: #ffffff !important;
}

.grey-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-grey-A100 {
  color: #ffffff !important;
}

.grey-A200 {
  background-color: #eeeeee !important;
}

.grey-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-grey-A200 {
  color: #eeeeee !important;
}

.grey-A400 {
  background-color: #bdbdbd !important;
}

.grey-A400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-grey-A400 {
  color: #bdbdbd !important;
}

.grey-A700 {
  background-color: #616161 !important;
}

.grey-A700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-grey-A700 {
  color: #616161 !important;
}

.black {
  background-color: #1e2129 !important;
}
.black .mat-table {
  background: transparent !important;
}
.black .ngx-datatable.material *,
.black .mat-table,
.black .mat-cell,
.black .mat-header-cell {
  color: #ffffff !important;
}
.black .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.black .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #1e2129, 5;
}

.text-black {
  color: #1e2129;
}

.sidebar-black .branding {
  background: #1e2129 !important;
  color: #ffffff !important;
}
.sidebar-black .navigation-hold {
  background: #1e2129 !important;
}
.sidebar-black .navigation-hold a,
.sidebar-black .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-black .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-black .navigation-hold .sidenav li.open > a > span,
.sidebar-black .navigation-hold .icon-menu .mat-raised-button,
.sidebar-black .navigation-hold .app-user .app-user-name,
.sidebar-black .navigation-hold .app-logo-text {
  color: #ffffff !important;
}
.sidebar-black .navigation-hold a:hover {
  background: rgba(0, 0, 0, 0.2) !important;
}
.sidebar-black .navigation-hold .app-user .app-user-photo,
.sidebar-black .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #96045f !important;
}
.sidebar-black .navigation-hold .icon-menu .mat-raised-button {
  background: rgba(30, 33, 41, 0.96) !important;
}
.sidebar-black .navigation-hold li.open > a {
  background: rgba(0, 0, 0, 0.2) !important;
}
.sidebar-black .navigation-hold .text-muted {
  color: white !important;
}

.footer-black .main-footer {
  background: #1e2129 !important;
  color: #ffffff !important;
}

.black-50 {
  background-color: #e4e4e5 !important;
}

.black-50-fg {
  color: #000000 !important;
}

.text-black-50 {
  color: #e4e4e5 !important;
}

.black-100 {
  background-color: #bcbcbf !important;
}

.black-100-fg {
  color: #000000 !important;
}

.text-black-100 {
  color: #bcbcbf !important;
}

.black-200 {
  background-color: #8f9094 !important;
}

.black-200-fg {
  color: #000000 !important;
}

.text-black-200 {
  color: #8f9094 !important;
}

.black-300 {
  background-color: #626469 !important;
}

.black-300-fg {
  color: #ffffff !important;
}

.text-black-300 {
  color: #626469 !important;
}

.black-400 {
  background-color: #404249 !important;
}

.black-400-fg {
  color: #ffffff !important;
}

.text-black-400 {
  color: #404249 !important;
}

.black-500 {
  background-color: #1e2129 !important;
}

.black-500-fg {
  color: #ffffff !important;
}

.text-black-500 {
  color: #1e2129 !important;
}

.black-600 {
  background-color: #1a1d24 !important;
}

.black-600-fg {
  color: #ffffff !important;
}

.text-black-600 {
  color: #1a1d24 !important;
}

.black-700 {
  background-color: #16181f !important;
}

.black-700-fg {
  color: #ffffff !important;
}

.text-black-700 {
  color: #16181f !important;
}

.black-800 {
  background-color: #121419 !important;
}

.black-800-fg {
  color: #ffffff !important;
}

.text-black-800 {
  color: #121419 !important;
}

.black-900 {
  background-color: #0a0b0f !important;
}

.black-900-fg {
  color: #ffffff !important;
}

.text-black-900 {
  color: #0a0b0f !important;
}

.black-A100 {
  background-color: #548dff !important;
}

.black-A100-fg {
  color: #000000 !important;
}

.text-black-A100 {
  color: #548dff !important;
}

.black-A200 {
  background-color: #216bff !important;
}

.black-A200-fg {
  color: #ffffff !important;
}

.text-black-A200 {
  color: #216bff !important;
}

.black-A400 {
  background-color: #004fed !important;
}

.black-A400-fg {
  color: #ffffff !important;
}

.text-black-A400 {
  color: #004fed !important;
}

.black-A700 {
  background-color: #0047d4 !important;
}

.black-A700-fg {
  color: #ffffff !important;
}

.text-black-A700 {
  color: #0047d4 !important;
}

.blue-grey {
  background-color: #607d8b !important;
}
.blue-grey .mat-table {
  background: transparent !important;
}
.blue-grey .ngx-datatable.material *,
.blue-grey .mat-table,
.blue-grey .mat-cell,
.blue-grey .mat-header-cell {
  color: white !important;
}
.blue-grey .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.blue-grey .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #607d8b, 5;
}

.text-blue-grey {
  color: #607d8b;
}

.sidebar-blue-grey .branding {
  background: #607d8b !important;
  color: white !important;
}
.sidebar-blue-grey .navigation-hold {
  background: #607d8b !important;
}
.sidebar-blue-grey .navigation-hold a,
.sidebar-blue-grey .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-blue-grey .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-blue-grey .navigation-hold .sidenav li.open > a > span,
.sidebar-blue-grey .navigation-hold .icon-menu .mat-raised-button,
.sidebar-blue-grey .navigation-hold .app-user .app-user-name,
.sidebar-blue-grey .navigation-hold .app-logo-text {
  color: white !important;
}
.sidebar-blue-grey .navigation-hold a:hover {
  background: rgba(54, 71, 79, 0.2) !important;
}
.sidebar-blue-grey .navigation-hold .app-user .app-user-photo,
.sidebar-blue-grey .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #96045f !important;
}
.sidebar-blue-grey .navigation-hold .icon-menu .mat-raised-button {
  background: rgba(96, 125, 139, 0.96) !important;
}
.sidebar-blue-grey .navigation-hold li.open > a {
  background: rgba(54, 71, 79, 0.2) !important;
}
.sidebar-blue-grey .navigation-hold .text-muted {
  color: white !important;
}

.footer-blue-grey .main-footer {
  background: #607d8b !important;
  color: white !important;
}

.blue-grey-50 {
  background-color: #eceff1 !important;
}

.blue-grey-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-blue-grey-50 {
  color: #eceff1 !important;
}

.blue-grey-100 {
  background-color: #cfd8dc !important;
}

.blue-grey-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-blue-grey-100 {
  color: #cfd8dc !important;
}

.blue-grey-200 {
  background-color: #b0bec5 !important;
}

.blue-grey-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-blue-grey-200 {
  color: #b0bec5 !important;
}

.blue-grey-300 {
  background-color: #90a4ae !important;
}

.blue-grey-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-blue-grey-300 {
  color: #90a4ae !important;
}

.blue-grey-400 {
  background-color: #78909c !important;
}

.blue-grey-400-fg {
  color: white !important;
}

.text-blue-grey-400 {
  color: #78909c !important;
}

.blue-grey-500 {
  background-color: #607d8b !important;
}

.blue-grey-500-fg {
  color: white !important;
}

.text-blue-grey-500 {
  color: #607d8b !important;
}

.blue-grey-600 {
  background-color: #546e7a !important;
}

.blue-grey-600-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-blue-grey-600 {
  color: #546e7a !important;
}

.blue-grey-700 {
  background-color: #455a64 !important;
}

.blue-grey-700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-blue-grey-700 {
  color: #455a64 !important;
}

.blue-grey-800 {
  background-color: #37474f !important;
}

.blue-grey-800-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-blue-grey-800 {
  color: #37474f !important;
}

.blue-grey-900 {
  background-color: #263238 !important;
}

.blue-grey-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-blue-grey-900 {
  color: #263238 !important;
}

.blue-grey-A100 {
  background-color: #cfd8dc !important;
}

.blue-grey-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-blue-grey-A100 {
  color: #cfd8dc !important;
}

.blue-grey-A200 {
  background-color: #b0bec5 !important;
}

.blue-grey-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-blue-grey-A200 {
  color: #b0bec5 !important;
}

.blue-grey-A400 {
  background-color: #78909c !important;
}

.blue-grey-A400-fg {
  color: white !important;
}

.text-blue-grey-A400 {
  color: #78909c !important;
}

.blue-grey-A700 {
  background-color: #455a64 !important;
}

.blue-grey-A700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-blue-grey-A700 {
  color: #455a64 !important;
}

.white {
  background-color: #ffffff !important;
}
.white .mat-table {
  background: transparent !important;
}
.white .ngx-datatable.material *,
.white .mat-table,
.white .mat-cell,
.white .mat-header-cell {
  color: rgba(0, 0, 0, 0.87) !important;
}
.white .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.white .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #ffffff, 5;
}

.text-white {
  color: #ffffff;
}

.sidebar-white .branding {
  background: #ffffff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-white .navigation-hold {
  background: #ffffff !important;
}
.sidebar-white .navigation-hold a,
.sidebar-white .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-white .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-white .navigation-hold .sidenav li.open > a > span,
.sidebar-white .navigation-hold .icon-menu .mat-raised-button,
.sidebar-white .navigation-hold .app-user .app-user-name,
.sidebar-white .navigation-hold .app-logo-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-white .navigation-hold a:hover {
  background: rgba(204, 204, 204, 0.2) !important;
}
.sidebar-white .navigation-hold .app-user .app-user-photo,
.sidebar-white .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #96045f !important;
}
.sidebar-white .navigation-hold .icon-menu .mat-raised-button {
  background: rgba(255, 255, 255, 0.96) !important;
}
.sidebar-white .navigation-hold li.open > a {
  background: rgba(204, 204, 204, 0.2) !important;
}
.sidebar-white .navigation-hold .text-muted {
  color: rgba(0, 0, 0, 0.87) !important;
}

.footer-white .main-footer {
  background: #ffffff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.white-50 {
  background-color: #ffffff !important;
}

.white-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-50 {
  color: #ffffff !important;
}

.white-100 {
  background-color: #ffffff !important;
}

.white-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-100 {
  color: #ffffff !important;
}

.white-200 {
  background-color: #ffffff !important;
}

.white-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-200 {
  color: #ffffff !important;
}

.white-300 {
  background-color: #ffffff !important;
}

.white-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-300 {
  color: #ffffff !important;
}

.white-400 {
  background-color: #ffffff !important;
}

.white-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-400 {
  color: #ffffff !important;
}

.white-500 {
  background-color: #ffffff !important;
}

.white-500-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-500 {
  color: #ffffff !important;
}

.white-600 {
  background-color: #ffffff !important;
}

.white-600-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-600 {
  color: #ffffff !important;
}

.white-700 {
  background-color: #ffffff !important;
}

.white-700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-700 {
  color: #ffffff !important;
}

.white-800 {
  background-color: #ffffff !important;
}

.white-800-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-800 {
  color: #ffffff !important;
}

.white-900 {
  background-color: #ffffff !important;
}

.white-900-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-900 {
  color: #ffffff !important;
}

.white-A100 {
  background-color: #ffffff !important;
}

.white-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-A100 {
  color: #ffffff !important;
}

.white-A200 {
  background-color: #ffffff !important;
}

.white-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-A200 {
  color: #ffffff !important;
}

.white-A400 {
  background-color: #ffffff !important;
}

.white-A400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-A400 {
  color: #ffffff !important;
}

.white-A700 {
  background-color: #ffffff !important;
}

.white-A700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-A700 {
  color: #ffffff !important;
}

.slate {
  background-color: #222a45 !important;
}
.slate .mat-table {
  background: transparent !important;
}
.slate .ngx-datatable.material *,
.slate .mat-table,
.slate .mat-cell,
.slate .mat-header-cell {
  color: #ffffff !important;
}
.slate .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.slate .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #222a45, 5;
}

.text-slate {
  color: #222a45;
}

.sidebar-slate .branding {
  background: #222a45 !important;
  color: #ffffff !important;
}
.sidebar-slate .navigation-hold {
  background: #222a45 !important;
}
.sidebar-slate .navigation-hold a,
.sidebar-slate .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-slate .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-slate .navigation-hold .sidenav li.open > a > span,
.sidebar-slate .navigation-hold .icon-menu .mat-raised-button,
.sidebar-slate .navigation-hold .app-user .app-user-name,
.sidebar-slate .navigation-hold .app-logo-text {
  color: #ffffff !important;
}
.sidebar-slate .navigation-hold a:hover {
  background: rgba(0, 0, 1, 0.2) !important;
}
.sidebar-slate .navigation-hold .app-user .app-user-photo,
.sidebar-slate .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #96045f !important;
}
.sidebar-slate .navigation-hold .icon-menu .mat-raised-button {
  background: rgba(34, 42, 69, 0.96) !important;
}
.sidebar-slate .navigation-hold li.open > a {
  background: rgba(0, 0, 1, 0.2) !important;
}
.sidebar-slate .navigation-hold .text-muted {
  color: white !important;
}

.footer-slate .main-footer {
  background: #222a45 !important;
  color: #ffffff !important;
}

.slate-50 {
  background-color: #222a45 !important;
}

.slate-50-fg {
  color: #ffffff !important;
}

.text-slate-50 {
  color: #222a45 !important;
}

.slate-100 {
  background-color: #222a45 !important;
}

.slate-100-fg {
  color: #ffffff !important;
}

.text-slate-100 {
  color: #222a45 !important;
}

.slate-200 {
  background-color: #222a45 !important;
}

.slate-200-fg {
  color: #ffffff !important;
}

.text-slate-200 {
  color: #222a45 !important;
}

.slate-300 {
  background-color: #222a45 !important;
}

.slate-300-fg {
  color: #ffffff !important;
}

.text-slate-300 {
  color: #222a45 !important;
}

.slate-400 {
  background-color: #222a45 !important;
}

.slate-400-fg {
  color: #ffffff !important;
}

.text-slate-400 {
  color: #222a45 !important;
}

.slate-500 {
  background-color: #222a45 !important;
}

.slate-500-fg {
  color: #ffffff !important;
}

.text-slate-500 {
  color: #222a45 !important;
}

.slate-600 {
  background-color: #222a45 !important;
}

.slate-600-fg {
  color: #ffffff !important;
}

.text-slate-600 {
  color: #222a45 !important;
}

.slate-700 {
  background-color: #222a45 !important;
}

.slate-700-fg {
  color: #ffffff !important;
}

.text-slate-700 {
  color: #222a45 !important;
}

.slate-800 {
  background-color: #222a45 !important;
}

.slate-800-fg {
  color: #ffffff !important;
}

.text-slate-800 {
  color: #222a45 !important;
}

.slate-900 {
  background-color: #222a45 !important;
}

.slate-900-fg {
  color: #ffffff !important;
}

.text-slate-900 {
  color: #222a45 !important;
}

.slate-A100 {
  background-color: #222a45 !important;
}

.slate-A100-fg {
  color: #ffffff !important;
}

.text-slate-A100 {
  color: #222a45 !important;
}

.slate-A200 {
  background-color: #222a45 !important;
}

.slate-A200-fg {
  color: #ffffff !important;
}

.text-slate-A200 {
  color: #222a45 !important;
}

.slate-A400 {
  background-color: #222a45 !important;
}

.slate-A400-fg {
  color: #ffffff !important;
}

.text-slate-A400 {
  color: #222a45 !important;
}

.slate-A700 {
  background-color: #222a45 !important;
}

.slate-A700-fg {
  color: #ffffff !important;
}

.text-slate-A700 {
  color: #222a45 !important;
}

.page-layout {
  position: relative;
  padding: 0 1.5rem;
  margin: -0.333rem -0.333rem 0;
}
.page-layout .header-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 200px;
  z-index: -1;
}
.page-layout .content {
  display: flex;
  flex-direction: column;
}
.page-layout .content-header {
  height: 136px;
}
.page-layout .content-card {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.page-layout .content-card > .mat-tab-group .mat-tab-header .mat-tab-label, .page-layout .content-card > .card-header {
  height: 64px;
}
.page-layout .content-card .card-header {
  display: flex;
  align-items: center;
}
.page-layout .sidebar-header {
  height: 200px;
}
.page-layout.plain {
  padding-left: 0;
  padding-right: 0;
}
.page-layout.plain .sidebar-header {
  height: auto;
}
.page-layout.plain .content-card {
  box-shadow: none;
  border-radius: 0;
}

.green-snackbar {
  background: #69d84f !important;
}

.mat-orange {
  background-color: #ffac1c !important;
  color: black !important;
}

.mat-green {
  background-color: #69d84f !important;
  color: black !important;
}

.mat-purple2 {
  background-color: #313367 !important;
  color: white !important;
}

.mat-tooltip {
  font-size: 14px !important;
}

.ql-toolbar.ql-snow {
  background-color: white;
}

.ql-container .ql-editor, .ql-container .ql-editor.ql-blank::before {
  color: white;
}

.mat-paginator-icon {
  height: 28px;
}

.mdc-button, .mdc-button .mdc-button__label {
  min-width: fit-content !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.mat-mdc-button .mdc-button__label, .mat-mdc-button .mat-icon, .mat-mdc-unelevated-button .mdc-button__label, .mat-mdc-unelevated-button .mat-icon, .mat-mdc-raised-button .mdc-button__label, .mat-mdc-raised-button .mat-icon, .mat-mdc-outlined-button .mdc-button__label, .mat-mdc-outlined-button .mat-icon {
  min-inline-size: fit-content;
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__shadow {
  background-color: #ff8a48;
  /*replace with your color*/
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__track::after {
  background-color: #ff8a48 !important;
  /*replace with your color*/
}

.seciton-left {
  color: white;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  min-width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../../../images/backgrounds/markus-spiske-unsplash.jpg");
  background-size: cover;
}
.seciton-left .section-left-content {
  max-width: 380px;
}
.seciton-left::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.65);
  z-index: -1;
}

.form-holder {
  padding: 16px;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  min-width: 40%;
}
@media only screen and (max-width: 600px) {
  .form-holder {
    width: 100%;
  }
}

.signup-form {
  padding: 20px 20px 70px 20px;
}

.page-wrap {
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
}

.session-progress {
  position: relative;
  bottom: -4px;
  z-index: 9999;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.session-form-hold > .mat-card {
  margin: 0;
}

.session-lockscreen {
  max-width: 320px;
}

.lockscreen-user {
  text-align: center;
  margin-bottom: 1rem;
  display: flow-root;
  overflow: hidden;
}

.lockscreen-face {
  max-width: 72px;
  max-height: 72px;
  border-radius: 50%;
  margin-bottom: 0.5rem;
}

.logo {
  display: inline-block;
  position: absolute;
  top: 2%;
  left: 5%;
  width: 80px;
  height: 80px;
  line-height: 48px;
}

::ng-deep .mat-step-header .mat-step-icon {
  background-color: white !important;
  color: #662eff !important;
  height: 36px;
  width: 36px;
  font-size: 20px;
}

::ng-deep .mat-step-header .mat-step-icon-selected {
  background-color: #662eff !important;
  color: white !important;
}

::ng-deep .mat-vertical-stepper-content {
  visibility: visible !important;
  height: 100% !important;
}

.half-width-center {
  position: absolute;
  top: 50%;
  width: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.inbox-sidenav {
  width: 15rem;
  padding: 0.333rem;
  background: transparent !important;
}

.inbox-sidenav .inbox-nav-list .mat-list-item-content > a > span {
  font-size: 0.875rem;
}

.inbox-sidenav .mat-list-item-content .mat-icon {
  margin-right: 8px;
}

.inbox-toolbar {
  height: 56px !important;
}

.inbox-toolbar .mat-toolbar-row {
  height: 56px !important;
}

.show-on-open {
  display: none !important;
}

.mat-expanded .show-on-open {
  display: inherit !important;
}
.mat-expanded .hide-on-open {
  display: none !important;
}
.mat-expanded .mat-expansion-panel-header {
  margin-bottom: 1rem;
}

.messages-wrap {
  padding: 0.333rem;
  min-height: 800px;
}
.messages-wrap .mat-expansion-panel-header-title {
  align-items: center;
}
.messages-wrap .mat-expansion-panel-header-description {
  align-items: center;
}
.messages-wrap .mail-checkbox.mat-checkbox {
  position: relative;
  width: 20px;
  height: 22px;
  overflow: hidden;
  margin-right: 8px;
}
.messages-wrap .mail-checkbox.mat-checkbox .mat-checkbox-layout {
  position: absolute;
  top: 0;
  left: 0;
}
.messages-wrap .inbox-face {
  height: 36px;
  width: 36px;
  border-radius: 50%;
}

[dir=rtl] .inbox-sidenav .mat-list-item-content .mat-icon {
  margin-right: 0;
  margin-left: 8px;
}
[dir=rtl] .messages-wrap .mail-checkbox {
  margin-right: 0;
  margin-left: 0.5rem;
}
[dir=rtl] .messages-wrap .mail-checkbox .mat-checkbox-inner-container {
  margin-left: 0;
}

@media (max-width: 959px) {
  .inbox-sidenav {
    background: inherit !important;
  }
}
.card-title-text.calendar-title {
  padding: 0.5rem 1.5rem !important;
}

.cal-top-col {
  width: 33.3333%;
  float: left;
}

.cal-event-action .material-icons {
  vertical-align: sub;
  margin: 6px;
}

.cal-open-day-events {
  box-shadow: none !important;
}

.cal-week-view .cal-time-label-column,
.cal-week-view .cal-event,
.cal-week-view .cal-header,
.cal-week-view mwl-calendar-week-view-hour-segment,
.cal-week-view .cal-hour-segment,
.cal-week-view .cal-day-headers,
.cal-week-view .cal-header.cal-today {
  background: #222a49;
}

.cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover,
.egret-navy-dark .cal-month-view .cal-cell-row .cal-cell-top:hover {
  background: #1a2037;
}

.egret-navy-dark .cal-month-view .cal-cell-row .cal-cell:hover,
.egret-navy-dark .cal-month-view .cal-cell.cal-has-events.cal-open,
.egret-navy-dark .cal-month-view .cal-cell-row:hover,
.egret-navy-dark .cal-month-view .cal-cell-row .cal-cell:hover,
.egret-navy-dark .cal-month-view .cal-cell.cal-has-events.cal-open,
.egret-navy-dark .cal-month-view .cal-cell-row:hover,
.egret-navy-dark .cal-month-view .cal-day-cell.cal-today {
  background: transparent;
}

.calendar-form-dialog .mat-dialog-container {
  padding: 0;
}

.color-picker-input.mat-input-element {
  padding: 4px 0 !important;
}

@media (max-width: 767px) {
  .cal-top-col {
    width: 100%;
  }
}
.chat-sidenav {
  width: 15rem;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  overflow: hidden !important;
}

.chat-sidebar-toolbar,
.chat-toolbar,
.chat-sidebar-toolbar .mat-toolbar-row,
.chat-toolbar .mat-toolbar-row {
  min-height: 56px !important;
  height: 56px !important;
}

.chat-sidebar-scroll {
  position: relative;
  height: calc(100% - 56px);
  overflow-y: scroll;
}

.chat-intro {
  height: calc(100vh - 240px);
}

.chats-wrap .conversations-hold {
  padding-top: 1rem;
  position: relative;
  height: calc(100vh - 330px);
  overflow-y: scroll;
}

.chats-wrap .conversations-hold .single-conversation {
  overflow: hidden;
  padding: 0 2rem;
  margin-bottom: 2rem;
}

.chats-wrap .conversations-hold .single-conversation .toolbar-avatar {
  vertical-align: bottom;
  margin-right: 1rem;
}

.chats-wrap .conversations-hold .single-conversation .chat-username {
  margin: 0 0 0.625rem;
  font-size: 0.875rem;
}

.chats-wrap .conversations-hold .single-conversation .conversation-msg {
  padding: 0.48rem 0.94rem;
  display: inline-block;
  border-radius: 4px;
}

.chats-wrap .conversations-hold .single-conversation.sender .conversation-msg {
  background: #ffffff;
}

.chats-wrap .conversations-hold .single-conversation .chat-date {
  font-size: 11px;
  padding: 2px;
  margin: 0;
  clear: both;
}

.chats-wrap .chat-input-actions {
  padding: 0.6rem;
}

.chat-loader {
  display: flex;
  height: 100%;
  align-items: center;
}

[dir=rtl] .chat-sidenav {
  border-right: none;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
[dir=rtl] .chats-wrap .conversations-hold .single-conversation .toolbar-avatar {
  margin-right: 0;
  margin-left: 1rem;
}

.app-gallery .gallery-control-wrap {
  position: absolute;
  width: 100%;
  height: 40px;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.35);
  opacity: 0;
  transition: opacity 0.3s ease-in;
  -webkit-transition: opacity 0.3s ease-in;
}

.app-gallery img {
  max-width: 100%;
}

.app-gallery .mat-grid-tile-content:hover .gallery-control-wrap {
  opacity: 1;
}

.app-gallery .mat-grid-tile-content .gallery-control-wrap .gallery-control {
  padding: 0 0 0 1rem;
}

.app-gallery .mat-grid-tile-content .gallery-control-wrap .gallery-control .photo-detail {
  margin: 0;
  color: #fefefe;
  line-height: 40px;
}

.app-gallery .mat-grid-tile-content .gallery-control-wrap .gallery-control .mat-icon {
  color: #fff;
}

[dir=rtl] .app-gallery .mat-grid-tile-content .gallery-control-wrap .gallery-control .photo-detail {
  margin-right: 1rem;
}

.plan-pricing .mat-list-item .mat-list-item-content {
  display: inline-flex !important;
  text-align: center;
  font-size: 0.875rem !important;
}

.user-card .card-title-text {
  padding: 0.5rem 1.5rem !important;
  align-items: center;
}

.user-card .user-details > p:last-child {
  margin-bottom: 0;
}

.user-card .user-details .mat-icon {
  vertical-align: top;
  margin-right: 0.5rem;
}

.profile-sidebar {
  box-sizing: border-box;
}

.profile-sidebar .propic {
  width: 100%;
  margin-bottom: 6px;
}

.profile-sidebar .propic img {
  width: 50%;
  height: auto;
  border-radius: 50%;
}

.profile-sidebar .profile-title .main-title {
  font-size: 1.5rem;
}

.profile-sidebar .profile-nav {
  margin-left: -24px;
  margin-right: -24px;
}

.profile-sidebar .profile-nav .mat-list-item .mat-icon {
  margin-right: 8px;
}

.timeline {
  position: relative;
  overflow: hidden;
}

.timeline::before {
  content: "";
  position: absolute;
  top: 40px;
  bottom: 0;
  left: 24px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.12);
}

.timeline .timeline-item {
  width: 100%;
  overflow: hidden;
  margin-bottom: 24px;
}

.timeline .timeline-badge {
  float: left;
  position: relative;
  margin-right: 30px;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  overflow: hidden;
}

.timeline .timeline-badge img {
  max-width: 100%;
}

.timeline .timeline-badge .icon-badge {
  text-align: center;
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.timeline .timeline-body-top {
  margin-bottom: 8px;
}

.timeline .timeline-body {
  float: right;
  width: calc(100% - 78px);
}

.timeline .timeline-body-content img {
  border-radius: 4px;
  max-width: 100%;
}

.timeline .timeline-body-content > :last-child {
  margin-bottom: 8px;
}

[dir=rtl] .profile-sidebar .profile-nav .mat-list-item .mat-icon {
  margin-right: 0;
  margin-left: 8px;
}

.product-rating {
  display: flex;
  align-items: center;
  margin-left: -5px;
  justify-content: flex-start;
}
.product-rating .star-svg {
  width: auto !important;
}

h1.title {
  font-size: 1.8rem;
  margin: 0 0 1rem;
}

.p-line {
  font-size: 0.813rem;
  margin-bottom: 4px;
}

.option-label {
  margin-bottom: 6px;
  display: block;
}

.product-search,
.quantity-input {
  border: 0;
  height: 32px;
  border-radius: 2px;
  padding-left: 8px;
  font-size: 14px;
  width: 260px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0);
  transition: all 300ms cubic-bezier(0.35, 0, 0.25, 1);
}
.product-search:focus,
.quantity-input:focus {
  outline: none;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.quantity-input {
  width: 80px;
}

.shop-wrap {
  margin: 1rem 0.33rem 0.33rem;
  overflow: hidden;
  min-height: 750px;
}

.shop-sidebar {
  width: 15rem;
}

.shop-filters-wrap {
  margin-top: 7px;
  padding: 0 0.5rem 0 0.333rem;
}
.shop-filters-wrap .product-categories {
  list-style: none;
  margin: 0;
  padding: 0;
}
.shop-filters-wrap .product-categories li {
  cursor: pointer;
  padding: 5px;
  text-transform: capitalize;
}

.shop-top-toolbar {
  margin-bottom: 0.5rem;
  padding: 0 8px;
}

.product-search-wrap .product-search {
  width: 260px;
  margin: 6px 0 0;
}

.product-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  min-height: 750px;
}
.product-container .products-pagination {
  width: 100%;
  text-align: center;
  margin: 1.5rem 0;
  align-self: flex-end;
  justify-content: flex-end;
}
.product-container .products-pagination ul {
  padding: 0;
  margin: 0;
}
.product-container .products-pagination .ngx-pagination .current {
  background: #e6e6e6;
  color: rgba(0, 0, 0, 0.87);
}
.product-container .membership-product-wrap {
  width: 25%;
  float: left;
}
.product-container .membership-product-wrap .product-inner {
  min-height: 400px;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  overflow: hidden;
  margin: 8px;
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.product-container .membership-product-wrap .product-inner:hover {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.product-container .membership-product-wrap .product-inner .product-badge {
  color: #fff;
  text-align: center;
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  z-index: 100;
  height: 64px;
  width: 64px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 8px;
  transform: rotate(-30deg);
}
.product-container .membership-product-wrap .product-inner .product-badge span {
  font-weight: 500;
  line-height: 1.1;
}
.product-container .membership-product-wrap .product-inner .featured-img {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  min-height: 180px;
  justify-content: center;
  align-items: center;
}
.product-container .membership-product-wrap .product-inner .info-wrap {
  display: flex;
  flex-direction: column;
  max-height: fit-content;
}
.product-container .membership-product-wrap .product-inner .info-wrap .main-info {
  width: 100%;
  margin-bottom: 1rem;
}
.product-container .membership-product-wrap .product-inner .info-wrap .main-info .title {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 8px;
  cursor: pointer;
}
.product-container .membership-product-wrap .product-inner .info-wrap .main-info .title:active {
  outline: none;
}
.product-container .membership-product-wrap .product-inner .info-wrap .actions {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.product-container .membership-product-wrap .product-inner .info-wrap .actions div {
  flex: 50%;
  display: flex;
}
.product-container .membership-product-wrap .product-inner .info-wrap .actions .price-rating {
  flex-direction: column;
  justify-content: flex-start;
}
.product-container .membership-product-wrap .product-inner .info-wrap .actions .price-rating .price {
  align-items: center;
}
.product-container .membership-product-wrap .product-inner .info-wrap .actions .price-rating .price span {
  font-size: 1.2rem;
  margin-right: 4px;
}
.product-container .membership-product-wrap .product-inner .info-wrap .actions .add-to-cart {
  align-items: center;
}
.product-container .membership-product-wrap .product-inner ul {
  margin: 0;
  padding: 0 0 0 8px;
  list-style: none;
}
.product-container .membership-product-wrap .product-inner ul li {
  margin-bottom: 4px;
  display: flex;
}
.product-container .membership-product-wrap .product-inner ul li .mat-icon {
  margin-right: 8px;
}
.product-container .membership-product-wrap .product-inner .more-info-wrap {
  display: none;
}
.product-container .product-wrap {
  width: 25%;
  float: left;
}
.product-container .product-wrap .product-inner {
  min-height: 600px;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  overflow: hidden;
  margin: 8px;
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.product-container .product-wrap .product-inner:hover {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.product-container .product-wrap .product-inner .product-badge {
  color: #fff;
  text-align: center;
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  z-index: 100;
  height: 64px;
  width: 64px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 8px;
  transform: rotate(-30deg);
}
.product-container .product-wrap .product-inner .product-badge span {
  font-weight: 500;
  line-height: 1.1;
}
.product-container .product-wrap .product-inner .featured-img {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  min-height: 180px;
  justify-content: center;
  align-items: center;
}
.product-container .product-wrap .product-inner .info-wrap {
  display: flex;
  flex-direction: column;
  max-height: fit-content;
}
.product-container .product-wrap .product-inner .info-wrap .main-info {
  width: 100%;
  margin-bottom: 1rem;
}
.product-container .product-wrap .product-inner .info-wrap .main-info .title {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 8px;
  cursor: pointer;
}
.product-container .product-wrap .product-inner .info-wrap .main-info .title:active {
  outline: none;
}
.product-container .product-wrap .product-inner .info-wrap .actions {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.product-container .product-wrap .product-inner .info-wrap .actions div {
  flex: 50%;
  display: flex;
}
.product-container .product-wrap .product-inner .info-wrap .actions .price-rating {
  flex-direction: column;
  justify-content: flex-start;
}
.product-container .product-wrap .product-inner .info-wrap .actions .price-rating .price {
  align-items: center;
}
.product-container .product-wrap .product-inner .info-wrap .actions .price-rating .price span {
  font-size: 1.2rem;
  margin-right: 4px;
}
.product-container .product-wrap .product-inner .info-wrap .actions .add-to-cart {
  align-items: center;
}
.product-container .product-wrap .product-inner ul {
  margin: 0;
  padding: 0 0 0 8px;
  list-style: none;
}
.product-container .product-wrap .product-inner ul li {
  margin-bottom: 4px;
  display: flex;
}
.product-container .product-wrap .product-inner ul li .mat-icon {
  margin-right: 8px;
}
.product-container .product-wrap .product-inner .more-info-wrap {
  display: none;
}

.product-details-wrap .gallery-photo {
  min-height: 400px;
  width: calc(100% - 80px);
  float: left;
  padding-right: 1rem;
  margin-bottom: 1rem;
}
.product-details-wrap .gallery-photo img {
  width: 100%;
  height: auto;
  border-radius: 4px;
}
.product-details-wrap .gallery-thumbnails {
  width: 80px;
  float: left;
  padding-right: 0.667rem;
}
.product-details-wrap .gallery-thumbnails img {
  width: 100%;
  border-radius: 2px;
  display: inline-block;
  opacity: 1;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.product-details-wrap .gallery-thumbnails img.active {
  opacity: 0.6;
}

.cart-table {
  width: 100%;
  border-collapse: separate;
  border-radius: 2px;
  margin-bottom: 1.5rem;
  border-spacing: 0;
}
.cart-table .cart-thumbnail {
  height: 44px;
  width: auto;
  border-radius: 2px;
}
.cart-table thead tr {
  border-collapse: separate;
  background: rgba(0, 0, 0, 0.03);
}
.cart-table thead tr th {
  border-collapse: separate;
  text-align: left;
  padding: 1rem;
  font-weight: 500;
  letter-spacing: 0.1px;
}
.cart-table tbody tr td {
  text-align: left;
  padding: 16px;
}

@media (max-width: 1100px) {
  .product-container .product-wrap {
    width: 50%;
  }
  .product-container .membership-product-wrap {
    width: 50%;
  }
}
@media (max-width: 960px) {
  .shop-filters-wrap {
    margin: 0;
    padding: 0;
  }
  .shop-sidebar {
    background: #fff;
  }
}
@media (max-width: 680px) {
  .product-container .product-wrap {
    width: 100%;
  }
  .product-container .membership-product-wrap {
    width: 100%;
  }
  .product-details-wrap .gallery-photo {
    min-height: auto;
  }
}
@media (min-width: 1100px) {
  .product-container.list-view .membership-product-wrap {
    width: 100%;
    padding-right: 0;
  }
  .product-container.list-view .membership-product-wrap .product-inner {
    flex-direction: row;
  }
  .product-container.list-view .membership-product-wrap .featured-img {
    flex: 3 0;
  }
  .product-container.list-view .membership-product-wrap .featured-img img {
    width: 100%;
    max-width: 100%;
    min-height: 100%;
  }
  .product-container.list-view .membership-product-wrap .info-wrap {
    flex-direction: column;
    flex: 2 0;
  }
  .product-container.list-view .membership-product-wrap .info-wrap .actions {
    align-self: flex-end;
    justify-content: flex-end;
    margin-top: auto;
  }
  .product-container.list-view .membership-product-wrap .more-info-wrap {
    display: flex;
    align-items: stretch;
    flex: 2 0;
    position: relative;
  }
  .product-container.list-view .membership-product-wrap .more-info-wrap .more-info {
    width: 100%;
    padding-left: 1rem;
    border-left: 1px solid #e6e6e6;
  }
  .product-container.list-view .product-wrap {
    width: 100%;
    padding-right: 0;
  }
  .product-container.list-view .product-wrap .product-inner {
    flex-direction: row;
  }
  .product-container.list-view .product-wrap .featured-img {
    flex: 3 0;
  }
  .product-container.list-view .product-wrap .featured-img img {
    width: 100%;
    max-width: 100%;
    min-height: 100%;
  }
  .product-container.list-view .product-wrap .info-wrap {
    flex-direction: column;
    flex: 2 0;
  }
  .product-container.list-view .product-wrap .info-wrap .actions {
    align-self: flex-end;
    justify-content: flex-end;
    margin-top: auto;
  }
  .product-container.list-view .product-wrap .more-info-wrap {
    display: flex;
    align-items: stretch;
    flex: 2 0;
    position: relative;
  }
  .product-container.list-view .product-wrap .more-info-wrap .more-info {
    width: 100%;
    padding-left: 1rem;
    border-left: 1px solid #e6e6e6;
  }
}
[dir=rtl] .product-search,
[dir=rtl] .quantity-input {
  padding-right: 8px;
}
[dir=rtl] .product-container.list-view .product-wrap .more-info-wrap .more-info,
[dir=rtl] .product-container.list-view .membership-product-wrap .more-info-wrap .more-info {
  border: 0;
  padding-left: 0;
}
[dir=rtl] .product-container .product-wrap .product-inner .product-badge,
[dir=rtl] .product-container .membership-product-wrap .product-inner .product-badge {
  left: auto;
  right: 20px;
}
[dir=rtl] .product-container .product-wrap .product-inner ul li .mat-icon,
[dir=rtl] .product-container .membership-product-wrap .product-inner ul li .mat-icon {
  margin-right: 0;
  margin-left: 8px;
}
[dir=rtl] .product-details-wrap .gallery-photo {
  padding: 0;
}
[dir=rtl] .product-details-wrap .product-content-wrap {
  padding: 0 1rem;
}

.subEventHolder {
  padding: 1%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subEventChip {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  width: 100%;
  max-width: 100% !important;
  margin: 1% !important;
  cursor: pointer !important;
  background-color: #3a487e !important;
}

.material-icons.color_red {
  color: red;
}

.material-icons.color_white {
  color: #ffffff;
}

.product-container .product-wrap .product-inner .info-wrap .actions,
.product-container .membership-product-wrap .product-inner .info-wrap .actions {
  position: absolute;
  top: 0.5vh;
  left: 0.5vw;
}

.icon-display {
  transform: scale(1.5);
}

.ngx-pagination a,
.ngx-pagination button {
  color: white !important;
}

.material-icons.color_white {
  text-shadow: -1px 0 rgba(255, 3, 3, 0.6784313725), 0 1px rgba(255, 3, 3, 0.6784313725), 1px 0 rgba(255, 3, 3, 0.6784313725), 0 -1px rgba(255, 3, 3, 0.6784313725);
}

.product-search {
  border: solid 1px white;
  border-radius: 3px;
}

.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
.mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
.mat-mdc-standard-chip .mat-mdc-chip-action-label {
  overflow: hidden !important;
}

.mat-mdc-standard-chip .mdc-evolution-chip__action--primary {
  padding: 0 !important;
  min-width: 100%;
}

.home-section {
  padding: 40px 0 40px;
}

.scrollable {
  position: relative;
  width: 100%;
  height: 100vh;
}

.section-intro {
  padding: 100px 0 30px;
  background: #222a45;
  color: #ffffff;
}
.section-intro .egret {
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.48);
}
.section-intro h1 {
  font-size: 32px;
  margin: 2rem 0 1rem;
  font-weight: 500;
}
.section-intro p {
  font-size: 16px;
  max-width: 450px;
  margin: 0 auto 1.4rem;
}
.section-intro img {
  display: block;
  margin: 0 auto;
}

.section-demos {
  position: relative;
}
.section-demos .demo-box-wrap {
  padding: 1.5rem;
}
.section-demos .demo-box {
  position: relative;
  border-radius: 8px;
  transition: 0.2s all ease-in-out;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.section-demos .demo-box:hover {
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.2);
}
.section-demos .demo-box:hover .caption {
  opacity: 1;
  transform: translateY(-10px);
}
.section-demos .demo-box .caption {
  opacity: 0;
  background: #ffffff;
  padding: 1rem;
  position: absolute;
  z-index: 99;
  top: -20px;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 8px;
  width: 220px;
  transition: 0.2s all ease-in-out;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.section-demos .demo-box h3 {
  margin: 0 0 1rem;
  font-size: 22px;
  font-weight: 300;
}
.section-demos .demo-box img {
  width: 100%;
  height: auto;
  float: left;
}
.section-demos .demo-box .screenshot {
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
}

.align-right {
  text-align: right;
}

.order-info,
.billing-address {
  margin-top: 20px;
}
.order-info h4,
.billing-address h4 {
  vertical-align: top;
  margin-top: 0px;
  font-weight: 700 !important;
}
.order-info > div:last-child,
.billing-address > div:last-child {
  text-align: right;
}

.billing-address {
  margin-bottom: 20px;
}
.billing-address p {
  white-space: pre-line;
}

.item-form-list {
  margin-top: 20px;
}
.item-form-list .item-form-list-head-line {
  background: #f5f5f5;
}
.item-form-list .item-form-list-head-line p {
  padding: 10px;
  margin: 0px;
}
.item-form-list .form-rows {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.item-form-list .add-button {
  text-align: right;
}
.item-form-list .add-button button {
  margin-top: 20px;
}

.form-total-cost {
  margin-top: 20px;
}
.form-total-cost > div {
  width: 300px;
}
.form-total-cost > div .mat-form-field {
  width: 50px;
}
.form-total-cost h5 {
  margin: 0px;
  font-weight: 700 !important;
}

.total-cost {
  margin-top: 20px;
}
.total-cost div:first-child {
  margin-right: 20px;
}
.total-cost h5 {
  margin-top: 0px;
}

.invoice-icon-circle {
  box-sizing: content-box;
  width: 120px;
  height: 120px;
  border-radius: 60px;
  border: 2px solid rgba(0, 0, 0, 0.87);
  overflow: hidden;
}
.invoice-icon-circle .mat-icon {
  font-size: 48px;
  height: 48px;
  width: 48px;
}

.invoice-text-white {
  color: #f5f5f5;
}

@media print {
  body.print-body-content {
    visibility: hidden;
  }
  #print-area {
    visibility: visible;
  }
  #print-area * {
    visibility: visible;
  }
  #print-area .item-table .mat-table {
    box-shadow: none !important;
  }
}
.todo-option {
  padding: 20px 16px;
}

.tag {
  padding-right: 8px;
  padding-left: 8px;
  border-radius: 12px;
  line-height: 24px;
  box-sizing: content-box;
  background: rgba(0, 0, 0, 0.12);
}
.tag > div {
  display: inline-block;
}
.tag .circle {
  margin-right: 5px;
  height: 8px;
  width: 8px;
  background: red;
  border-radius: 50%;
}

.no-fill-color {
  color: transparent;
}

.mat-menu-content button {
  text-transform: capitalize;
  border-bottom: rgba(57, 57, 57, 0.2784313725);
  border-width: medium;
  border-bottom-style: ridge;
}

.todo-list {
  max-width: 100%;
  min-height: 60px;
  display: block;
  border-radius: 4px;
  overflow: hidden;
}

.todo-box {
  padding: 20px 16px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  font-size: 14px;
  display: flex;
}
.todo-box:hover {
  background: rgba(119, 68, 68, 0.05);
}

.move-handler {
  cursor: move;
}

.tag {
  padding-right: 8px;
  padding-left: 8px;
  border-radius: 12px;
  line-height: 24px;
  box-sizing: content-box;
  background: rgba(0, 0, 0, 0.12);
}
.tag > div {
  display: inline-block;
}
.tag .circle {
  margin-right: 5px;
  height: 8px;
  width: 8px;
  background: red;
  border-radius: 50%;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.todo-box:last-child {
  border: none;
}

.todo-list.cdk-drop-list-dragging .todo-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.search-box {
  border-radius: 24px;
  background: white;
  overflow: hidden;
  vertical-align: middle;
}
.search-box .mat-icon-button {
  margin-left: 8px;
}
.search-box input[type=text] {
  width: calc(100% - 52px);
  padding: 12px 20px;
  border: none;
  outline: none;
  font-size: 18px;
  box-sizing: border-box;
}