@mixin header-top($theme) {
  $color: map-get($theme, color);
  $text-color: map-get(map-get($color, foreground), text);
  $background: map-get($color, background);
  $background-card: map-get($background, card);
  $background-default: map-get($background, background);

  .header-topnav,
  .topnav ul ul {
    background-color: $background-card;
  }
  .topnav ul li ul li:hover,
  .topnav ul li ul li.open {
    background: $background-default;
  }
  .header-topnav a {
    color: $text-color;
  }
}
