.align-right {
  text-align: right;
}

.order-info,
.billing-address {
  margin-top: 20px;
  h4 {
    vertical-align: top;
    margin-top: 0px;
    font-weight: 700 !important;
  }
  & > div:last-child {
    text-align: right;
  }
}

.billing-address {
  margin-bottom: 20px;
  p {
    white-space: pre-line;
  }
}

.item-form-list {
  margin-top: 20px;
  .item-form-list-head-line {
    background: #f5f5f5;
    p {
      padding: 10px;
      margin: 0px;
    }
  }
  .form-rows {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  .add-button {
    text-align: right;
    button {
      margin-top: 20px;
    }
  }
}

.form-total-cost {
  margin-top: 20px;

  & > div {
    width: 300px;

    .mat-form-field {
      width: 50px;
    }
  }

  h5 {
    margin: 0px;
    font-weight: 700 !important;
  }
}

.total-cost {
  margin-top: 20px;
  div:first-child {
    margin-right: 20px;
  }
  h5 {
    margin-top: 0px;
  }
}

.invoice-icon-circle {
  box-sizing: content-box;
  width: 120px;
  height: 120px;
  border-radius: 60px;
  border: 2px solid rgba(0, 0, 0, 0.87);
  overflow: hidden;

  .mat-icon {
    font-size: 48px;
    height: 48px;
    width: 48px;
  }
}

.invoice-text-white {
  color: #f5f5f5;
}

@media print {
  body.print-body-content {
    visibility: hidden;
  }
  #print-area {
    visibility: visible;
    * {
      visibility: visible;
    }
    .item-table .mat-table {
      box-shadow: none !important;
    }
  }
}
