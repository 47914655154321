.card-title-text.calendar-title {
  padding: 0.5rem 1.5rem !important;
}
.cal-top-col {
  width: 33.3333%;
  float: left;
}
.cal-event-action .material-icons {
  vertical-align: sub;
  margin: 6px;
}
.cal-open-day-events {
  box-shadow: none !important;
}
.cal-week-view .cal-time-label-column,
.cal-week-view .cal-event,
.cal-week-view .cal-header,
.cal-week-view mwl-calendar-week-view-hour-segment,
.cal-week-view .cal-hour-segment,
.cal-week-view .cal-day-headers,
.cal-week-view .cal-header.cal-today {
  background: #222a49;
}

.cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover,
.egret-navy-dark .cal-month-view .cal-cell-row .cal-cell-top:hover {
  background: #1a2037;
}

.egret-navy-dark .cal-month-view .cal-cell-row .cal-cell:hover,
.egret-navy-dark .cal-month-view .cal-cell.cal-has-events.cal-open,
.egret-navy-dark .cal-month-view .cal-cell-row:hover,
.egret-navy-dark .cal-month-view .cal-cell-row .cal-cell:hover,
.egret-navy-dark .cal-month-view .cal-cell.cal-has-events.cal-open,
.egret-navy-dark .cal-month-view .cal-cell-row:hover,
.egret-navy-dark .cal-month-view .cal-day-cell.cal-today {
  background: transparent;
}

.calendar-form-dialog {
  .mat-dialog-container {
    padding: 0;
  }
}
.color-picker-input.mat-input-element {
  padding: 4px 0 !important;
}

@media (max-width: 767px) {
  .cal-top-col {
    width: 100%;
  }
}
