@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $key, $utility in $utilities {
      @if type-of($utility) == 'map' and (map-get($utility, responsive) or $infix == '') {
        @include generate-utility($utility, $infix);
      }
    }
  }
}

@media print {
  @each $key, $utility in $utilities {
    @if type-of($utility) == 'map' and map-get($utility, print) == true {
      @include generate-utility($utility, '-print');
    }
  }
}
