.page-layout {
  position: relative;
  padding: 0 1.5rem;
  margin: -0.333rem -0.333rem 0;

  .header-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 200px;
    z-index: -1;
  }

  .content {
    display: flex;
    flex-direction: column;
  }
  .content-header {
    height: 136px;
  }
  .content-body {
  }

  .content-card {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);

    & > .mat-tab-group .mat-tab-header .mat-tab-label,
    & > .card-header {
      height: 64px;
    }
    .card-header {
      display: flex;
      align-items: center;
    }
    .card-body {
    }
  }

  .sidebar-header {
    height: 200px;
  }

  &.plain {
    padding-left: 0;
    padding-right: 0;

    .sidebar-header {
      height: auto;
    }
    .content-card {
      box-shadow: none;
      border-radius: 0;
    }
  }
}

.green-snackbar {
  background: #69d84f !important;
}

.mat-orange {
  background-color: #ffac1c !important;
  color: black !important;
}

.mat-green {
  background-color: #69d84f !important;
  color: black !important;
}

.mat-purple2 {
  background-color: #313367 !important;
  color: white !important;
}

.mat-tooltip {
  font-size: 14px !important;
}

.ql-toolbar.ql-snow {
  background-color: white;
}
.ql-container .ql-editor, .ql-container .ql-editor.ql-blank::before {
  color: white;
}
.mat-paginator-icon {
  height: 28px;
}

.mdc-button,.mdc-button .mdc-button__label {
  min-width: fit-content!important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.mat-mdc-button .mdc-button__label, .mat-mdc-button .mat-icon, .mat-mdc-unelevated-button .mdc-button__label, .mat-mdc-unelevated-button .mat-icon, .mat-mdc-raised-button .mdc-button__label, .mat-mdc-raised-button .mat-icon, .mat-mdc-outlined-button .mdc-button__label, .mat-mdc-outlined-button .mat-icon {
  min-inline-size: fit-content;
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__shadow {
  background-color: #ff8a48;
  /*replace with your color*/
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__track::after {
  background-color: #ff8a48 !important;
  /*replace with your color*/
}