@import 'signup-2';

.page-wrap {
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
}
.session-progress {
  position: relative;
  bottom: -4px;
  z-index: 9999;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
.session-form-hold > .mat-card {
  margin: 0;
}
.session-lockscreen {
  max-width: 320px;
}
.lockscreen-user {
  text-align: center;
  margin-bottom: 1rem;
  display: flow-root;
  overflow: hidden;
}
.lockscreen-face {
  max-width: 72px;
  max-height: 72px;
  border-radius: 50%;
  margin-bottom: 0.5rem;
}
.logo {
  display: inline-block;
  position: absolute;
  top: 2%;
  left: 5%;
  width: 80px;
  height: 80px;
  line-height: 48px;
}

::ng-deep .mat-step-header .mat-step-icon {
  background-color: white !important;
  color: #662eff !important;
  height: 36px;
  width: 36px;
  font-size: 20px;
}

::ng-deep .mat-step-header .mat-step-icon-selected {
  background-color: #662eff !important;
  color: white !important;
}

::ng-deep .mat-vertical-stepper-content {
  visibility: visible !important;
  height: 100% !important;
}

.half-width-center {
  position: absolute;
  top: 50%;
  width: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
